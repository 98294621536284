
.bannerHome {
    background: var(--fourth-color) !important; 
    border-radius: 20px;
    height: min(20rem, 40rem);
}

.bannerCard>img {
    float: right;
    margin: 4rem;
}

.chartPrediction {
    display: flex;
    gap: 1.1rem;
    margin-top: 7.7rem;
}

p.chartheader {
color: var(--primary-color) !important;

}
h6.liveResult1{
        color: var(--primary-color) !important;

}
.GraphCard {
    flex: 1;
    height: min(20.8rem, 40rem);
    background: #fff;
    background-blend-mode: luminosity;
    border-radius: 0px;
}

.PredictionCard {
    flex: 1;
    /* height: min(20rem, 40rem); */
    background:var(--fourth-color) !important; 
    border-radius: 0px;
    margin-left: 10px;
    /* margin-top: 12px; */
}

.endsection {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.1rem;
    width: 98%;
    margin-inline: auto;
}

.AdsCard {
    background: #C4C4C4;
    width: 95%;
    height: -moz-fit-content;
    height: fit-content;
    position: sticky;
    top: 0;
    height: 400px !important;
    left: 1%;
    display: flex;
    justify-content: center;
}

.AdsCard h1 {
    text-align: center;
}

.PredictionFlex {
    display: flex;
    justify-content: space-between;
}

.Predictiontable {
    display: flex;
    width: 90%;
    margin-inline: auto;
    justify-content: space-between;
    padding-top: 15px;
}

.stickyads {
    width: 75%;
}

.aab {
    /* flex: 1; */
    width: 30%;
}

.middlesectioninner {
    width: 23%;
}

.newabc {
    width: 77%;
}

.presictData h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    color: #fff;
}

.MatchDataPredict h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22.5949px;
    line-height: 30px;
    color: #FFFFFF;
}
.MatchDataPredict h3 {
    font-size: 14px !IMPORTANT;
    display: block;
    width: 100%;
}
.PredictionFlex {
    width: 100%;
    margin-inline: auto;
    display: block;
    padding-top: 10px;
    
}
span.PredictionFlex p {
    margin-top: 0 !important;
    font-size: 14px !IMPORTANT;
}

.PredictionFlex :nth-child(1) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5949px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 20px 0 0 20px;
    height: 28px;
    overflow: hidden;

}

.PredictionFlex :nth-child(2) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15.5949px;
    line-height: 19px;

    color: #FFFFFF;
    margin: 20px 0 0 20px;
}

.PredictionFlex :nth-child(3) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15.5949px;
    line-height: 19px;
    color: #5EC40F;
    margin: 20px 20px 0 20px;
}

.playerPredictTable {
    margin-inline: auto;
    width: 96%;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 0px;
    margin-top: 11px;
    min-height: 230px;
    min-height: 230px;
    max-height: 250px;
    overflow: auto;

}

.calenderin {
    width: 90%;
    margin: auto;

}

.react-calendar {
    width: 90%;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    margin: auto;
    border-radius: 0px;
}

.Currentpostdiv {
    width: 94%;
    margin-inline: auto;
    height: fit-content;
    background: var(--fifth-color) !important; 
    border-radius: 0px;
    margin-top: 0px;
}

.newpost {
    min-height: 700px;
    /* height: 700px; */
    background:var(--fourth-color) !important; 
    margin: 10px 0px;
    padding: 20px px 0px 20px 0px;
    border-radius: 0px 20px 20px 0px;
    overflow: auto;
    margin-bottom: 12px;
    max-height: 700px;
}

.chartPrediction {
    display: flex;
    gap: 1.1rem;
    margin-top: 0.7rem !important;
}

.nav-tabs {
    border: none !important;
    width: 100%;
    margin-inline: auto;
    margin-top: 4px;
    color: #fff;
    padding-bottom: 6px;
    padding: 2px;
}

/* .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: none;
    color: #fff;
    font-weight: 700;
      background: var(--primary-color) !important; 
} */

:root {
    --primary-color: var(--primary-color);
    --secondary-color: var(--secondary-color);
  }

.nav-tabs .nav-link {
    color: #fff;
    font-size: 14px;
}

.tab-content {
    margin-top: -5px;
    margin-bottom: 0px;
    /* min-height: 35rem; */
}

.match .mb-4 {
    margin: 0px 0px !important;
}



ul.mb-3.RaceNavItem.nav.nav-tabs {
    margin-left: 20px;
}

.calendericon {
    color: #000;
    font-size: 18px;
}

.nav-link {
    color: #000;
    font-size: 18px;
}

.Currentpostdiv h3 {
    text-align: center;
    color: #000;
}

.Currentpostheader {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin-inline: auto;
    margin-top: 12px
}

.Currentpostheader h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    margin-top: 20px;
    font-weight: 700;
    line-height: 19px;
    color: #fff;
    font-size: 24px;
}

.Currentpostheader img {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 19px;
    margin-top: 1px;
    color: #000000;
    object-fit: scale-down;
}

.Competitionitem {
    border-radius: 21px;
    margin: 8px;
}

.accordion-button .collapsed {
    border-radius: 20px;
}

/* .customers12 > span > p:nth-child(2){
    width: 15%;
    text-align: start;
} */
.Currentpostheader img {
    height: 40px;
    width: 50px;
}

.racecalenderstyle1 {
    width: 93%;
    background: #fff;
    color: #000 !important;
    cursor: pointer;
    margin: auto;
    height: 56px;
    border-radius: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.eventracedata1 {
    color: #000;
    text-align: center;
    font-weight: 600;
    /* padding-top: 12px;
    padding-left: 15px; */
}
.match li:nth-child(2) button {
       background: var(--primary-color);

    border-color: transparent !important;
}
.eventracecalender {
    padding-right: 15px;
}
.verdictrace table {
    width: 100%;
}
.react-calendar__navigation {
    font-size: 14px !important;
}

.itemraces_center div p:nth-child(2) {
    max-height: 60px;
    overflow: hidden;
}

button.eventdata1 {
    background: #19469D;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    /* height: 30px;
    width: 25px;
    opacity: 0.5;
    position: relative;
    left: -20px; */
}

abbr {
    margin: -1px !important;
}