.RaceCard {
  /* width: 688px; */
  height: max-content;
  background: var(--secondary-color) !important; 
  margin: auto;
  margin-top: 20px;
  border-radius: 20px;
  margin-left: 10px;
}

.racepagehead {
  height: fit-content;
  background: var(--primary-color) !important; 

  border-radius: 0px;

  align-items: center;
  display: flex;
  min-height: 113px;
  align-items: center;
}

.racepagesection {
  height: fit-content;
  height: fit-content;
  background: var(--secondary-color) !important; 
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  margin-top: -11px;
}
/* .todayracefav {
  width: 60% !important;
} */
.racepageitem {
  display: flex;
  justify-content: space-between;
  /* width: 672px; */
  min-height: 140px;
  background: #ffffff;
  border-radius: 0px;
  margin-inline: auto;
  margin: 10px;
  padding: 12px;
  transition: 0.5s linear all;
  height: -moz-fit-content;
  max-height: fit-content;
  cursor: pointer;
}
.racepageheadflex {
  display: flex;
  width: 94%;
  align-items: center;
  margin-inline: auto;
  justify-content: space-between;
}

.raceStatus {
  display: flex;
  flex: 2;
  justify-content: space-evenly;
}

.racepagename {
  flex: 3 1;
  /* margin: 10px 10px 50px 6px; */
}

.racepagename>span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22.5949px;
  line-height: 27px;
  color: #fff;
}

.racepagename>p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16.5949px;
  line-height: 27px;
  color: #fff;
}

.raceStatusitem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 67.93px;
  height: 33.95px;
  background: #ff9900;
  border-radius: 0px;
  color: #fff;
  font-size: 17.17293px;
  line-height: 9px;
}

.racedown {
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  gap: 10px;
}

.racedown>p:hover {
  border-bottom: 2px solid #19469d;
  cursor: pointer;
}

.racestatusclass {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: self-end;
  padding: 16.97744px 26.96617px;
  gap: 10.76px;
  width: 95px;
  height: 80px;
  left: 791px;
  top: 514px;
  color: #fff;
  border-radius: 0px;
  margin-top: 10px;
}
.racestatusright {
  display: flex;
  justify-content: space-evenly;
  gap: 22px;
  justify-content: center;
  text-align: center;
}
thead.drawtablehead {
  width: 40px;
}
.HeaderAccordion {
  box-sizing: border-box;
  width: 688px;
  height: 300px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: transparent;
}

.accordion-item {
  background: #fff;
  border: none;
  margin: -3px -3px;
  border-radius: 0px !important;
}

.cardracesAccordion {
  display: flex;
  gap: 30px;
  height: 146px;
}

.cardraces1 {
  display: flex;
  gap: 10px;
}

.cardraces1box {
  width: 60px;
  height: 98px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  font-weight: 300;
  font-size: 9.69231px;
  line-height: 12px;
}


.cardraces3 {
  display: flex;
  justify-content: space-between;
}

.cardraces3 img {
  width: 92px;
  height: 67px;
  object-fit: fill;
}

.forfexclass .accordion-button {
  display: block;
}

.pmclass {
  margin: 0px 0 0 2px;
  display: flex;
  gap: 25px;
}

.pmclass>p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 11px;
  color: rgba(0, 0, 0, 0.5);
}

.uaecareer {
  display: flex;
  gap: 25px;
}

.uaecareer>p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  color: rgba(0, 0, 0, 0.5);
}

.mycardclass1 {
  background: var(--secondary-color) !important; 
  width: 100%;
  margin: initial;
  /* height: 200px; */
  border-radius: 0px;
}

.BodyNew {
  height: fit-content;
  /* left: 376px;
    top: 881px; */
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.BodyNew1:nth-child(1) {
  height: 55px;

  /* background: #5ec40f; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  margin: 10px 0px 0px 0px;
}

.BodyNew1:nth-child(2) {
  height: 65px;

  /* background: #9f5109; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  margin: 10px 0px 0px 0px;
}

.BodyNew1:nth-child(3) {
  height: 55px;
  /* background: #949494; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

/* .BodyNew1:nth-child() {
    height: 55px;
    left: 376px;
    top: 881px;
    background: #9F5109;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
} */

.Competition_Matches {
  cursor: pointer;
}

.racepageitem h5 {
  font-weight: 300;
  margin-top: 7px;
  font-size: 20px;
}

.RaceDesc p {
  font-size: 12px;
}

.cardracesAccordion1 {
  display: flex;
  gap: 10px !important;
}

.racestatusclassMobile {
  display: none;
}

/* .accordion-collapse.collapse.show {
    height: fit-content !important;
    min-height: 16rem;
} */

p.StartTimeCards {
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
}
.racepageitem.removedata12 {
  margin: 0px;
}
.racepageitem:hover .RaceDetailsName {
  color: #19469d;
}
.racecardacc12.accordion-body {
  background: transparent !important;
  margin-bottom: -16px;
}
.racepageitem:hover {
  scale: 1.01;
}

p.noDataFound {
  text-align: center;
}

.close {
  color: #fff !important;
  opacity: 1;
}

/* .racetime>p {
    margin-left: 20px;


} */
.trackspan span {
  margin: 0px 3px;
  cursor: pointer;
}

.redclass {
  color: red;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  font-weight: 700;
  font-size: 20.4333px;
  line-height: 28px;
  margin: 0px 10px !important;
  /* margin-left: 0px; */
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.orangeclass {
  color: #ff9900;
}

.castBtn {
  background: #19469d;
  border-radius: 19px;
  color: #fff;
  width: 96px;
  border: none;
  height: 21px;
  text-align: center;
  font-size: 12px;
}

.displayn {
  display: none;
}

.castBtn1 {
  cursor: pointer;
  margin: 10px;
  border-radius: 0px;
  background: none;
  border: 1px solid gray;
  font-size: 10px;
  padding: 4px 9px;
  transition: all ease-in-out 300ms;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.castBtn1:hover {
  cursor: pointer;

  border-radius: 0px;
  background: #19469d;
  border: 1px solid gray;
  font-size: 10px;

  color: white;
  border: none;
  transition: all ease-in-out 300ms;
  transform: scale(1.1);
}

.ErrorPara {
  text-align: center;
  color: red;
}

.hideClass {
  display: none !important;
}

.filterbtn {
  position: absolute;
  margin-top: -35px;
  right: 0;
  font-size: 16px;
  color: #fff;
  padding: 0px 10px;
  background: transparent;
  border: none;
}

.showfilterclass {
  /* display: flex; */
  /* justify-content: space-around; */
  text-align: center;
}

.showfilterclass>p {
  font-size: 12px;
}

svg {
  cursor: pointer;
}

.raceCarParent {
  width: 100%;
  margin: 30px auto;
  border: 1px solid rgb(244, 244, 244);
  min-height: 80vh;
  border-radius: 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  background: #ededed;
}

.raceCarParent .accordion-button {
  border-radius: 0px !important;
}

.raceCardHeader {
  background: linear-gradient(273deg, #19469d 23.67%, #00a3ff 97.94%);
  display: flex;
  border-radius: 0px;
  color: #fff;

  align-items: center;
  text-align: center;
}

.raceCardHeader>p {
  padding: 25px 30px;
  cursor: pointer;
}

.raceCardHeader1>h1 {
  margin: 15px 26px;
  font-family: inter;
  font-size: 38px;
  font-weight: 600;
}

.raceCardHeader1>p {
  margin: 29px 26px;
}

.accordionHeader {
  cursor: pointer;
  border-radius: 0px;
  margin: 0 0px;
  background: var(--secondary-color);
}

.headerCard1 {
  display: flex;
}

.headerCard1>h4 {
  padding: 22px 0px 15px 30px;
  font-size: 18px;
  font-family: inherit;
}

.headerCard1>p {
  font-family: inherit;
  padding: 15px 0px 15px 14px;

  font-size: 10px;
}

.raceCardTable {
  transition: all ease-in-out 2s;
}

.tableDataCard {
  height: 55px;
  color: #fff;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  margin: 1px 0px 0px 0px;
  cursor: pointer;
}

.tableDataCard:hover {
  transform: scale(1.01);
}

.headerCard2 {
  font-family: inherit;
  padding: 15px 18px 15px 0px;
  text-align: right;
}

.accordionHeader.row {
  align-items: center;
}

.headerCard2 p {
  margin: 0 !important;
  text-transform: capitalize;
}

.raceCarParent button.accordion-button {
  display: block !important;
}

.raceCarParent button.accordion-button {
  padding: 0;
}

.raceCarParent th {
  padding: 0 !important;
  padding-bottom: 10px !important;
  color: #000 !important;
}

.accordion-button:not(.collapsed) {
  color: #000;
}

.raceCarParent .customers td {
  background: transparent!important;
  border: 1px solid transparent;
  color: #000000;
  font-size: 12px;
  padding: 0;
  text-align: center;
}

.raceCarParent .customers td.lb {
  border-radius: 0px !important;
}

.raceCarParent .customers td.rb {
  border-radius: 0px !important;
}

.stickyHeader {
  position: fixed;
  text-align: center;


  background: #c4c4c4;
  width: 100%;
  position: sticky;
  top: 0;

  z-index: 999 !important;
  opacity: 1;
}

.stickyHeader h5 {
  font-weight: 900;
  font-size: 19.6px;
  line-height: 24px;
  color: rgb(25, 70, 157);
  word-spacing: 3px;
  letter-spacing: 1px;
}



.pointandstagings1 {
  display: flex;
}

.notFoundImageDiv {

  width: 100%;
}

.notFoundImageDiv img {

  width: 100%;
}

.raceCardHeader:last-child {
  display: none !important;
}

.rb svg {
  cursor: pointer;
  COLOR: red;
  font-size: 32px;
  text-align: end;
}

.singleRacewidth {
  width: 55% !important;
  margin-top: 50px;
}

.inner_itemraces .sponsor {
  height: 80px !important;
  flex: 1 1;
  margin-top: 17px !important;
  width: 100%;
  object-fit: cover;
  margin-left: 0;
}

.raceCarParent .customers td.lb img {
  border-radius: 0px !important;
  height: 35px;
}

.customClass .lb {
  border-radius: 0px;
}

.historyhorseth .rb {
  border-radius: 0px;
}

.historyhorseth .lb {
  border-radius: 0px;
}


.historyhorseth .rb {
  border-radius: 0px;
}

.raceroursecolor2 {
  height: 6px !important;
  width: 8% !important;

  /* width: 40px; */
  position: absolute !important;
  left: 2% !important;
  top: 14.9% !important;
  margin-top: -6px !important;
}



.tempcolor {
  background: #5ec40f !important;
}

.entryTable td {
  font-size: 10px;
}


.atozParent th {
  color: #000 !important;
  font-size: 20px;
}

.atozParent td img {
  width: 60px;
  height: 45px;
  object-fit: scale-down;
}

.atozParent th {
  color: #000 !important;
  font-size: 18px;
  text-align: center;
  width: 10%;
  padding: 10px 15px;
}

.atozParent td {
  color: #000 !important;
  font-size: 14px;
  text-align: center;

}

.raceCarParent1 {
  width: 100%;
  margin: 0px auto;
  border: 1px solid rgb(244, 244, 244);
  min-height: 80vh;
  border-radius: 0px;
  background: #fff !important;
  padding: 28px;


}

.raceCarParent1 tr {

  border: 0.5px solid rgb(188, 188, 188);
  background: #fff;

}



.atozParent h1 {
  padding: 16px;
  font-size: 24px;
}

.container.mystages {
  margin-top: 25px;
}

.horsenamerace {
  font-weight: "700";
  font-size: "19.6px";
  line-height: "24px";
  color: "#19469D";
}

.showfilterclass.icons1 {
  justify-content: space-between;
  width: 98%;
  margin: auto;
  margin-top: -10px;
}

.cardraces1>img {
  width: 60px;
  height: 50px;
  /* margin-top: 15px; */
  object-fit: scale-down;
}

.rb {
  border-radius: 0px !important;
}

.ownerSection>p>b {
  color: #d71616;
}

.ownerSection>p>p {
  margin-left: 10px;

}

.modalOfRace {
  width: 100% !important;
}

*[dir="rtl"] .statsTable th {
  text-align: center !important;
  padding-right: 31px !important;
}

.ownernamestyle1 {
  font-size: 9px;
  font-weight: 300;
  line-height: 10px !important;
  color: red;
  font-weight: 400 !important;
  cursor: pointer;

}

.ownerTable>tr>td {
  width: 12%;
  font-size: 11px;
}


p.favhorses p {
  line-height: 0px !important;
}

.racecardcss1.classtyperaces1 {
  display: block;
}

.top p.favhorses p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px !important;
}

.top p {
  margin: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}
 
.top h5 {
  margin-bottom: 7px;
  color: #000;
  font-size: 12px;
  font-weight: 800 !IMPORTANT;
  line-height: 16px !important;
  text-align: left !important;
}
p.favhorses {
  margin-bottom: 0 !IMPORTANT;
}

/* .todayracefav {
  width: 95%;
} */

.top1 {
  width: 140px; 
}

img.imgflag2 {
  /* width: 35px; */
  /* height: 30px; */
  /* margin: 0px 0px 3px 8px; */
  margin: 0px 0px 3px 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 22.5949px;
  line-height: 27px;
  color: #FFFFFF;
  height: 29px;
  width: 29px;
}

.headerCard1.carddisplay121.col-sm-8 h4 {
  color: #fff !important;
}

.headerCard3.col-sm-1 .fa {
  color: #fff;
}

.headerCard2.col-sm-3 p {
  color: #fff;
}


.prizecard.prizecardverdict {
  height: auto;
  padding: 0px 12px 20px 12px;
}

.breedername2{
  margin-left: 12px;
  line-height: 12px;
  margin-right: 12px;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}