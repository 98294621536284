/* html{
    overflow-x: hidden;
} */
body {
    /* overflow-x: hidden; */
    text-transform: capitalize;
}
:root {
    --primary-color: var(--primary-color);
    --secondary-color: var(--secondary-color);
  }
/* *[dir="rtl"]{
    font-weight: 700;
} */
*[dir="rtl"] .header {
    margin-right: 0 !important;
    margin-left: 0;
    padding-right: 20px;
    border-radius: 0px 0px 0px 50px;
}

*[dir="rtl"] .userprofile {
    flex-direction: row-reverse;
}
*[dir="rtl"] .middlebanner section {
    left: -11.9% !important;
}

*[dir="rtl"] input#floatingInput {
    margin-right: 100px;
    height: 35px;
}
*[dir="rtl"] .searchparent {
    margin-right: -5px;
}

*[dir="rtl"] .trainerStages td {
    width: 9.5% !important;
}

*[dir="rtl"] .trainerStages td {
    text-align: center;
}

[dir=rtl] .mystages td {
    padding: 12px 28px!important;
}

*[dir="rtl"] .formobilescroll1 .mycss1 td {
    padding-left: 0px !important;
}




*[dir="rtl"] .top h5 {
    text-align: right !important;
    font-weight: 800 !important;
    font-size: 17px !important;
}


*[dir="rtl"] .raceStatusitem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 67.93px;
    height: 33.95px;
    background: #ff9900;
    border-radius: 0px;
    color: #fff;
    font-size: 22.17293px;
    line-height: 9px;
}

*[dir="rtl"] .form-floating>label {
    top: 12px;
}

*[dir="rtl"] .form-floating>.form-control:not(:placeholder-shown)~label{
    opacity: .65;
    transform: unset !important;
}

*[dir="rtl"] .mb-3.form-floating input {
    margin-right: 100px;
}
*[dir="rtl"] .notificationmenu.dropdown-menu.show {
    margin-left: 0px;
}

*[dir="rtl"] .headerCard2 {
    font-family: inherit;
    padding: 15px 18px 15px 0px;
    text-align: left;
}

*[dir="rtl"] .compDetail h5 {
    left: -49px;
}

*[dir="rtl"]  .react-calendar__tile button {
    height: 35px !important;
}
*[dir="rtl"] .nav-tabs {
    padding: 3x 0px;
    margin-bottom: 30px !important;
}
*[dir="rtl"] ::placeholder{
    padding-right: 20px;
}

*[dir="rtl"] .col-sm-6.todayracefav p {
    line-height: 20px;
}


*[dir="rtl"] .rb {
    border-radius: 0px !important;
}
*[dir="rtl"] .newpost {
    border-radius: 0px;
}

*[dir="rtl"] .authhover ul {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

*[dir="rtl"] .authhover ul li {
    padding-right: 20px;
}

*[dir="rtl"] .authhover>ul>li:last-child {
    border-radius: 0px !important;
}

*[dir="rtl"] .icon11 {
    margin-left: -35px;
    font-size: 21px;
    color: rgb(170, 168, 168);
    margin-right: -35px;
}


*[dir="rtl"] .singleracecardbtn button {
    margin-left: 0;
    margin-right: 0px;
}

*[dir="rtl"] .singleracecard {
    padding: 0 10px;
}

*[dir="rtl"] .resultCardHeader h2 {
    padding-right: 20px;
}

*[dir="rtl"] .itemraces>p {
    font-size: 14px;
    line-height: 25px;
    word-spacing: 4px;
}
*[dir="rtl"] .uaecareer>p {
    font-size: 13px !important;
    font-weight: 800;
}

*[dir="rtl"] .customers1 th:last-child {
    border-radius:  0px;
}

*[dir="rtl"] p.chartheader {
    padding-top: 10px;
    padding-right: 15px;
    font-family: 'Noto Sans Arabic', sans-serif;
}

*[dir="rtl"] .presictData h6 {
    padding-right: 15px;

}

*[dir="rtl"] .inner_itemraces {
    justify-content: space-between;
}


*[dir="rtl"] .presictData h3 {
    padding-right: 15px;
}

*[dir="rtl"] .presictData span {
    padding-right: 15px;
}

*[dir="rtl"] .bannerCard>img {
    float: left;
    margin: 0rem;
}

*[dir="rtl"] .searchchild {
    left: 0 !important;
    top: 100%;
    right: unset !important;
}
*[dir="rtl"] .meetdetail h2{
    text-align: right !important;
}
*[dir="rtl"] .meetdetail p{
    text-align: right !important;
}
*[dir="rtl"] .col-sm-4.meetingtyp h2 {
    text-align: right !important;
}
*[dir="rtl"] .col-sm-4.meetingtyp p {
    text-align: right;
}
*[dir="rtl"] .rightlayoutinner {
    display: flex;
    justify-content: space-between;
    gap: 19px;
    padding-right: 0 !important;
    align-items: baseline;
    padding-left: 10px !important;
}

*[dir="rtl"] .sponsor {
    margin-left: 10px;
}

*[dir="rtl"] .dropdown-toggle::after {
    position: relative;
    right: 10px;
}

*[dir="rtl"] .owner {
    text-align: start;
}
*[dir="rtl"] .racecardrow {
    /* flex-direction: row-reverse; */
    margin: -13px -0pxx;
}

*[dir="rtl"] .clubname {
    text-align: start;
}

*[dir="rtl"] .CompetitionName {
    padding: 30px 150px 5px 15px;
}



*[dir="rtl"] button.eventdata1 {
    background: #19469d;
    /* z-index: 999999999999 !important; */
    border-radius: 0px;
    color: #fff;
    /* padding: 10px; */
    height: 35px;
    width: 35px;
    /* position: absolute; */
    /* top: 92px; */
    margin-left: 0px;
    opacity: 0.8;
    /* margin-top: 3px; */
    padding: 12px 0px;
    /* z-index: -12 !important; */
    position: absolute;
}



*[dir="rtl"] p.cardnationality {
    text-align: start;
}
*[dir="rtl"]  .middlesection{
padding-right: 0 !important;
padding-left: 10px !important;
}

*[dir="rtl"] span.raceroursecolor {
    height: 4px !important;
    width: 30% !important;
    position: absolute;
    left: auto;
    top: 90%;
    right: 5%;
}
*[dir="rtl"] .AccordionHeader>p:nth-child(1) {
    font-weight: 600;
    font-size: 18px;;
}
*[dir="rtl"] span.race p:nth-child(2) {
    font-weight: 600;
    text-transform: uppercase !important;
}
*[dir="rtl"] h5.tooltipclass {
    font-weight: 800;
}
*[dir="rtl"] .racepageitem p {
    font-weight: 800;
    font-size: 15px;
    word-spacing: 7px;
    text-transform: uppercase !important;
}
*[dir="rtl"] .RaceDetailsName h6 {
    margin: 10px 40px;
    font-size: 19px;
}



*[dir="rtl"] .top p {
    font-size: 14px !important;
    word-spacing: 2px !important;
    line-height: 20px !important;
}
*[dir="rtl"]  .cardracesjockeyleft p:nth-child(3) {
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.5);
}
*[dir="rtl"]  span.cardraces1box p:last-child {
    float: left !important;
}
*[dir="rtl"] .pmclass>p {
    font-size: 18px !important;
    font-weight: 600;
}
*[dir="rtl"]  .AccordionHeader>p:nth-child(1) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 21px !important;
    line-height: 19px;
    color: #000000;
}
*[dir="rtl"] .filterbtn {
    right: 86%;
    margin-top: -55px;
}
*[dir="rtl"] .Summarydata p {
    font-size: 16px !important;
    text-transform: capitalize;
    font-weight: 500;
}
*[dir="rtl"] tbody.querytablebody td {
    font-size: 14px !important;
}
*[dir="rtl"] tbody.querytablebody time {
    font-size: 14px !important;
}
*[dir="rtl"] .resultimages {
    height: 100px;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
}
*[dir="rtl"] .resultimages span {
    margin-right: 20px;
}
*[dir="rtl"] .cardraces2 p {
    font-size: 17px !important;
    font-weight: 800 !important;
}

*[dir="rtl"] marquee p {
    font-weight: 400 !important;
    font-size: 20px !important;
}

*[dir="rtl"] .racecardcss1.classtyperaces1 p {
    font-size: 15px;
}


*[dir="rtl"] .orangeclass {
    margin: 0px -6px !important;
}

*[dir="rtl"] .tooltipclass {
    max-width: 18vw !important;
}

*[dir="rtl"] .cardraces2 div:nth-child(3) p {
    font-size: 16px !important;
    padding: 10px 0px 5px 0px;
    line-height: 15px !important;
}
*[dir="rtl"] .BodyNew.mpobile0 td {
    font-size: 14px;
}
*[dir="rtl"] .horsecenterdata.jockeyname1 p {
    margin-right: 7px;
}
*[dir="rtl"] .modal-body table.statsTable.innermodalstats {
    width: 100% !important;
    margin-left: 0px !important;
}
*[dir="rtl"] .modal-body table.statsTable.innermodalstats th{
    padding-left: 2% !important;
}
*[dir="rtl"]  td.rb img {
    width: 30%;
    object-fit: cover;
}
*[dir="rtl"] .customClass .lb {
    border-radius: 0px  !important;
}
*[dir="rtl"] .Trophydata_P>h6 {
    font-size: 14px !important;
    font-weight: 800 !important;
    padding-bottom: 20px !important;
}
*[dir="rtl"] .prizecard {
    padding: 12px;
    height: 190px !important;
}
*[dir="rtl"] .customers td, .customers th {
    padding: 10px 14px !important;
    text-align: center;
    font-size: 15px;
}
*[dir="rtl"]  .racetime {
    color: #fff !important;
    text-align: left !important;
}
*[dir="rtl"]  span.mybor {
    margin-right: 7%;
}
*[dir="rtl"] .row.uaestats p {
    text-align: center;
    font-size: 14px;
}

*[dir="rtl"] .racecardrow {
    margin: -14px 0px !important;
}
*[dir="rtl"] .col-sm-9 {
    padding-right: 0;
    padding-left: 10px !important;
}
*[dir="rtl"]  .liveresult1 .customers1 td {
    color: #000;
    font-size: 14px !important;
    padding: 15px 0px;
    padding-left: 0px !important;
    background-color: #E2F4F5;
    border-bottom: 3px solid #fff;
    padding-right: 15px;
}
/* *[dir="rtl"] button#uncontrolled-tab-example-tab-ante {
    width: 180px !important;
} */
*[dir="rtl"]  .row.login .showIcon {
    right: unset !important;
    left: 2% !important;
}
*[dir="rtl"] .forget p {
    text-align: left !important;
}
*[dir="rtl"] .registration .col-sm-6 {
    text-align: right !important;
}
*[dir="rtl"]  .login div button {
    width: 100% !important;
}
*[dir="rtl"]  svg.crossIcon {
    right: 0 !important;
}
*[dir="rtl"]  .searchbox input {
    padding-right: 10px;
}
@media only screen and (max-width: 1540px) {
    *[dir="rtl"] button#uncontrolled-tab-example-tab-ante {
        width: 165px !important;
    }
    *[dir="rtl"]  .buttonSubscribe {
        margin: 0px 25px !important;
    }
    *[dir="rtl"] .topbar .dropdown {
        padding-right: 0 !important;
        margin-top: 0px;
    }
}
@media only screen and (max-width: 1440px) {
    *[dir="rtl"] button#uncontrolled-tab-example-tab-ante {
        width: 145px !important;
    }
    *[dir="rtl"] .menuNav {
        justify-content: flex-start !IMPORTANT;
    }
    *[dir="rtl"] .searchchild {
        left: 3.5% !important;
        top: 100%;
        right: unset !important;
    }
}
@media only screen and (max-width: 1370px) {
    *[dir="rtl"] .buttonLogin {
        padding: 10px 60px !important;
     }
     *[dir="rtl"] .mylogo img {
        width: 80%;
        margin-right: 0;
    }
    *[dir="rtl"] .searchchild {
        left: 3.5% !important;
        top: 100%;
        right: unset !important;
    }
}
@media only screen and (max-width: 1024px) {
    .rightlayoutinner {
        padding: 0px 0px;
        padding-top: 0px !important;
        padding-bottom: 10px !important;
        margin-top: -11px !important;
    }
}
@media only screen and (max-width: 520px) {
    *[dir="rtl"] .headerlayout {
        padding-right: 20px;
      }
      *[dir="rtl"]  .mobiledropdown {
        right: 0;
    }
    *[dir="rtl"]  .auth1 a {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
    }
    *[dir="rtl"] .buttonLogin {
        padding: 10px 40px !important;
    }
    *[dir="rtl"] .sponsor {
        margin-left: 0;
        margin-right: 15px !important;
    }
    .racestatuscolor {
        display: flex;
        flex-direction: row;
        justify-content: flex-start !important;
        align-items: center;
        gap: 5px;
        padding-top: 6px;
        width: 100% !important;
    }
    *[dir="rtl"] .racenameflex {
        margin-right: 0px !important;
    }
    *[dir="rtl"] p.itemtime {
        margin-right: 15px !important;
    }
    *[dir="rtl"] .mobilemenu .dropdown {
        margin-top: 0px;
    }
    *[dir="rtl"] .tooltipclass {
        max-width: 100% !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 35px;
    }
    *[dir="rtl"] .tooltipclass1 {
        max-width: 40vw !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 35px;
    }
    *[dir="rtl"] .mobilesitewidth.racecardpage.accordion.accordion-flush button.accordion-button {
        padding: 0;
        height: 110px;
    }
    *[dir="rtl"] .dropdown-content p {
        text-align: right !important;
    }
    *[dir="rtl"] .dropdown.LinkStyle .dropdown-content {
        top: 35px;
        width: 108% !important;
        left: -15px !important;
    }
    *[dir="rtl"] div#selectNow2 {
        margin-left: 10px !important;
        margin-right: 0 !important;
    }
    *[dir="rtl"] .dropdown-content {
        border-radius: 0 !important;
        left: -150px;
    }
    *[dir="rtl"] footer.w-100.py-4.flex-shrink-0 ul {
        padding-right: 0px;
    }
    *[dir="rtl"] button#dropdown-basic {
        margin-top: 0px !important;
    }
    *[dir="rtl"] .searchchild {
        width: 100% !important;
        left: 0% !important;
        top: 100%;
        right: unset !important;
        margin: 0;
    }
    *[dir="rtl"] p.searchname {
        line-height: 18px;
        font-size: 13px;
        margin-bottom: 16px;
    }
}