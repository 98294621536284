* {
  font-family: "Inter";

}

:root {
  --primary-color: var(--primary-color);
  --secondary-color: var(--secondary-color);
  --third-color: var(--third-color);
  --third-color: var(--fourth-color);
  --fifth-color: var(--fifth-color);
}

body {
  overflow-x: hidden !important;
  -webkit-font-smoothing: none !important;

}


body::-webkit-scrollbar {
  width: 0.1em;
  -webkit-font-smoothing: none !important;
  z-index: -9999;

}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, rgb(48, 8, 139), darksalmon);
}


p,h1.h2,h3,h4,h5,h6{
  text-transform: none !important;
  font-family: "Inter";
}

.HomeLeftSection::-webkit-scrollbar {
  width: 0.1em;
}


.HomeRightSection::-webkit-scrollbar {
  width: 0.1em;
}

.Competitiontrophy::-webkit-scrollbar {
  width: 0.1em;
}

.RaceDetailHeader::-webkit-scrollbar {
  width: 0.1em;
}

.RaceAndHorseModelDataCSSFlex::-webkit-scrollbar {
  width: 0.1em;
}

.LeaderData::-webkit-scrollbar {
  width: 0.1em;
}

.newpost::-webkit-scrollbar {
  width: 0.4em;
}

.racestatuscolor::-webkit-scrollbar {
  width: 0.1em;
}
.racestatuscolor {
  margin-right: 10px !important;
  overflow: auto;
  cursor: grab;
}

.carousel-control-prev-icon {
  display: block !important;
  color: #000 !important;
  display: none !important;
}

.carousel-control-next-icon {
  display: block;
  color: #000 !important;
  display: none !important;


}
.carousel-control-prev {
  left: 0;
  margin-left: -0%;
}
.carousel-control-next {
  right: 0;
  margin-right: 0%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 9px;
  height: 9px;
  opacity: 0.5;
  transition: opacity 0.6s ease;
  border-radius: 50% !important;
  color: rgb(53, 53, 53) !important;
}

.ForgetPassword1 {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
button.verifyemailagain {
  display: flex;
  border: none;
  padding: 10px;
  border-radius: 10px;
}

a {
  color: #000;
  text-decoration: none;
}
.mobiletophead1{
  display: none;
}
.mobiledata {
  display: none;
}

.mobilemenudata {
  display: none;
}

.scroll-container1 {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
  border-radius: 20px;
}

.react-calendar__month-view__days__day--weekend {
  color: #000;
}

.searchLottie {
  height: 100px !important;
}

.homeLottie {
  height: 500px !important;
  position: absolute;
  top: 40%;
  left: 40%;
}

.compLottie {
  height: 200px;
}

.slideLottie {
  height: 300px !important;
}

.trackerTab {
  margin-bottom: 39px !important;
  margin-left: 67px !important;
}

/* .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border: none;
  color: #fff !important;
  font-weight: 700;
  background: #19469D !important;
  width: 220px;
  border-radius: 25px;
  height: 52px;
} */
.tracker {
  color: red !important;
}

.trackerTab button#noanim-tab-example-tab-1 {
  background: linear-gradient(89.98deg, #4e4e4e 23.67%, #979695 97.94%);
}

.trackerTab button#noanim-tab-example-tab-2 {
  background: linear-gradient(89.98deg, #4e4e4e 23.67%, #979695 97.94%);
  margin: 0 20px;
}

.trackerTab.active {
  border: none;
  color: #fff !important;
  font-weight: 700;
  background: red !important;
}

.load {
  margin: 230px auto;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.paginationItem {
  background: #fff;
  border: 2px solid #666;
  padding: 10px 15px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: blue;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
}

.paginationItem.active {
  border: 1px solid #888;
  color: #888;
  pointer-events: none;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}

.page-link {
  cursor: pointer;
}

.pleaseWait {
  left: 46%;
  position: absolute;
  right: 0;
  z-index: 999;
  top: 103%;
  color: black !important;
  margin: auto;
  transform: translateY(-50%);
}
.pleaseWait.nohistory {
  margin: -1px;
  text-decoration: underline;
}
.pleaseWait span {
  position: absolute;
  height: 10px;
  width: 84px;
  top: 50px;
  overflow: hidden;
}

.pleaseWait p {
  color: black;
}

/* .react-calendar__navigation__prev-button::before{
  content: "«";
  display: inline-block;
  margin-left: 0.5em; 
}

.react-calendar__navigation__next-button::before {
  content: "»";
  display: inline-block;
  margin-right: 0.5em; 
} */



.pleaseWait span>i {
  position: absolute;
  height: 4px;
  width: 10px;
  border-radius: 50%;
  -webkit-animation: wait 4s infinite;
  -moz-animation: wait 4s infinite;
  -o-animation: wait 4s infinite;
  animation: wait 4s infinite;
}

.pleaseWait span>i:nth-of-type(1) {
  left: -28px;
  background: rgb(15, 0, 133);
}

.pleaseWait span>i:nth-of-type(2) {
  left: -21px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: rgb(100, 100, 240);
}

@-webkit-keyframes wait {
  0% {
    left: -7px
  }

  30% {
    left: 52px
  }

  60% {
    left: 22px
  }

  100% {
    left: 100px
  }
}

@-moz-keyframes wait {
  0% {
    left: -7px
  }

  30% {
    left: 52px
  }

  60% {
    left: 22px
  }

  100% {
    left: 100px
  }
}

@-o-keyframes wait {
  0% {
    left: -7px
  }

  30% {
    left: 52px
  }

  60% {
    left: 22px
  }

  100% {
    left: 100px
  }
}

@keyframes wait {
  0% {
    left: -7px
  }

  30% {
    left: 52px
  }

  60% {
    left: 22px
  }

  100% {
    left: 100px
  }
}

.load1 {
  margin: -33px auto;
  width: 400px;
}

.noFoundHeader {
  font-size: 1.5rem;
  margin: 57px auto;
  text-align: center;
  letter-spacing: 3px;
}

.left {
  direction: ltr;
}

.right {
  direction: rtl;
}

.pagination button {
  cursor: pointer;
  border: none;
  background: transparent;
}
/* .themeclass{
  background: rgb(109, 26, 26) !important;
} */

.col-sm-10.flex {
  display: flex;
}

span.notificationicon1 svg {
  width: 50px;
  height: 40px;
  margin-left: 20px;
}
.col-sm-8.stagescenter{
  margin: auto;
}

h4.viewnotification {
  font-size: 16px;
  padding: 11px;
  text-decoration: underline;
  font-weight: 600;
}

/* .inputsdata1 > input {
  width: 32px;
  height: 32px;
  text-align: center;
  border: none;
  border-bottom: 1.5px solid #d2d2d2;
  margin: 0 10px;
} */

.inputsdata1 > input {
  padding: 12px;
  font-size: 18px;
}

.inputsdata1 > input::placeholder {
    font-size: 18px;
    line-height: 4;
}


.inputsdata1{
  display: flex;
  justify-content: center;
}
.dataverify{
  margin-top: 12%;
}


.registrationform>div {
  width: 500px;
  margin: auto;
  background: var(--primary-color) !important;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.registrationform>div>h5 {
  padding-top: 20px;
  text-align: left;
  font-size: 28px;
  margin-left: 18px;
  color: #fff;
  font-family: sans-serif;
  font-weight: 700;
}


.registrationform>div>p {
  text-align: left;
  font-size: 12px;
  margin-left: 18px;
  color: #fff;
  font-family: sans-serif;
}

.pleaseWaitnodata {
  left: 0%;
  position: absolute;
  right: 0;
  z-index: 999;
  /* top: 103%; */
  color: black !important;
  margin: auto;
  transform: translateY(-50%);
}

.tooltipclass1 {
  max-width: 5vw !important;
}
.registration .ant-picker {
  width: 100% !important;
  padding: 12px 10px !important;
  border-radius: 0px !important;
}

.ant-picker {
  width: 100% !important;
  padding: 11px !important;
  border-radius: 0px !important;
}

input[type="text"]::placeholder {
  font-size: 17px !important;
}

tr.BodyNew.mpobile01.historyhorseth.newdata-stats{
    background: var(--primary-color);
}