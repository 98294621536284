:root {
    --primary-color: var(--primary-color);
    --secondary-color: var(--secondary-color);
  }
.RaceCardSection {
    margin-top: 2px;
    display: grid;
    grid-template-columns: auto 10%;
    column-gap: 1.1rem;
}

.RaceCardSlider {
    height: 158px;
    left: 18px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}

.slidercards {
    width: 100%;
    margin-inline: auto;
}

.slick-slide.slick-active {
    outline: none;
    width: 250px;
}

.singleracecard {
    box-sizing: border-box;
    width: 235px !important;

    height: 130px;
    cursor: pointer;
    background: #FFFFFF;
    border: 2px solid #DFDFDF;
    border-radius: 0px;
    transition: 0.4s linear all;
}

.singleracecardbtn {
    display: none;
    transition: 0.4s linear all;

}

.singleracecardbtn button {
    border: none;
    background: var(--primary-color) !important; 
    margin-left: 0px;
    color: #fff;
    height: 30px;
    width: 60px;
    border-radius: 10px;
}

/* .singleracecard:hover {
    height: 155px;
}

.singleracecard:hover>.singleracecardbtn {
    margin-top: -15px;
    display: block;
    transition: 0.4s linear all;

} */

/* .singleracecard:hover .racetime{
    padding-top: 15px;
} */
.racecardrow {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin: -14px 12px;
}

.clubname {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1px;
    color: #000000;
    padding: 0px 5px 0px 17px;
}

.owner {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 14px;
    padding: 0px 5px 0px 17px;
}
*[dir="rtl"] .owner {
    padding: 0px 5px 2px 22px;
}
.raceNo {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    padding: 0px 6px;

    color: rgba(0, 0, 0, 0.5);
}

.racedistance {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: rgba(0, 0, 0, 0.5);
}


.racetime {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 21.4333px;
    line-height: 25px;
    margin-left: 0px;
    color: #FF9900;
    width: 90px;
    height: 30px;
    overflow: hidden;
    transition: 0.4s linear all;
    text-align: center;
}

/* .racetime{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 23.4333px;
line-height: 28px;
margin-left: -30px;
color: #FF9900;
width: 66px;
height: 30px;
overflow: hidden;
transition: 0.4s linear all;
} */
/* .racetime:hover{
    transition: 0.4s linear all;
    margin-bottom: 20px;
    position: relative;
    top: 20px;
} */


button.slick-arrow.slick-prev.slick-disabled {
    width: 25px;
    height: 124px;
    background: #D9D9D9;
    border-radius: 10px;
    margin-left: -10px;
}

button.slick-arrow.slick-next.slick-disabled {
    width: 25px;
    height: 124px;
    background: #D9D9D9;
    border-radius: 10px;
    margin-right: 22px;
}

button.slick-arrow.slick-next {
    width: 25px;
    height: 124px;
    background: #D9D9D9;
    border-radius: 10px;
    margin-right: 22px;
}

button.slick-arrow.slick-prev {
    width: 25px;
    height: 124px;
    background: #D9D9D9;
    border-radius: 10px;
    margin-left: -10px;
}

/* -------------------Addington CSS ------------------*/

.Addington {
    flex: 1;
}

.AddingtonStyle {
    width: 117px;
    height: 100px;
    /* margin: auto; */
    margin-top: 5px;
}

.AddingtonStyle img {
    width: 84px;
    height: 101.9px;
}

.Addingtontimer {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 21.4118px;
    line-height: 6px;
    color: #19469D;
    display: flex;
    width: 131px;
}

.Addingtonname {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 2px;
    color: #000000;
    padding-left: 8px;
    /* margin: 1.1rem; */
}

.jockeyimagedetail {
    width: 50px;
    height: 50px;
}

.ownerdetailjockey {
    width: 150px;
    height: 150px;
}

.noimageclass {
    display: flex;
    justify-content: center;
    margin: auto;
    object-fit: cover;
}

img.noimage {
    width: 100%;
    object-fit: cover;
}

p.cardnationality {
    /* background: aquamarine; */
    background: var(--fourth-color) !important;
    border-radius: 0px;
    display: flex;
    padding: 5px 0px 1px 15px;
    color: #fff;
    /* height: 22px; */
    justify-content: space-between;
}

.col-sm-2.Addingtondiv {
    background: var(--fourth-color) !important;
    padding: 25px 45px;
    margin-bottom: 22px;
    /* padding: 7px 17px; */
    margin-right: 10px;
    width: 15%;
}

p.LeaderCompetition {
    color: #fff !important;
}

.compickdisplay {
    display: flex;
}
.Addingtonname {
    color: #fff;
}
.Addingtontimer {
    color: #fff;
}
p.compick {
    color: var(--primary-color) !important;
    padding: 0px 8px;
}

