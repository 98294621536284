.RaceCardDetail {
    width: 100%;
    /* margin-top: 20px; */
}

.RaceDetailHeader {
    background: var(--fourth-color) !important; 
    border-radius: 18px;
    /* max-height: 900px; */
    height: -moz-fit-content;
    height: fit-content;
    margin-inline: auto;
}

.RaceAndHorseModelDataCSS {
    border: 1px solid rgb(112, 111, 111);
}

.match {
    margin-top: -17px;
}

.RaceDetailCard {
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    /* background: linear-gradient(180deg, #078BE6 0%, #19469D 100%), #19469D; */
    border-radius: 0px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    background: #fff;
}

.racedisc {
    width: 98%;
    height: fit-content;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    margin-inline: auto;
}

.prizecard {
    box-sizing: border-box;
    width: 98%;
    height: 152px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    margin-inline: auto;
    margin-top: 10px;
}

.prizecard>p {
    margin: 10px;
}

.profile-tabitem {
    font-size: 14px;
}

.prizeposition {
    display: flex;
    justify-content: space-evenly;
}

.innerprize {
    display: flex;
}



.inner_itemraces {
    display: flex;
    /* justify-content: space-between; */
}

.itemraces_left {
    display: flex;

}

.itemraces_right {
    display: flex;
    margin: 0px 15px 0px 0px
}

.itemraces_left>.race {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: 58px;
    height: 77px;
    background: #5EC40F;
    border-radius: 0px;
    margin: 17px 10px 0 16px;
    color: #fff;
    font-weight: 300;
    font-size: 13px;
    line-height: 1px;
}

.itemraces_left>.sponsor {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 0.96617px;
    gap: 10.76px;
    width: 84px;
    height: 77px;

    background: #CFCFCF;
    border-radius: 12.98px;
}

.itemraces>p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12.8px;
    line-height: 17px;
    color: #000000;
    margin: 10px 10px 20px 16px;

}

.itemraces_right .distance {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5.97744px 8.96617px;
    gap: 10.76px;
    width: 152px;
    height: 77px;
    left: 778px;
    top: 307px;
    margin: 17px 20px 0 0;
    border: 4px solid #BA7E25;
    border-radius: 20px;
}

.distance>p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 12.1729px;
    line-height: 15px;
    text-align: center;
    color: #727272;
    margin-top: 15px;

}

.itemraces_right .Favourite {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 5px;
    margin-top: 22px;
}

.racenameflex {
    display: flex;
    margin-left: 12px;
    margin-top: 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22.5949px;
    line-height: 27px;
}

.racenameflex>p {
    margin: 5px 16px 9px 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 22.5949px;
    line-height: 27px;
    color: #FFFFFF;
}

.racenameflex>img {
    margin: 6px 20px 0 0;
    font-style: normal;
    font-weight: 700;
    font-size: 22.5949px;
    line-height: 27px;
    color: #FFFFFF;
    height: 29px;
    width: 29px;

}

.itemtime {
    margin: 0px 3px 9px 30px;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 22.5949px;
    line-height: 27px;

}


.predict {
    display: flex;
    justify-content: space-between;
    /* width: 688px; */
    height: 60px;
    background: #5EC40F;
    border-radius: 18px;
    margin: 9px 0 0 0px;

}

.predictdata {
    display: flex;
    position: relative;
    left: 0%;
    width: 80%;
}

.predictdata img {
    width: 60px;
    height: 35px;
    margin-left: 20px;
    margin-top: 8px;
}

span.innerpredictdata {
    margin-top: 130px;
}

.AccordionBody11 {
    margin: -11px -54px 0px -20px;
    width: 100%;
    background: transparent;
}

.innerpredictdata h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 126.02%;
    margin-left: 20px;
    /* or 15px */


    color: #FFFFFF;
}

.innerpredictdata p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24.02%;
    margin-left: 20px;
    /* or 15px */


    color: #FFFFFF;
}

.predict button {
    width: 49px;
    height: 52px;
    left: 1023px;
    top: 707px;
    background: #FF0000;
    border-radius: 18px;
    border: none;
    margin-top: 3px;

    color: #FFFFFF;
}

.Summary {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
}

.SummaryDistance {
    box-sizing: border-box;
    width: 316px;
    height: 332px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 18px;
    margin-top: 10px;
}

.SummaryDistance>p {
    font-style: normal;
    font-weight: 700;
    font-size: 19.6px;
    line-height: 24px;
    color: #000000;
    margin: 17px 16px 9px 18px;

}

.Summarydata {
    display: flex;

    padding: 4px;


}

.imgDiv {
    width: 60px;
    height: 50px;
}

.Summarydata img {
    width: 51px;
    height: 37px;
}

.Summarydata p {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    padding: 7px 0px;
    margin: 0 18px;
}

.horseRecordTable {
    margin-left: -4px !important;
    width: 101% !important;
}

.customers {
    /* margin-left: 10px; */
    width: 100%;
}

.customers td,
.customers th {
    /* background: #5ec40f; */
    border: transparent;
    color: #fff;
    padding: 16px 14px;
    text-align: center;
}

.customers th {
    padding-top: 20px;

    text-align: center;
    background: transparent;
    color: #000;

}

.customers2 {
    width: 98%;

    margin: auto;
}

.customers2 td,
.customers2 th {
    border: transparent;
    padding: 8px;
    background: transparent;
    color: #fff;
}


.customers2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    color: #fff;
    width: 7%;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
}

th {
    font-size: 12px;
}

td {
    font-size: 13px;
}

.drawchart {
    width: 500px !important;
    height: 430px;
    margin: auto;
}

.Printout {
    height: 500px;
}

.LinkStyle {
    list-style: none;
    text-decoration: none;
    color: inherit;
}

.LinkStyle:hover {
    list-style: none;
    text-decoration: none;
    color: inherit;
}

.PrintOutClass {
    border: none;
    width: 100%;
    margin-top: 10px;
}

.RaceDesc {
    display: flex;
    justify-content: space-between;
}

.itemraces_center {
    margin-top: 20px;
    flex: 2;
    margin-left: 20px;
}

.colorheader {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.racestatuscolor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;
    width: 50%;
    padding-top: 9px;
}

.racestatuscolor>li {
    list-style: none;
    text-align: center !important;
    width: 45px;
    text-align: center;
    align-items: center;
    padding: 4px 7px;
    color: #ffff !important;
    height: 35px;
    cursor: pointer;
    font-size: 12px;
    justify-content: center;
    background: #5EC40F;
    border-radius: 0px;
    display: flex;
}

.racestatuscolor>li:active {

    background: #19469D !important;

}

.arrow {
    font-size: 30px;


}


.racestatuscolor>li:nth-child(3) {
    background: #ED6203;
}

.racestatuscolor>li:nth-child(7) {
    background: #FF0000;
}

.itemraces_center>h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-transform: capitalize;
}

.itemraces_center>p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
}

.Trophydata_P>h6 {
    margin-top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    margin-left: 10px;
}

.prizecardheaders {
    display: flex;
    justify-content: space-between;
    width: 92%;
    margin: auto;
    padding-top: 15px;
}

.videoicon {
    margin-top: -3px;
    height: 11px;
    width: 6px;
    margin-right: 15px;
}

b,
strong {
    font-weight: 800;
}

.cardraces1>img {
    width: 92px;
    height: 67px;
    margin-top: 15px;
}

.cardracesjockey {
    display: flex;
}

.cardracesjockeyleft>p {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */


    color: #000000;
}

.cardracesjockeyimg {
    width: 50px;
    height: 50px;
}

.cardraces2 {
    flex: 3;
}

.cardracesjockeycards>ul li:nth-child(2) {
    background: #078BE6;
}

.cardracesjockeycards>ul li:nth-child(3) {
    background: #979695;
}

.cardracesjockeycards>ul li {
    list-style: none;
    width: 22px;
    height: 21px;
    left: 937px;
    top: 800px;
    color: #fff;
    background: #fff;
    text-align: center;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    padding-top: 5px;

}

.cardracesjockeycards>ul {
    display: flex;
    justify-content: space-evenly;
}

.race_trainerbreader>p {
    font-style: normal;

    font-size: 10px;
    line-height: 6px;
    margin-left: 3px;
    width: 100%;
    color: #000000;
}

.prizecardheaders {
    width: 98%;
}

.trainerbreader_section {
    display: flex;
}

.trainerbreader_image {
    width: 30px;
    height: 30px;
}

.RaceAndHorseModelDataCSS {
    border: 1px solid rgb(214 211 211);
    border-radius: 20px;
}

.RaceAndHorseModelDataCSSFlex {
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-height: 89rem;
    min-height: 55rem;
    overflow: auto;
}

.weatherIcon {
    width: 26px;
    height: 26px;
    color: rgb(112, 112, 112);
}

.RaceDetailsName {
    display: flex;
}

.RaceDetailsName h6 {

    margin: 10px 40px;
}

.cardraces2 img {

    width: 19px;
    height: 19px;
    margin: 0 5px;
}

.ShowPreviousHistory {
    border: none !important;
    /* border-bottom: 1px solid black !important; */
    margin: auto;
    background: transparent;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
    line-height: 11px;
    /* identical to box height */

    text-decoration-line: underline;

    color: rgba(0, 0, 0, 0.5);
}

.RaceAndHorseModelDataCSS .card {
    border-radius: 15px;
    border: none;
}

.raceName h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin: 17px 15px;

}

.raceName {
    display: block;
}

.itemsraces>p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12.8px;
    line-height: 17px;
    color: #000000;
    margin: 5px 10px;
}

.RaceNav {
    background: rgb(25, 70, 157) !important;

}

.showMore {
    cursor: pointer;
    border: none;
    background: transparent;
}

/* .hosekindSection {
    margin: -17px 25px 0px 188px;
} */

.hosekindSection {
    margin: -18px 7px 0px 104px;
    line-height: 10px;
    font-size: 15px;
}

.nonrunner {
    position: absolute;
    height: 220px;
    background: rgb(209, 211, 211);
    opacity: 0.4;
    width: 100%;
    text-align: center;
    font-size: 45px;
    color: #496394;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -15px;
    font-weight: bolder;
    /* letter-spacing: 10px; */
}

.card-header {
    position: relative;
}

/* .nonrunner span {
    transform: skew(-15deg, 15deg);
} */

.distance img {



    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10.76px;
    width: 152px;
    height: 77px;


    border-radius: 20px;
}

.tooltipclass {
    max-width: 9vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* maxHeight: "400px",
    maxWidth:'250px',
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap", */
}

thead.customers5 {
    height: 65px;
}

.historyhorseth th {
    color: #fff;
}

.predictDetail {
    line-height: 0px;
    margin-top: 10px;
    margin-left: 10px;
}

.predictDetail>h6 {
    color: #fff;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 11px !important;
    font-style: normal;
    width: 70px !important;


}

.predictDetail>p {
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

}

.registrationform>form>h5 {
    padding-top: 20px;
    text-align: left;
    font-size: 28px;
    margin-left: 18px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 700;
}

.registrationform>form>p {

    text-align: left;
    font-size: 12px;
    margin-left: 18px;
    color: #fff;
    font-family: sans-serif;
}

.BodyNew1 {
    height: 55px;
    /* background: #5EC40F; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 25px;
}

.racetime1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 21.4333px;
    line-height: 28px;
    /* margin-left: 0px; */
    color: var(--secondary-color) !important; 
    /* width: 90px; */

    overflow: hidden;
    display: flex;
    transition: 0.4s linear all;
}

span.raceroursecolor1 {
    height: 6px !important;
    /* width: 9%; */

    width: 62px;
    position: relative;
    left: -34%;
    top: 41px;
    margin-top: -6px;
}

.customers1 .r b {
    border-radius: 0px 20 20px 0px;
}

h4.noFoundHeader {
    display: none;
}

h4.noFoundHeader:first-child {
    display: block !important;
}