:root {
  --primary-color: var(--primary-color);
  --secondary-color: var(--secondary-color);
}

@media only screen and (max-width: 1680px) {
  .sponsorimg {
    height: 52vh !important;
    margin-right: 10px;
  }
  button#uncontrolled-tab-example-tab-contact svg {
    color: #000 !important;
    margin-left: -6px;
}

.sponsor {
  /* height: 100% !important; */
  margin: 10px -20px 0px 10px;
}
.match .mb-4 {
  margin: 0px 14px !important;
}
  body{
    overflow-x: hidden !important;
  }
  html{
    overflow-x: hidden !important;
  }
  *[dir="rtl"]  .react-calendar__tile button {
    height: 35px !important;
}
*[dir="rtl"]  .react-calendar__tile {
  height: 35px !important;
}
*[dir="rtl"] .filterbtn {
  font-size: 15px !important;
}
  .querytoolpagination {
    bottom: -22%;
    left: 40%;
}

.profilepic>img {
  width: 4rem;
  height: 65px;
}
.customers td, .customers th {
  padding: 0px 2px !important;
  font-size: 12px;
  width: 124px !important;
}
.noFoundHeader {
  font-size: 18px !important;
}
.cardracesjockeyleft > p {
  font-size: 12px;
  line-height: 10px;
}
  .resultslider_head {
    width: 100% !IMPORTANT;
}
  .cardracesAccordion {
    gap: 4px !important;
  }
  .ownernamestyle1 {
    line-height: 15px !important;
  }
  .slidercards {
    width: 100%;
    /* height: 150px; */
  }
  .singleracecard {
    height: 130px;
}
  button.slick-arrow.slick-next {
    margin-right: 12px !important;
  }

  .sponsor {
    height: 52vh !important;
  }

  .RaceCardSection {
    margin-top: 0px;
  }


  .swiper-button-next {
    right: 2%;
}

  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    right: 7%;
}
.add_acc_1.accordion-item {
  margin: 0px !important;
}

  /* .RaceCardSlider {
    height: 130px !important;
  } */
  .searchdatalist {
    text-align: unset;
  }
  .querypage {
    width: 100%;
  }

  span.racecardcolor1 {
    left: 4%;
  }

  .container.mystages {
    margin-top: 20px;
  }

  .atozParent h1 {
    font-size: 24px;
    padding: 15px 10px 5px 15px;
  }

  .atozParent th {
    font-size: 14px;
  }

  button#uncontrolled-tab-example-tab-home {
    padding: 20px 30px;
  }
  *[dir="rtl"] .searchdatalist {
    text-align: end;
}
p.updownicon {
  /* font-size: 10px; */
  margin: 10px 0px;
  color: #000;
  font-size: 10px;
}
  button#uncontrolled-tab-example-tab-ante {
    padding: 20px 30px;
}

  button#uncontrolled-tab-example-tab-contact {
    padding: 16px 44px;
  }

  .bannerText {
    padding: 30px !important;
    padding-top: 100px !important;
    position: relative !important;
  }

  .bannerText h1 {
    font-size: 42px;
  }

  .customers1 th {
    border: none;
    padding: 18px 20px;
  }

  .chartheader {
    font-weight: 600;
    font-size: 16px;
  }

  .col-lg-3.col-md-6.mobiledata1 {
    padding-left: 90px !important;
  }

  .mtfooter {
    margin-top: 45px;
    font-size: 18px;
    font-weight: 700;
  }
/* 
  .mtfooter1 {
    margin-top: 25px;
    width: 280px;
  } */

  p.footertext1 {
    padding-right: 0;
    font-size: 14px !important;
    line-height: 1.5;
  }

  .LinkStyle > li > p {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .text-muted > li > p {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
  }

  footer li b {
    font-size: 18px !important;
  }

  .formclass1 > input {
    height: 40px;
  }

  .CompetitionHeader h2 {
    font-size: 18px !important;
  }

  .AccordionHeader > p:nth-child(1) {
    font-weight: 400;
    font-size: 14px;
  }

  .Competition_Matches p {
    font-size: 14px;
  }

  .itemraces_center {
    flex: 0 0 35%;
  }

  .accordion-button {
    padding:15px 20px;
  }

  .BodyNew,
  .customers th {
    font-size: 12px;
  }

  .aab .accordion-body {
    background: #19469d;
    border-radius: 0px !important;
    margin-bottom: 15px;
    padding: 5px;
  }

  .cta a {
    font-size: 14px;
  }

  .itemraces_right .distance {
    width: 140px;
    height: 70px;
    margin-right: 0;
  }

  span.itemraces_right p {
    padding: 10px;
  }

  /* .itemraces_center div p:nth-child(2) {
    margin-left: 35px;
  } */

  span.itemraces_center p {
    font-size: 12px;
  }

  button.ShowPreviousHistory {
    width: 25%;
    margin: auto;
    display: block;
  }

  .uaecareer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
  }

  .pmclass > p {
    font-size: 9px;
    line-height: 4px;
  }

  .pointuser {
    font-size: 12px !important;
    line-height: 14px;
  }
  .inner_itemraces {
    gap: 20px;
}
.RaceCardSection {
  padding-top: 10px !important;
}
.header {
  height: 75px !important;
}

.slideimg1 {
  width: 100%;
  height: 470px !important;
}


.menuNav .logoclass {
  margin-top: 10px !important;
  width: 70% !important;
  height: auto !important;
}
.cta {
  padding-right: 60px;

}
.authhover {
  width: 230px;
}

.username {
  font-size: 13.6133px;
}
span.mybor {
  width: 93% !IMPORTANT;
  margin-top: -4%;
  margin-bottom: 3%;
}

.col-sm-2.Addingtondiv {
  padding: 20px 30px;
}
ul.mb-4.tabdata1.nav.nav-tabs button {
  margin-right: 1px;
}
span.Predictiontable>img {
  width: 35px;
  height: 35px;
}
.racepageitem h5 {
  font-size: 20px;
  width: 75px;
}
.col-sm-12.racedown {
  margin-top: 10px;
}
.row.uaestats p {
  font-size: 10px;

}
p.ownernamestyle1 b:nth-child(2) {
  margin-left: 4px !important;
  line-height: 18px;
}
p.tooltipclass1 {
  line-height: 15px;
}
.cardracesjockey {
  display: flex;
  justify-content: end !important;
}
.menus a {
  padding: 0px 30px !important;
}
.buttonLogin {

  padding: 10px 30px;

}

}
@media only screen and (max-width: 1540px) {
  .AdsCard .sponsorimg {
    height: 53vh !important;
    margin-right: 0 !important;
}
*[dir="rtl"] .trainerStages td {
  width: 11.5% !important;
}
*[dir="rtl"] .mystages td {
  padding: 12px 30px !important;
}

.buttonLogin {
  padding: 10px 30px;

}


*[dir="rtl"] .menuNav p {
  font-family: 'monospace';
  font-size: 17px !important;
  font-weight: 600 !important;
}
.registration {
  height: 100vh !important;
}

*[dir="rtl"] span.racecardcolor1 {
  right: 8%;
}


.username {
  font-size: 13.6133px;
}
.headerlayout {
  padding: 10px 0;
}
.dropdown-content p{
  width: 100% !important;
  text-align: left !important;
}
.middlebanner {
  height: 620px;
}
.aboutData {
  padding-left: 0 !important;
}
.bannerAbout .bannerText {
  padding: 30px !important;
  padding-top: 110px !important;
  position: relative !important;
}
.middlebanner section {
  width: 31.7%;
  text-align: center;
  height: 400px;
  background: white;
  position: relative;
  top: 25%;
  left: 12.1%;
  padding: 50px 60px;
}
.rightlayoutinner .sponsorimg {
  height: 52vh !important;
  margin-right: 0 !important;
}
  *[dir="rtl"]  .bannerText {
    padding: 30px !important;
    padding-top: 130px !important;
    position: relative !important;
}
.RaceCardSection {
  padding-top: 10px !important;
}
  body{
    overflow-x: hidden !important;
  }
  html{
    overflow-x: hidden !important;
  }
  /* .HomeLeftSection button {
    height: 55px !important;
} */
  p.adstitlepos {
    position: relative !important;
    left: 0 !IMPORTANT;
    top: 0 !IMPORTANT;
    color: #fff;
}
.inner_itemraces {
  gap: 20px !important;
}
.cardracesjockeyleft > p {
  font-size: 12px;
  line-height: 20px;
}

  .querytoolpagination {
    bottom: -22%;
    left: 36%;
}
  .cardracesAccordion {
    gap: 4px !important;
  }
  .ownernamestyle1 {
    line-height: 15px !important;
  }
  .resultslider_head {
    width: 74%;
  }

  /* .sponsor {
    height: 100% !important;
  } */

  .RaceCardSection {
    margin-top: 0px;
  }
  span.mybor {
    width: 56%;
    bottom: 0.6%;
}

.swiper-button-next {
  right: 1.2%;
}
  /* .RaceCardSlider {
    height: 130px !important;
  } */

  .querypage {
    width: 100%;
  }

  span.racecardcolor1 {
    left: 4%;
  }

  .container.mystages {
    margin-top: 20px;
  }

  .atozParent h1 {
    font-size: 24px;
    padding: 15px 10px 5px 15px;
  }

  .atozParent th {
    font-size: 14px;
  }

  button#uncontrolled-tab-example-tab-home {
    padding: 16px 25px;
  }

  button#uncontrolled-tab-example-tab-ante {
    padding: 16px 25px;
}

  button#uncontrolled-tab-example-tab-contact {
    padding: 16px 45px;
  }

  .bannerText {
    padding: 30px !important;
    padding-top: 155px !important;
    position: relative !important;
  }

  .bannerText h1 {
    font-size: 42px;
  }

  .customers1 th {
    border: none;
    padding: 18px 20px;
  }

  .chartheader {
    font-weight: 600;
    font-size: 16px;
  }

  .col-lg-3.col-md-6.mobiledata1 {
    padding-left: 90px !important;
  }

  .mtfooter {
    margin-top: 45px;
    font-size: 18px;
    font-weight: 700;
  }

  p.footertext1 {
    padding-right: 0;
    font-size: 14px !important;
    line-height: 1.5;
  }

  .LinkStyle > li > p {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .text-muted > li > p {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
  }

  footer li b {
    font-size: 18px !important;
  }

  .formclass1 > input {
    height: 40px;
  }

  .CompetitionHeader h2 {
    font-size: 18px !important;
  }

  .AccordionHeader > p:nth-child(1) {
    font-weight: 400;
    font-size: 14px;
  }

  .Competition_Matches p {
    font-size: 14px;
  }

  .itemraces_center {
    flex: 0 0 35%;
  }

  .accordion-button {
    padding: 15px 20px;
}

  .BodyNew,
  .customers th {
    font-size: 12px;
  }

  .aab .accordion-body {
    background: #19469d;
    border-radius: 0px !important;
    margin-bottom: 15px;
    padding: 15px;
  }

  .cta a {
    font-size: 14px;
  }

  .itemraces_right .distance {
    width: 140px;
    height: 70px;
    margin-right: 0;
  }

  span.itemraces_right p {
    padding: 10px;
  }

  /* .itemraces_center div p:nth-child(2) {
    margin-left: 35px;
  } */

  span.itemraces_center p {
    font-size: 12px;
  }

  button.ShowPreviousHistory {
    width: 25%;
    margin: auto;
    display: block;
  }

  .uaecareer > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
}

  .pmclass > p {
    font-size: 9px;
    line-height: 4px;
  }

  .pointuser {
    font-size: 12px !important;
    line-height: 14px;
  }
  .queryheader {
    display: flex;
    width: 98% !important;
}
.header {
  height: auto !important;
}
.menuNav .logoclass {
  margin-top: 10px !important;
  width: 70% !important;
  height: auto !important;
}

.AdsCard {
  background: #C4C4C4;
  width: 98% !important;
  height: -moz-fit-content;
  height: fit-content;
  position: sticky;
  top: 0;
  height: 400px !important;
  left: 1%;
  display: flex;
  /* justify-content: center; */
}
.card .cardraces2 span {
  font-size: 18px !important;
}
span.horseposition {
  font-size: 28px;
}
.cardraces2 b {
  margin-left: 10px;
  /* color: #000 !important; */
}
.itemraces_left>.race {
  width: 90px !important;
  height: 77px;
  font-size: 12px;
  padding-top: 15px;
}
.stages .statsTable {
  width: 90% !important;
}
.stages table.statsTable th:nth-last-child(2) {
  text-align: center !important;
  padding-left: 50px !important;
  width: 440px !important;
}
.stages.tens th {
  text-align: left;
  padding-left: 63px !important;
  width: 257px !important;
}
.posttabs p {
  font-size: 14px !important;
}
svg.chosecalender {
  height: 25px;
  color: #fff;
  margin-top: 5px;
}
.raceCarParent.stages.racecard1 h5 {
  font-size: 16px !important;
}
.raceCarParent.stages.stages123 h5 {
  font-size: 16px !important;
}
.raceresult p {
  font-size: 12px;
  font-weight: 700;
}
.meetdetail p span {
  font-size: 12px !important;
  color: #fff;
  /* padding-left: 5px; */
  font-size: 14px;
  font-weight: 700;
}
.meetdetail h2 {
  text-align: left;
  font-size: 16px !important;
  font-weight: 700;
  margin-left: 10px;
}
.meetdetail p {
  margin-left: 10px;
}
.middlesection {
  margin-top: 1.8rem !important;
}
.stages .mycardclass1 th {
  text-align: left;
  padding-left: 32px !important;
  width: 257px !important;
}
.stages .mycardclass1 td.rb img {
  width: 20%;
  object-fit: cover;
}
*[dir="rtl"] .dropdown-content {
  border-radius: 0px 0px 0px 0px;
  left: -90px;
}
.profilefile2 {
  background: var(--primary-color);
  width: 15% !IMPORTANT;
  color: #fff;
  text-align: center !IMPORTANT;
  padding-top: 14px;
  margin: 12px;
  display: inline-flex;
  justify-content: center !important;
  padding-right: 4%;
}
.col-sm-6.registrationimg img {
  width: 80%;
}
.register {
  padding: 0 !important;
  position: relative;
  top: 7%;
}
.registration img {
  width: 60%;
}
.menus a {
  padding: 0 30px !important;
}
.col-sm-10.menus .dropdown {
  padding: 0 30px !important;
}
.menus div {
  padding: 0 0px !important;
}
.mylogo img {
  width: 60%;
}
.col-sm-10.menus {
  margin-left: 0 !important;
}
.racetime p {
  font-size: 17px !important;
}
.profilepic>img {
  width: 4rem;
  height: 4rem;
  border-radius: 0px;
}


footer .container.py-4 {
  max-width: 90%;
}
ul.mb-4.tabdata1.nav.nav-tabs button {
  margin-right: 1px;
}
.row.desktopheader {
  width: 100%;
  align-items: center;
}
.liveresult1 .customers1 th {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  background: #fff;
  color: #000;
  font-size: 14px !important;
  padding-left: 15px;
}
.bannerHome {
  height: 360px !important;
  margin-top: 5px;
}
span.Predictiontable p{
  font-size: 14px I !important;
}
.Competition_Matches p:nth-child(2) {
  font-size: 11px !important;
  font-weight: 500;
}
.accordion div .Competition_Matches{
  font-size: 11px !important;
  font-weight: 500;
}
.col-sm-12.pmclass p {
  font-size: 12px;
}
.row.uaestats p {
  font-size: 11px;
  text-align: center;
  margin-bottom: 8px !important;
}
.row.uaestats .col-sm-2 {
  padding: 0 !important;
  width: 20%;
  margin-right: 10px;
}
.cardraces2 div:nth-child(3) p {
  font-size: 12px !important;
  padding: 10px 0px 5px 0px;
  width: 100%;
}
.cardraces1>img {
  width: 70px !important;
  height: 90px;
  margin-top: 15px;
  object-fit: scale-down;
}
.racecardcss1.classtyperaces1 p {
  font-size: 12px;
}
span.totalrunners {
  padding: 0 20px;
  font-size: 14px;
  color: #919191;
  margin: 0;
  padding-left: 6px;
  padding-right: 6px;
}
.racepageitem p {
  font-size: 11px;
}
.col-sm-12.racedown {
  margin-top: 10px;
  margin-bottom: 0 !important;
}
.RaceDetailsName h6 {
  margin: 10px 18px;
}
.col-sm-6.todayracefav h5 {
  width: 75px;
}
.itemraces>p {
  font-size: 14px;
  margin: 20px 10px 30px 16px;
  line-height: 22px;
}
.container-fluid.mystages.racecards .accordion-body .top p {
  font-size: 12px !important;
  margin-bottom: 5px;
}
.container-fluid.mystages.racecards .racedown p {
  font-size: 12px !IMPORTANT;
}
.container-fluid.mystages.racecards p.StartTimeCards {
  font-size: 12px !important;
}
.container-fluid.mystages.racecards .todayracefav p {
  font-size: 12px !IMPORTANT;
}
.RaceDetailsName {
  display: flex;
  align-items: baseline;
}
.meetdetail h4{
  margin-left: 10px !important;
  font-size: 14px !important;
}
.container.raceCourseContent p {
  font-size: 14px !important;
}
.container.raceCourseContent li {
  font-size: 14px;
}
.COMMITTEE li {
  font-size: 17px !important;
}
.COMMITTEE ul li:last-child {
  padding-top: 60px !important;
}
.aboutData p {
  font-size: 14px !important;
}
.middlebanner p {
  font-size: 14px !important;
}
.col-sm-3.cardraces4.arabic {
  padding-left: 0;
}
.COMMITTEE {
  height: 520px !important;
}
.courseSection .cardinner {
  background: #efefef;
  height: 100%;
  margin: 0 auto;
  width: 90%;
  border-radius: 0px;
  padding: 0 5px;
}
.row .SummaryDistance.col-sm-4 p {
  font-size: 14px !important;
}
.racecardcss1.classtyperaces1 p {
  font-size: 13px !important;
}
.itemraces_center {
  flex: 0 0 40%;
}
.Copyrights p {
  font-size: 14px !important;
}
.copyrightinner {
font-size: 14px !important;
}
.DashboardCard {
  display: flex;
  gap: 20px !important;
  flex-wrap: wrap;
  margin: 1rem;
}
.GenerateReports {
  width: 32% !important;
}
.row.topbar .col-sm-4 {
  align-items: center;
}
.cta {
  padding-right: 0 !important;
}
.authhover>ul {
  width: 100% !important;
  margin: 0;
  padding: 0;
}
.slideimg1 {
  width: 100%;
  height: 455px !important;
}
/* .carousel-inner {
  height: 455px !important;
} */
.registration .col-sm-6 i {
  left: 36% !important;
}
.registration .col-sm-6.passports i {
  position: absolute;
  z-index: 9999;
  left: unset !important;
  top: 100px;
  background: #fff;
  padding: 0 5px;
  right: 2%;
  left: unset;
}
.sponsorimg {
  margin-right: 0px;
}
}
   
@media only screen and (max-width: 1490px) {
  .slideimg1 {
    width: 100%;
    height: 380px;
  }
  h6.trcklength1 {
    left: -10px;
    margin: 8px 0px 0px -15px;
    bottom: 0;
    font-weight: 800;
    text-align: center;
    color: red;
    font-size: 12px;
}
  *[dir="rtl"] .mystages td {
    padding: 13px 25px !important;
}
*[dir="rtl"] .trainerStages td {
  width: 11.5% !important;
}
  *[dir="rtl"] .menuNav p {
    font-family: 'monospace';
    font-size: 16px !important;
    font-weight: 600 !important;
}
  .react-calendar__tile {
    height: 30px !important;
}
*[dir="rtl"] .filterbtn {
  right: 80% !important;
}
.col-sm-6.contss p {
  font-size: 14px;
  line-height: 23px;
}
.profilepic>img {
  height: 2.5rem !important;
  width: 2.5rem;
  object-fit: contain;
}
.menuNav p {
  font-size: 14px !important;
}
.cardraces2 b {
  margin-left: 10px;
  color: #000 !important;
  padding-right: 10px;
  font-size: 11px;
}
.GenerateReports {
  width: 32% !important;
}
.RaceAndHorseModelDataCSS .card {
  margin: 10px 0px !important;
}
.inner_itemraces {
  gap: 15px !important;
}
  .aboutpageheader {
    margin-top: 0px !important;
}
  .react-calendar, .react-calendar *, .react-calendar ::before, .react-calendar ::after {
    font-size: 14px !important;
}
.menus a {
  padding: 0 25px !important;
}
.col-sm-10.menus .dropdown {
  padding: 0 25px !important;
}
  body{
    overflow-x: hidden !important;
  }
  html{
    overflow-x: hidden !important;
  }
  .cardraces4.arabic p {
    padding-left: 3px;
}

p.updownicon {
  font-size: 8px;
}

*[dir="rtl"]  .horsecenterdata {
  padding-right: 15px;
}
  *[dir="rtl"] .Competition_Matches {
    font-family: 'Noto Sans Arabic', sans-serif;
    font-size: 13px;
}
  *[dir="rtl"] .filterbtn {
    font-size: 14px !important;
  }
  /* button#uncontrolled-tab-example-tab-ante{
    height: 55px !important;
  } */
  p.adstitlepos {
    position: relative !important;
    left: 0 !IMPORTANT;
    top: 0 !IMPORTANT;
    color: #fff;
}
  ::placeholder{
    font-size: 10px !important;
  }
  .match li button {
    font-size: 15px !important;
}
  .hometop {
    gap: 5px;
}
.menuNav .dropdown-content p {
  text-align: left !important;
}
.queryheader {
  display: flex;
  width: 98% !important;
}

  button.slick-arrow.slick-prev {
    width: 25px;
    height: 124px;
    background: #d9d9d9;
    border-radius: 0px;
    margin-left: 12px;
  }
  .querytoolpagination {
    bottom: -32%;
    left: 50%;
  }
  .trainerStages td {
    width: 110px !important;
  }

  *[dir="rtl"] .distance img {
    width: 140px;
    height: 70px;
    margin-left: 15px;
  }

  button.slick-arrow.slick-prev.slick-disabled {
    margin-left: 6px;
  }

  .icon11 {
    margin-top: 10px !important;
  }

  button#uncontrolled-tab-example-tab-home {
    padding: 16px 27px;
}

  button#uncontrolled-tab-example-tab-ante {
    padding: 10px 18px;
  }

  button#uncontrolled-tab-example-tab-contact {
    padding: 16px 42px;
    height: 55px;
  }

  *[dir="rtl"] button#uncontrolled-tab-example-tab-home {
    width: 90px;
    font-size: 12px !important;
  }

  *[dir="rtl"] button#uncontrolled-tab-example-tab-ante {
    width: 105px;
    font-size: 12px !important;
  }

  *[dir="rtl"] button#uncontrolled-tab-example-tab-contact {
    width: 75px;
  }

  .statsTable {
    width: 92%;
  }

  .atozParent th {
    font-size: 14px;
  }

  .atozParent td {
    font-size: 12px;
  }

  .atozParent h1 {
    font-size: 24px;
    padding: 15px 10px 5px 15px;
  }

  .querypage {
    width: 100%;
  }

  .raceCarParent1.atozParent {
    /* margin-top: 25px; */
    border-radius: 0px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #ededed;
    padding: 30px;
  }

  .RaceCardSection {
    margin-top: 2px;
  }

  .orangeclass {
    width: 150px !important;
    font-size: 15px !important;
    margin: 0px 10px !important;
  }

  .sponsor {
    height: 330px !important;
  }

  .searchbox {
    display: flex;
    margin-left: 15px;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* height: 385px; */
}
  .sponsorimg {
    height: 380px !important;
  }
  .row.desktopheader {
    width: 100%;
    align-items: center;
}
  /* .RaceCardSlider {
    height: 130px !important;
  } */

  .itemraces > p {
    margin: 15px 10px 15px 16px;
  }
  
  span.mybor {
    width: 56%;
    bottom: 11%;
}

.topbar .notification {
  padding-right: 0px;
  margin-top: 10px;
}


.carousel-control-next {
  right: 0;
  margin-right: 0px;
}

.searchbox {
  display: flex;
  align-items: baseline;
}
.cardracesjockeyleft > p {
  font-size: 12px;
  line-height: 20px !important;
}
  .CompetitionHeader h2 {
    font-size: 21px !important;
  }

  .accordion-button {
    padding:15px 10px !important;
  }

  .AccordionHeader p {
    font-size: 14px !important;
  }

  .bannerText h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .cardracesjockeyleft p {
    margin-bottom: 5px !IMPORTANT;
}
.col-sm-3.cardraces4.arabic {
  padding-left: 0;
}
  /* .adstitlepos {
    top: 5%;
    left: 8%;
    line-height: 40px !important;
  } */

  .customers1 th {
    font-size: 14px;
  }

  .customers1 th {
    border: none;
    padding: 18px 8px;
  }

  .customers13 td {
    padding: 18px 15px;
  }

  .playerPredictTable p {
    font-size: 14px !important;
    margin-bottom: 3px;
  }

  .chartheader {
    font-weight: 600;
    font-size: 16px;
  }

  .MatchDataPredict h3 {
    font-size: 18px;
    margin: 20px 0 0 18px;
  }

  .PredictionFlex :nth-child(1) {
    font-weight: 700;
    font-size: 18px;
    margin: 20px 0px 0px 20px;
  }

  .PredictionFlex :nth-child(2) {
    margin: 20px 20px 0 20px;
  }

  .innerCardBlogs {
    height: auto !important;
  }

  .col-lg-3.col-md-6.mobiledata1 {
    padding-left: 90px !important;
  }

  .mtfooter {
    margin-top: 45px;
    font-size: 18px;
    font-weight: 700;
  }

  p.footertext1 {
    padding-right: 0;
    font-size: 14px !important;
    line-height: 1.5;
  }

  .LinkStyle > li > p {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .text-muted > li > p {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
  }

  footer li b {
    font-size: 18px !important;
  }

  .formclass1 > input {
    height: 40px;
  }

  .RaceCardDetail {
    width: 100%;
  }

  .aab {
    /* flex: 1; */
    width: 31.2%;
  }

  .itemraces_right .distance {
    width: 125px;
    height: 70px;
  }

  .itemraces_right .distance {
    margin-right: -10px;
  }

  button.ShowPreviousHistory {
    width: 25%;
    margin: auto;
    display: block;
  }

  .RaceDetailCard .BodyNew {
    border-radius: 0px;
    overflow-x: scroll;
  }

  /* p.adstitlepos {
    padding: 50px 10px;
  } */

  .Trophydata span {
    margin-right: 6px;
    font-weight: 700;
    font-size: 14px;
  }

  .Trophydata_P > h6 {
    font-size: 12px;
  }

  .cardraces2 div:nth-child(3) p {
    font-size: 10px !important;
    padding: 2px 0;
    font-weight: 600 !important;
  }

  .cardracesjockeyleft > p {
    font-size: 12px;
    line-height: 5px;
  }

  .cardracesjockeycards > ul li {
    margin: 2px;
  }

  .cardraces2 p {
    margin-right: 0px;
    font-size: 13px;
    line-height: 6px;
  }
  .ownernamestyle1 {
    line-height: 8px !important;
  }
  span.racecardcolor1 {
    left: 4%;
  }
  .cardracesAccordion {
    gap: 2px !important;
  }

  .cardraces1box {
    width: 70px;
    height: 90px;
    padding: 10px;
  }

  span.cardraces1box h3 {
    font-size: 21px;
    text-align: center;
  }

  .cardracesjockeycards > ul {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
  }

  .cardracesjockeyleft p {
    margin-bottom: 13px !important;
  }

  .cardracesjockeyimg {
    width: 65px;
    height: 60px;
    margin-left: 15px;
  }

  .username {
    font-size: 11px;
    line-height: 18px;
    font-weight: 600;
  }

  .pointuser {
    font-size: 12px !important;
    line-height: 14px;
  }

  .filterbtn {
    margin-top: -62px;
    font-size: 14px;
  }

  .querytoolpagination {
    bottom: 0 !important;
    position: inherit;
  }

  .card-header {
    padding: 10px 15px;
  }
  .Addingtonname {
    font-size: 14px !important;
}
.racetime1 p {
  color: #19469D;
  font-size: 18px;
}
.Addington {
  position: relative;
  left: -8%;
}
.dropdown {
   left: -10px;
}
.menuNav p {
  font-size: 14px !important;
  text-align: center !IMPORTANT;
}
.menuNav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  justify-content: flex-start !important;
  width: 95%;
  align-items: center;
  margin: auto;
  display: inline-flex !IMPORTANT;
}
.menuNav .logoclass {
  margin-top: 10px !important;
  width: 70% !important;
  height: auto !important;
}
.uaecareer > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
}
.card .cardraces2 span {
  font-size: 16px !important;
}
.stages .statsTable {
  width: 89% !important;
}
.trainerStages .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0px !important;
  padding: 10px 25px !IMPORTANT;
  text-align: center;
}
.Statisticsbtn.Statisticsbtn1 .dropdown {
  left: 0 !important;
}
.stages.tens th {
  text-align: left;
  padding-left: 60px !important;
  width: 257px !important;
}
svg.chosecalender {
  height: 20px;
  color: #fff;
  margin-top: 10px;
}
.stages .mycardclass1 td.rb img {
  width: 20%;
  object-fit: cover;
}
.trainerStages .accordion-button:not(.collapsed) {
  border-radius: 0px !important;
  background: #fff !important;
  border: 1px solid #fff !important;
  padding: 10px 15px !important;
  padding-left: 20px !IMPORTANT;
}
.itemraces>p {
  font-size: 12px;
  margin: 20px 10px 30px 16px;
}
.registration img {
  width: 60%;
}
.menuNav {
  justify-content: end !IMPORTANT;
}
.menuNav .dropdown {
  left: 0 !important;
}

.header {
  height: auto !important;
}


.searchbox>input {
  margin-top: 25px;
  margin-top: 0px;
  height: 40px;

}

.newsflex {
  padding: 15px 20px;
}
.userprofile {
  height: 45px;
}

.profilepic>img {
  height: 3rem;
}


.notification .badge {
  top: -8px;
  padding: 5px 5px;

}

.graphParent {
  gap: 0px;
}
span.mybor {
  width: 93% !IMPORTANT;
  margin-top: -4.5%;
  margin-bottom: 3%;
}
.Competition_Matches p.tooltipclass {
  width: 70%;
}
.tab-content .accordion-body {
  background: #fff !important;
  border-radius: 0px !important;
  margin-bottom: 1px;
  padding: 15px 10px;
}
.HomeRightSection .accordion-body {
  padding: 15px 10px;
}
.row .SummaryDistance.col-sm-4 {
  width: 32% !important;
  margin: 10px 4px;
  padding: 0 !IMPORTANT;
}
.row .SummaryDistance.col-sm-4 p {
  font-size: 14px !important;
}
.racecardcss1.classtyperaces1 p {
  font-size: 13px !important;
}
.itemraces_center {
  flex: 0 0 40%;
}
.Copyrights p {
  font-size: 13px !important;
}
.copyrightinner {
font-size: 13px !important;
}
.DashboardCard {
  display: flex;
  gap: 20px !important;
  flex-wrap: wrap;
  margin: 1rem;
}
.slideimg1 {
  width: 100%;
  height: 455px !important;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
}

@media only screen and (max-width: 1370px) {
  .itemraces_right .distance {
    width: 115px;
    height: 70px;
    margin-left: -15px;
  }
  h6.trcklength1 {
    left: -10px;
    margin: 8px 0px 0px -15px;
    bottom: 0;
    font-weight: 800;
    text-align: center;
    color: red;
    font-size: 12px;
}
  *[dir="rtl"] .mystages td {
    padding: 13px 25px !important;
}
*[dir="rtl"] .trainerStages td {
  width: 9.5% !important;
}
  .tracker {
    margin-top: 5px;
}

*[dir="rtl"] .menuNav p {
  font-family: 'monospace';
  font-size: 16px !important;
  font-weight: 600 !important;
}
.top1 {
  width: 162px;
}
*[dir="rtl"] .filterbtn {
  right: 80% !important;
}
.GenerateReports {
  width: 32% !important;
}
.registration img {
  width: 60%;
  padding-top: 40px;
}
.profilepic>img {
  height: 2.5rem !important;
  width: 2.5rem;
  object-fit: contain;
}
.register ::placeholder{
  font-size: 14px !important;
}
.racepageitem h5 {
  font-size: 18px;
}
.col-sm-6.contss h3 {
  font-size: 30px;
  font-weight: 700;
}
.racecardcss1 p {
  margin-bottom: 10px;
}
.notification {
  margin-top: 5px;
}
  .AccordionHeader > p:nth-child(1) {
    font-size: 14px !important;
}

  .stages .statsTable {
    width: 87.5% !important;
}
  div#selectNow2 {
    padding: 8px 4px;
}
  .react-calendar__tile {
    height: 30px !important;
}
  .icon11 {
    margin-top: 15px !important;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
  .hometop {
    gap: 2px;
  }
  .searchbox>input{
    margin-top: 0 !important;
  }
  /* .slidercards {
        width: 0% !important;
    } */
  .querytoolpagination {
    bottom: -32%;
    left: 50%;
  }

  button.slick-arrow.slick-prev.slick-disabled {
    margin-left: 17px !important;
  }

  *[dir="rtl"] .itemraces_right .distance {
    margin-left: 30px;
  }

  *[dir="rtl"] .inner_itemraces .sponsor {
    width: 90px !important;
  }

  *[dir="rtl"] .itemraces_right {
    margin: 0px -60px 0px 0px !important;
  }

  *[dir="rtl"] .distance img {
    width: 140px;
    margin-left: 15px;
  }

  *[dir="rtl"] .itemraces_left > .race {
    width: 60px !important;
    font-size: 11px;
  }
  *[dir="rtl"] .dropdown {
    left: 0px !important;
}

  .Currentpostheader {
    width: 88%;
  }

  .orangeclass {
    width: 149px !important;
    font-size: 18px !important;
    margin: 0px 4px !important;
  }

  .slidercards {
    width: 97%;
    margin-inline: auto;
  }

  .racetime {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    height: 30px;
    overflow: hidden;
    transition: 0.4s linear all;
    font-size: 17.4333px;
    margin-left: 4px;
    color: #ff9900;
    width: 66px;
  }

  .uaecareer > p {
    font-weight: 400;
  }
  .Copyrights p {
    font-size: 13px !important;
}
.copyrightinner {
  font-size: 13px !important;
}
  .forget p {
    color: #fff;
    margin: 0 !important;
    text-align: right !important;
    font-size: 14px !IMPORTANT;
}
  .slideimg1 {
    width: 100%;
    height: 470px !important;
}
.courseSection .cardinner {
  background: #efefef;
  height: 100%;
  margin: 0 auto;
  width: 95%;
  border-radius: 0px;
}
.inner_itemraces .sponsor {
  height: 60px !important;
  flex: 1 1;
  margin-top: 19px !important;
  width: 100%;
  object-fit: cover;
  margin-left: 5px !important;
}
  .icon11 {
    margin-top: 27px;
  }

  .sponsorimg {
    height: 342px !important;
}

  .uaecareer {
    display: flex;
    gap: 12px;
    height: 11px;
  }

  .pmclass {
    gap: 12px;
  }

  .trainerbreader_section {
    margin-bottom: 5px;
  }

  .Currentpostheader img {
    height: 100%;
    object-fit: scale-down;
  }

  .Addingtontimer {
    font-size: 15.4118px;
  }

  .newabc .sponsor {
    height: 60px !important;
    width: 120px;
    object-fit: contain;
  }

  .itemraces_left > .race {
    height: 65px !important;
    width: 120px !important;
    margin-left: 0px;
    margin: 15px 0px 0px 0px;    
}
.cardracesjockeycards > ul li:last-child{
  width: 55px !important;
}

.filterbtn {
  margin-top: -42px;
  font-size: 14px;
}
  .statsTable {
    width: 100%;
  }
  .cardracesjockeycards > ul{
padding-left: 0 !important;
  }
  .logoclass {
    margin-top: 18px;
    width: 80%;
  }

  button.ShowPreviousHistory {
    width: 27%;
    margin: auto;
    display: block;
    margin-top: 10px;
  }

  .cardracesjockeyleft > p {
    font-size: 12px;
    line-height: 10px;
    margin: 0 !important;
  }

  tbody.querytablebody > tr {
    margin: 5px !important;
    font-size: 13px;
    line-height: 42px;
  }

  .trainerStages td {
    font-size: 13px;
  }

  .statsTable {
    width: 92%;
    margin-top: 10px;
  }

  button.eventdata1 {
    /* padding: 10px; */
    height: 32px;
    width: 27px;
    /* position: absolute; */
    /* top: 92px; */
    margin-left: 0px;
    /* margin-top: 3px; */
    opacity: 0.7;
    padding: 14px 0px;
}

.react-calendar__tile {
  padding: 4px 1.6667px;
  line-height: 9px;
}
  .cardraces2 p {
    margin-right: 10px;
    font-size: 11px;
    line-height: 8px;
  }

  h6.liveResult1 {
    top: 40px;
    right: 200px;
    line-height: 5px;
    width: 160px;
}

p.cardnationality {
  padding: 1px 10px !IMPORTANT;
}

  .nonrunner {
    height: 75%;
  }

  .cardraces1 > img {
    width: 60px;
    height: 60px;
    margin-top: 15px;
    object-fit: scale-down;
  }

  .cardraces1box {
    width: 70px;
    height: 90px;
    padding: 10px;
  }

  span.cardraces1box h3 {
    font-size: 21px;
    text-align: center;
  }

  .cardracesjockeycards > ul {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
  }

  .cardracesjockeyleft p {
    margin-bottom: 13px !important;
  }

  .cardracesjockeyimg {
    width: 65px;
    height: 60px;
    margin-left: 15px;
  }

  .cta {
    display: flex;
    padding-right: 5px !important;
  }
  .buttonSubscribe {
    margin: 0px 10px !important;
}
  .menuNav p {
    font-size: 14px;
  }

  .customers1 th {
    font-size: 11px;
  }

  .customers13 td {
    font-size: 11px;
    padding-top: 12px !important;
    padding-bottom: 0px !important;
    font-weight: 500;
  }

  span.racecardcolor1 {
    left: 5%;
  }

  h2.liveResult {
    font-size: 18px;
  }

  .card-header {
    padding: 10px 10px !important;
  }

  .showfilterclass > p {
    font-size: 10px !important;
  }

  .atozParent th {
    font-size: 14px;
    padding: 8px 10px;
  }

  .atozParent td img {
    width: 50px;
    height: 40px;
  }

  .posttabs p {
    font-size: 14px !important;
  }

  .container.mystages {
    margin-top: 20px;
  }

  .GenerateReport {
    margin: 25px 19px;
  }

  .querytoolpagination {
    position: inherit;
  }

  .Competition_Matches {
    font-size: 9px;
}

  .Competition_Matches.inner1 {
    padding: 8px;
  }

  .DashboardCard {
    gap: 15px;
    margin: 1rem;
  }

  .GenerateReports {
    width: 340.35px;
  }

  .userprofile {
    gap: 3px;
    padding: 13px;
  }

  .headerCard2 {
    padding: 9px 5px 5px 5px;
    text-align: right;
    font-size: 13px;
  }

  .header {
    height: 80px !important;
    width: 100% !important;
    margin-right: 40px;
  }

  .logoclass {
    margin-top: 15px;
    width: 70%;
  }

  .menuNav {
    margin: unset !important;
    padding-top: 0px;
  }

  .userprofile {
    height: 80px;
    padding: 12px;
  }

  .newsflex {
    padding: 20px 30px;
  }

  .rightlayoutinner {
    padding: 0px 0px;
    padding-top: 0px !important;
    padding-bottom: 4px !important;
  }

  .rightlayoutinner {
    padding: 0px 0px;
    padding-top: 0px !important;
    padding-bottom: 10px !important;
    margin-top: 4px;
  }

  .RaceCardSection {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.menuNav .logoclass {
  margin-top: 10px !important;
  width: 80% !important;
  height: auto !important;
}
  /* .RaceCardSlider {
    height: 130px !important;
  } */
  .singleracecard {
    width: 198px !important;
    height: 124px;
}
  .middlesection {
    margin-top: 0.8rem !important;
}
  .cardracesAccordion {
    display: flex;
    gap: 8px;
  }

  .AddingtonStyle {
    margin-top: -28px;
  }

  .bannerHome {
    height: 300px !important;
  }

  .bannerText {
    padding: 30px !important;
    padding-top: 120px !important;
  }

  .bannerCard > img {
    margin: 1rem;
  }

  .resultCard {
    height: 300px !important;
    margin-top: 0;
  }

  .bannerText h1 {
    line-height: 30px;
  }

  span.itemraces_right p {
    z-index: 999;
  }

  .newabc .sponsor {
    width: 100% !important;
  }

  .itemraces_center {
    flex: 0 0 50%;
  }

  button.slick-arrow.slick-prev.slick-disabled {
    width: 25px;
    margin-left: 12px;
  }
  button.slick-arrow.slick-prev {
    width: 25px;
    height: 124px;
    background: #d9d9d9;
    border-radius: 0px;
    margin-left: 12px;
  }
  .Competition_Matches p {
    font-size: 12px;
}
.tens .accordion-button:not(.collapsed) {
  border-radius: 0px !important;
  background: #fff !important;
  padding: 10px 25px !IMPORTANT;
  border: 1px solid #fff !important;
  padding: 10px 25px !IMPORTANT;
}
.stages .mycardclass1 th {
  text-align: left;
  padding-left: 55px !important;
  width: 235px !important;
}
.stages th {
  text-align: left;
  padding-left: 60px !important;
  width: 257px !important;
}
.stages .statsTable {
  width: 90% !important;
}
.stages table.statsTable th:nth-last-child(2) {
  text-align: center !important;
  padding-left: 38px !important;
  width: 440px !important;
}
.raceCarParent.stages.stages123.tens .stages table.statsTable th:nth-last-child(2) {
  text-align: center !important;
  padding-left: 55px !important;
  width: 440px !important;
}
.raceCarParent.stages.stages123.tens .statsTable {
  width: 87.5% !important;
}
.stages .mycardclass1 td.rb img {
  width: 20%;
  object-fit: cover;
}
.trainerStages .accordion-button:not(.collapsed) {
  border-radius: 0px !important;
  background: #fff !important;
  border: 1px solid #fff !important;
  padding: 10px 15px !important;
  padding-left: 20px !IMPORTANT;
}

.itemraces_center div p:nth-child(2) {
  margin-left: 0px;
}
.menus a {
  padding: 0 18px !important;
}
.menus div {
  padding: 0 18px !important;
}
p.footertext1 {
  padding-right: 0;
  font-size: 12px !important;
  line-height: 1.5;
}
span.totalrunners {
  font-size: 13px !important;
}
.menuNav .dropdown {
  left: 0 !important;
}

.header {
  height: auto !important;
}


.searchbox>input {
  margin-top: 25px;
  margin-top: 0px;
  height: 40px;

}

.newsflex {
  padding: 15px 20px;
}
.userprofile {
  height: 45px;
}

.profilepic>img {
  height: 3rem;
}


.notification .badge {
  top: -8px;
  padding: 5px 5px;

}

.graphParent {
  gap: 0px;
}
.mylogo img {
  width: 80%;
}
ul.mb-4.tabdata1.nav.nav-tabs button {
  margin-right: 1px;
  font-size: 14px !IMPORTANT;
  padding: 10px 27px!important;
}
.registration .col-sm-6.passports img.PreviewImage {
  position: absolute;
  width: 50%;
  right: 2% !important;
  padding-top: 20px !important;
}
.registration .col-sm-6 img.PreviewImage {
  padding-top: 20px !important;
}
.formobilescroll1 .mycss1 td {
  width: 9.3% !important;
  padding-left: 10px !important;
  text-align: center;
}
}
   
@media only screen and (max-width: 1280px) {
  .AccordionHeader {
    justify-content: space-between;
    height: unset !important;
}  
h6.trcklength1 {
  left: -10px;
  margin: 10px 0px 0px -15px;
  bottom: 0;
  font-weight: 800;
  text-align: center;
  color: red;
  font-size: 12px;
} 
.inner_itemraces {
  gap: 8px !important;
}   
.itemraces_left {
  display: block !important;
}   
.myoveflow {
  height: 65px;
}

*[dir="rtl"] span.racecardcolor1 {
    right: 12%;
}

.cardraces2 p {
  font-size: 11px !important;
  line-height: 16px;
}   
.icon11 {
  margin-top: 15px !important;
}   
.noFoundHeader {
  font-size: 1.5rem;
  margin: 40px auto;
  text-align: center;
  font-size: 18px !important;
}
.AddingtonStyle {
  margin-top: -15px !important;
}
.RaceCardSection {
  padding-top: 20px;
  padding-bottom: 20px;
}
.sponsor {
  margin-right: 0px !important;
}
.Competition_Matches p {
  font-size: 11px !important;
}
.buttonLogin {
  padding: 10px 55px;
}
button#uncontrolled-tab-example-tab-ante {
  max-width: 130px;
}
.sponsor {
  height: 330px !important;
  width: 100%;
}
.sponsorimg {
  margin-right: 0 !important;
}
button#uncontrolled-tab-example-tab-contact svg {
  color: #000 !important;
  margin-left: -6px;
}
.RaceCardDetail {
  width: 100% !important;
}
svg.chosecalender {
  margin-top: 0 !important;
}
.mtfooter1 {
  width: 90% !important;
}
.profilepic>img {
  height: 2.5rem;
  width: 2.5rem;
}
.row.hassam .col-sm-8 {
  padding-right: 10px !important;
}

.row.hassam .col-sm-4 {
  padding-right: 0px !important;
}

*[dir="rtl"] .buttonLogin {
    padding: 10px 45px !important;
}

}

@media only screen and (max-width: 1170px) {
  .itemraces_right .distance {
    width: 105px;
    height: 70px;
    margin-left: -7px;
  }

  .bannerText h1 {
    font-size: 28px;
  }

  .newabc .sponsor {
    width: 100% !important;
  }

  .bannerText {
    padding: 30px !important;
    padding-top: 120px !important;
  }

  .bannerCard > img {
    margin: 1rem;
  }

  .bannerHome {
    height: 300px !important;
  }

  .hometop {
    gap: 5px;
  }

  .resultCard {
    height: 300px !important;
  }

  .headerCard2 {
    padding: 9px 5px 5px 5px;
    text-align: right;
    font-size: 13px;
  }

  button#uncontrolled-tab-example-tab-home {
    max-width: 75px !important;
  }

  button#uncontrolled-tab-example-tab-ante {
    font-size: 13px !important;
    max-width: 95px !important;
  }

  .itemraces_left > .race {
    height: 65px !important;
  }

  .bannerText h1 {
    line-height: 30px;
  }

  .sponsorimg {
    height: 400px !important;
  }

  .statsTable {
    width: 100%;
  }

  .logoclass {
    margin-top: 18px;
    width: 80%;
  }

  button.ShowPreviousHistory {
    width: 27%;
    margin: auto;
    display: block;
  }

  span.itemraces_right p {
    z-index: 999;
  }

  .cardracesjockeyleft > p {
    font-size: 12px;
    line-height: 15px;
    margin: 0 !important;
  }

  .cardraces2 p {
    margin-right: 10px;
    font-size: 11px;
    line-height: 4px;
  }

  .nonrunner {
    height: 75%;
  }

  .cardraces1 > img {
    width: 60px;
    height: 60px;
    margin-top: 15px;
    object-fit: contain;
  }

  .cardraces1box {
    width: 70px;
    height: 90px;
    padding: 10px;
  }

  span.cardraces1box h3 {
    font-size: 21px;
    text-align: center;
  }

  .cardracesjockeycards > ul {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
  }

  .cardracesjockeyleft p {
    margin-bottom: 13px !important;
  }

  .cardracesjockeyimg {
    width: 65px;
    height: 60px;
    margin-left: 15px;
  }

  .cta {
    width: 18%;
    display: flex;
  }

  .menuNav p {
    font-size: 14px;
  }

  .customers1 th {
    font-size: 11px;
  }

  .customers13 td {
    font-size: 11px;
    padding-top: 12px !important;
    padding-bottom: 0px !important;
    font-weight: 500;
  }

  span.racecardcolor1 {
    left: 5%;
  }

  h2.liveResult {
    font-size: 18px;
  }

  .card-header {
    padding: 10px 10px !important;
  }

  .showfilterclass > p {
    font-size: 10px !important;
  }

  .atozParent th {
    font-size: 14px;
    padding: 8px 10px;
  }

  .atozParent td img {
    width: 50px;
    height: 40px;
  }

  .posttabs p {
    font-size: 12px !important;
  }

  .container.mystages {
    margin-top: 20px;
  }

  .GenerateReport {
    margin: 25px 19px;
  }

  .querytoolpagination {
    bottom: -8rem !important;
    position: inherit;
  }

  .Competition_Matches {
    padding: 4px;
    font-size: 12px;
  }

  .newabc .sponsor {
    height: 60px !important;
    width: 120px;
    object-fit: contain;
  }

  span.itemraces_left a {
    height: 65px;
  }

  .distance img {
    gap: 6.76px;
    width: 100px;
    height: 55px;
  }

  span.itemraces_right p {
    padding: 0px !important;
  }

  .itemraces_center {
    margin-left: 0px;
  }

  .itemraces_right {
    flex: 0 0 0 !important;
  }

  .itemraces > p {
    font-size: 13px;
    margin: 5px 5px 5px 15px;
  }

  .prizecard {
    padding: 5px;
    height: 160px;
  }

  .prizecardheaders {
    padding-top: 2px;
    padding-bottom: 5px;
  }

  ul.mb-3.RaceNavItem.nav.nav-tabs {
    border-radius: 0px 0px 0px 0px;
    padding-bottom: 10px;
  }

  .RaceDetailHeader .nav-tabs {
    padding: 20px 0px;
  }

  .nav-tabs {
    margin-top: 10px;
  }

  .RaceDetailHeader .nav-tabs .nav-link {
    color: #fff;
    font-size: 12px;
  }

  .filterbtn {
    margin-top: -42px;
    font-size: 12px;
  }

  .cardraces1 {
    display: flex;
    gap: 2px;
  }

  .pmclass {
    margin: 0px 0px -8px 1px;
    gap: 15px;
  }

  .uaecareer {
    gap: 5px;
  }

  .RaceDetailsName h6 {
    margin: 10px 22px;
  }

  .tooltipclass {
    max-width: 12vw;
  }

  .racedown {
    font-size: 12px;
    line-height: 14px;
    gap: 0px;
  }

  .Addingtontimer {
    font-size: 15.4118px;
    line-height: 6px;
    width: 100%;
  }

  .CompetitionHeader h2 {
    font-size: 16px !important;
  }

  button.showMore {
    padding: 10px 20px;
    font-size: 11px;
    margin-top: 4px;
  }

  .querypage {
    width: 90%;
  }

  .userprofile {
    gap: 3px;
    padding: 13px;
  }

  .rightlayoutinner {
    padding: 0px 0px;
  }

  .DashboardCard {
    gap: 15px;
    margin: 1rem;
  }

  .GenerateReports {
    width: 340.35px;
  }

  .header {
    height: 80px !important;
    width: 83% !important;
    margin-right: 40px;
  }

  .logoclass {
    margin-top: 15px;
    width: 70%;
  }

  .menuNav {
    margin: unset !important;
    margin-left: 30px !important;
    padding-top: 0px;
  }

  .userprofile {
    height: 80px;
    padding: 12px;
  }

  .newsflex {
    padding: 20px 30px;
  }

  .rightlayoutinner {
    padding: 0px 0px;
    padding-top: 0px !important;
    padding-bottom: 4px !important;
  }

  .rightlayoutinner {
    padding: 0px 0px;
    padding-top: 0px !important;
    padding-bottom: 4px !important;
  }

  .RaceCardSection {
    padding-top: 10px;
    grid-template-columns: auto 12%;
  }

  .Addingtontimer {
    font-size: 14.4118px;
  }

  .Addingtontimer {
    font-size: 14.4118px;
  }

  .Addingtonname {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 2px;
    color: #000000;
    /* margin: 1.1rem; */
  }

  .AddingtonStyle {
    margin-top: -28px;
  }
  .cardracesAccordion {
    gap: 2px;
  }
}
  @media only screen and (max-width: 1210px) {
    .mylogo img {
      margin: 0px 15px;
   }
   .menus a {
    padding: 0 15px !important;
}
.col-sm-10.menus .dropdown {
  padding: 0 15px !important;
}
.menus {
  justify-content: end;
}
ul.mb-4.tabdata1.nav.nav-tabs button {
  margin-right: 0px;
  padding: 10px 24px!important;
}
.searchbox ::placeholder{
  font-size: 12px !important;
  padding-left: 0 !important;
}
.searchbox INPUT {
  padding-left: 0px;
  font-size: 12px !important;
}
  }
@media only screen and (max-width: 1080px) {
  .itemraces_right .distance {
    width: 115px;
    height: 70px;
    margin-left: -15px;
  }

  .sponsorimg {
    height: 345px !important;
  }

  .Currentpostheader {
    margin-top: 8px;
  }

  button#uncontrolled-tab-example-tab-home {
    max-width: 75px !important;
  }

  button#uncontrolled-tab-example-tab-ante {
    font-size: 13px !important;
    max-width: 80px !important;
  }

  button#uncontrolled-tab-example-tab-contact {
    width: 55px;
  }

  .statsTable {
    width: 88%;
    margin: 10px 0px -10px 0px;
  }

  .logoclass {
    margin-top: 18px;
    width: 160px;
  }

  .bannerText h1 {
    font-size: 28px;
  }

  .itemraces_left > .race {
    height: 55px !important;
    font-size: 12px !important;
    width: 95px;
    margin-left: -6px;
  }

  .AccordionHeader p {
    font-size: 13px !important;
  }

  *[dir="rtl"] .auth1 {
    margin: 27px 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .auth1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .Addingtontimer {
    font-size: 14.4118px;
  }

  .Addingtontimer {
    font-size: 14.4118px;
  }

  button.ShowPreviousHistory {
    width: 27%;
    margin: auto;
    display: block;
  }

  .cardracesjockeyleft > p {
    font-size: 12px;
    line-height: 15px;
    margin: 0 !important;
  }

  .cardraces2 p {
    margin-right: 10px;
    font-size: 11px;
    line-height: 16px;
  }

  .nonrunner {
    height: 75%;
  }

  .cardraces1 > img {
    width: 60px;
    height: 60px;
    margin-top: 15px;
    object-fit: cover;
  }

  .cardraces1box {
    width: 70px;
    height: 90px;
    padding: 10px;
  }

  span.cardraces1box h3 {
    font-size: 21px;
    text-align: center;
  }

  .cardracesjockeycards > ul {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
  }

  .cardracesjockeyleft p {
    margin-bottom: 13px !important;
  }

  .cardracesjockeyimg {
    width: 65px;
    height: 60px;
    margin-left: 15px;
  }

  .cta {
    width: 20%;
    display: flex;
  }

  .menuNav p {
    font-size: 13px;
  }

  .Homelayout {
    margin-top: 14px;
  }

  .customers1 th {
    font-size: 11px;
  }

  .customers13 td {
    font-size: 11px;
    padding-top: 12px !important;
    padding-bottom: 0px !important;
    font-weight: 500;
  }

  span.racecardcolor1 {
    left: 5%;
  }

  .auth1 {
    margin: 20px 2px;
}

  .buttonLogin {
    padding: 0px;
  }

  h6.liveResult1 {
    top: unset;
  }

  .cta {
    width: 15%;
    display: flex;
  }

  .header {
    width: 83% !important;
    margin-right: 10px;
  }

  .hometop {
    gap: 5px;
  }

  button.showMore {
    margin-top: 10px;
  }

  .userprofile svg {
    margin-left: 26px;
  }

  .authhover > ul {
    width: 230px;
    margin-left: -50px;
  }

  .userprofile {
    height: 80px;
    padding: 0px;
  }

  button.accordion-button {
    height: 45px;
  }
}
@media only screen and (max-width: 820px) {
  .profilepic > img {
    width: 3rem;
    height: 3rem;
    border-radius: 0%;
  }
  .aboutpageheader {
    height: 150px;
    margin-top: 40px !important;
}
  *[dir="rtl"] .imgDivs {
    width: 100%;
    padding: 20% 0;
    margin-top: 45px !important;
}
  p.footertext1 {
    padding-left: 0 !important;
}
  .mtfooter1 {
    margin-top: 25px;
    width: 40%;
    padding-left: 0 !important;
}
  .carousel-inner {
    height: auto !important;
}
.accordion-body {
  margin-bottom: 20px;
}
.LeaderData {
  padding: 0 50px;
}
.Competition {
  margin-left: 20px;
}
.accordion-button {
  padding: 15px 20px !important;
  margin-bottom: 10px;
}
  .authhover > ul {
    width: 188px;
    margin-left: -94px;
}
button.react-calendar__tile {
  display: block;
  text-align: center !important;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 5px 0 !important;
}
.authhover {
  height: 255px;
  width: 135px;
  right: 0%;
  margin-top: 45px !important;
  top: 20px !important;
}

.userprofile {
  display: block;
  text-align: center;
  height: 40px;
}

  .header {
    height: 100px !important;
    width: 100% !important;
    margin-right: 0px !important;
  }

  /* .cta {
    display: none;
    new push after pull
} */


  .userprofile span:last-child {
    position: absolute;
    top: 25px;
    right: 10px;
  }

  span.react-reveal.mobiledropdown {
    padding: 15px;
    border-radius: 0px 0 0px 0px;
}

  span.react-reveal.mobiledropdown p {
    text-align: left;
    padding-bottom: 8px;
    font-size: 13px;
  }
  .dropdown.LinkStyle {
    /* float: left; */
    /* margin-left: -91%; */
    margin-top: 0px;
    width: 100%;
    padding-left: 0 !IMPORTANT;
}


  .dropdown.dropdownmenu {
    text-align: left;
    padding-left: 0 !important;
  }

  .dropdown.dropdownmenu button {
    text-align: left;
    padding-left: 0 !important;
    text-transform: uppercase;
  }

  .language {
    margin-left: 0 !important;
  }

  ul.dropdown-menu.show {
    width: 50%;
    margin-left: 25% !important;
    margin-top: -16% !important;
    background: transparent !important;
  }
  /* .dropdown-menu.show {
        display: block;
    } */

  .dropdown.dropdownmenu button:focus {
    outline: unset !important;
    border: unset !important;
  }

  button.slick-arrow.slick-prev.slick-disabled {
    top: 40%;
  }
  .PredictionFlex {
    width: 100%;
    margin-inline: auto;
    margin: 0;
  }

  .BlogCard {
    height: auto !important;
  }

  .Policies {
    justify-content: center;
  }

  .Policies a {
    padding: 0px 24px;
  }

  .Policies p {
    padding: 0px 0px;
  }
/* 
  .adstitlepos {
    top: 10%;
    left: 8%;
    line-height: 45px !important;
    font-size: 36px;
  } */

  .header1 {
    border-radius: 0px 0px 0px 0px;
    margin-right: 4px;
    height: 100px !important;
    width: 83% !important;
  }

  .searchbox {
    display: flex;
    width: 500px;
    margin: 0px 0px 0px 0px;
  }

  .rightlayoutinner.dashboardH img {
    margin-right: 10px;
  }

  .DashboardCard div {
    width: 49% !important;
}

  .DashboardCard {
    gap: 10px !important;
  }
  .Addington {
    display: flex;
    align-items: end;
}
.topbar .dropdown {
  padding-right: 0px !important;
  margin-top: 0px;
}
  .newpost {
    border-radius: 0px 0px 0px 0px;
  }
  .racetime1 p {
    color: #000;
}
  .tab-content {
    margin-top: -23px;
}
.Competitionitem {
  margin: 0px;
}
.AdsCard {
  display: none;
}

.buttonSubscribe {
  padding: 0px;
}
.mobiledata {
  margin-top: 25px;
  display: block;
}
.match {
  margin-top: 0px !important;
}

.resultCard {
  width: 100%;
  margin-left: 15px;
}
h6.liveResult1 {
  left: 24px;
  line-height: 10px;
}

  .logoclass {
    margin-top: 15px;
    height: 60px;
    margin-left: -25px;
}

  h6.liveResult1 {
    display: block;
  }
  span h6 {
    justify-content: start;
}
h6.liveResult1 {
  display: none;
}


  .tooltipclass {
    max-width: 110px;
    font-size: 12px;
  }
  .RaceCardDetail {
    width: 98%;
  }
  span.racecardcolor1{
    display: none;
  }
  .itemraces_center {
    flex: 0 0 30%;
  }

  button.slick-arrow.slick-prev.slick-disabled {
    display: none !important;
  }
  button.slick-arrow.slick-next.slick-disabled {
    display: none !important;
  }
  button.slick-arrow.slick-prev {
    display: none !important;
  }
  button.slick-arrow.slick-next {
    display: none !important;
  }

  /* .mtfooter1 {
    width: 80px;
    margin: auto;
    display: block;
    margin-top: -30px;
  } */

  .racestatuscolor {
    display: flex;
    flex-direction: row;
    justify-content: unset;
    align-items: center;
    gap: 5px;
    padding-top: 6px;
    padding-left: 30px;
}
  .colorheader {
    display: block;
  }
  .RaceDetailHeader {
    width: 100%;
    /* margin: 0 5px; */
  }
  .RaceDetailHeader {
    overflow-x: hidden;
    margin: 10px 0px !important;
    border-radius: 0px;
  }
  .rightlayoutinner {
    padding: 0px 0px;
    padding-top: 10px !important;
    padding-bottom: 4px !important;
    display: flex;
    flex-wrap: wrap-reverse;
}
  .racedisc {
    padding: 10px;
  }

  .itemraces_left > .race {
    height: 50px !important;
    font-size: 9px !important;
    width: 55px;
    margin-left: -6px;
  }
  span.itemraces_left a {
    height: 65px;
  }
  span.itemraces_center p {
    font-size: 11px;
    line-height: 12px;
  }
  .inner_itemraces {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .resultimagesRunningTime>h4 {
    line-height: 1px;
    font-size: 12px;
}
span.resultimagesRunningTime {
    font-size: 12px;
}
.resultimagebox {
    width: 50px;
    height: 50px;
}

.resultimagevideo {
    width: 50px;
    height: 50px;
    font-size: 12px;
}
span.horseposition {
    font-size: 15.1065px;
    line-height: 20px;
}
.cardraces2 p {
    font-size: 10px !important;
    line-height: 10px;
}

.RaceAndHorseModelDataCSS .card {
    margin: 1px 0;
    width: 100% !important;
}

button.showMore {
    margin-top: 20px;
    padding: 5px 5px;
    font-size: 10px;
}
button#uncontrolled-tab-example-tab-home, button#uncontrolled-tab-example-tab-ante {
    max-width: 220px !important;
    font-size: 18px !important;
}
.match li button {
  font-size: 18px !important;
  max-width: 210px !important;
  width: 220px !important;
}

ul.mb-3.RaceNavItem.nav.nav-tabs {
    overflow-x: auto !important;
    margin-left: 0 !important;
    flex-wrap: inherit;
}
.userHeader>h3 {
    /* margin-top: 30px; */
    padding: 14px 45px;
    font-size: 20px;
    margin-top: 0px !important;
}
.ButtonTracker {
    /* margin-left: 45px; */
    margin: 3px 4px 18px;
    display: flex;
}

.ButtonTracker {
    display: flex;
    justify-content: space-evenly;
}

.updateButton {
    margin-top: 0rem !important;
    width: 100px;
    height: 50px;
    transition: all ease-in-out 0.5s;
}

.SubmitButtons:hover {
    background: var(--primary-color) !important;
    height: 50px;
}
.racecardcss1 p:last-child {
  font-weight: 800;
  font-size: 13px;
  line-height: 20px;
}
*[dir="rtl"] .inner_itemraces .sponsor {
  width: 175px !important;
  object-fit: contain;
  margin-right: 15px !IMPORTANT;
}
*[dir="rtl"] span.cardraces1box p:last-child{
  float: left !important;
}
*[dir="rtl"] .horseimage button{
  margin-right: 0 !important;
}
*[dir="rtl"] .horseheader1 {
  padding: 11px;
}
.icon11 {
  margin-top: 15px !important;
}
.itemraces_right {
    flex: 0 0 !important;
    margin-top: -35px !important;
}
.inner_itemraces .sponsor {
  height: 50px !important;
  width: 50px !important;
  object-fit: cover;
  width: 175px !IMPORTANT;
}
.mobiletophead1 .dropdown {
  margin-top: 0 !important;
}
.resultimages {
    margin-top: -25px !important;
}
.itemraces_left>.race {
    width: 60px !important;
}
.PredictionCard {
    margin-top: 0px;
}
.AddingtonStyle img {
    width: 70px !important;
    height: 80px !important;
}
.sponsorimg {
  height: 52vh !important;
  margin-right: 0 !important;
  margin: 0 35px !important;
}
h2.liveResult {
  margin-bottom: 0 !important;
  margin: 28px 0 !important;
  padding-left: 30px;
}
.middlesection {
  padding-right: 0 !important;
  padding: 15px !important;
}
.Addingtonname {
    font-size: 12px;
    line-height: 2px;
    margin-bottom: 0px;
    /* margin: 1.1rem; */
}

.racetime1 {
    font-size: 16.4333px;
    line-height: 24px;
}
p.Addingtonname {
  margin-bottom: 10px !important;
}
.AddingtonStyle {
  margin-top: 0px;
  height: 120px;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slidercards {
  width: 94%;
}
.slick-track {
  display: flex !important;
}
.singleracecard {
  width: 195px !important;
  height: 110px;
}


.owner {
  padding: 0px 5px 0px 14px;
}
.clubname {
  padding: 0px px 0px 15px !important;
  font-size: 14px;
}

.racetime {
  font-size: 15.4333px;
 
}

.racedistance {
  font-size: 12px;
}
.raceNo {
  font-size: 12px;
}
.RaceCard {
  width: 100%;
  margin: 10px auto;
}
.racepageitem {
  height: px;
  margin: 8px;
  padding: 5px;
}
.cardraces2 {
  flex: 1 !important;
  padding-left: 3px;
    margin-top: 3px;
}
.racecardrow {
  margin: -15px 1px 0px 10px;
}

.cardraces1 > img {
  width: 50px;
  height: 40px;
}
.cardraces1box {
  width: 40px;
  height: 50px;
  padding: 0px;
  margin: 12px 0px;
}
span.cardraces1box h3 {
  font-size: 10px !important;
  margin: 0;
}
span.cardraces1box p {
  font-size: 7px  !important;
  padding: 0;
  margin: 0;
}

.cardraces2 p {
  font-size: 12px !important;
  margin: 0px 12px 4px 2px !important;
  line-height: 12px !important;
}
.cardracesAccordion {
  height: fit-content;
}
.uaecareer {
  margin-top: 1px !important;
width: 100%;
gap: 1px;
}
.card-header {
  padding: 8px 12px 8px 12px!important;

}
.cardracesjockeyimg {
  width: 25px;
  height: 30px;
  margin: 0;
}
.cardracesjockeyleft > p {
  font-size: 10px  !important;
  line-height: 10px;
  margin: 0 !important;
}
.RaceDetailCard {
  padding: 0px;
  /* margin: -17px; */
}
.uaecareer > p {
  font-size: 10px  !important;
  line-height: 10px;
}
.trainerbreader_section {
  margin-top: 2px !important;
}

.cardracesjockeycards > ul li {
  margin: 2px;
  margin-top: -10px; 
}
ul.mb-3.RaceNavItem.nav.nav-tabs{
  padding-bottom: 0px !important;
  padding-top:0px !important ;
}
.itemraces_center div p:nth-child(2) {
  margin-left: 40px;
  margin-top: -27px;
  width: 120px;
}

.itemraces > p {
  font-size: 11px !important;
  margin: 5px 5px 0px 0px !important;
}

.RaceAndHorseModelDataCSSFlex {
  max-height: fit-content;
  min-height: fit-content;
}
button.filterbtn {
  display: none;
}
.itemtime {
  margin: -10px 0px -16px 30px;
}

.racenameflex {
  margin-left: 12px;
  margin-top: 12px;
}

.prizecard {
  padding: 5px !important;
  height: fit-content !important;
}

.Competitiontrophy {
  margin-top: -12px !important;
}
.nonrunner{
  font-size: 30px !important;
  margin: 0px;
}
.cardracesjockeycards>ul li {
  font-size: 11px;
  width: 20px;
  height: 20px;
}
 .redclass{
  font-size: 17px;
 }

 .auth1 {
  margin: 10px -10px;
  color: #fff;

}
/* .cta {
  width: 95%;
  display: flex;
  position: fixed;
  background: var(--primary-color);
  bottom: 0;
  right: 2%;
    z-index: 999999999999999999999999;
  height: 45px;
  border-radius: 10px 10px 0px 0px;
} */
h5.text-black.mb-3.mtfooter {
  padding-top: 25%;
}

.cta a {
  font-size: 12px;
  color: #fff;
}


/* Slider CSS */
.slideimg1 {
  width: 100%;
  height: 36.9vh;
}
.slick-slide.slick-active.slick-current {
  width: 215px !important;
}
.sponsor {
  height: 360px !important;
  margin-top: 0px;
}
.sponsorimg {
  height: 200px !important;
    margin-top: 0px !IMPORTANT;
    margin-left: 0 !important;
}

.first-txt {
  padding: 70px;
  width: 250px;
  top: 0%;
  line-height: 80px;
}
.container-fluid.register.registerpage .col-sm-6 {
  width: 100%;
}
.posttabs .col-sm-2 {
  width: 25%;
  margin-bottom: 11px;
}

.header1 {
  border-radius: 0px 0px 0px 0px;
  margin-right: 0px;
  height: 100px !important;
  width: 100% !important;
}
.mtfooter {
  margin-top: -25px;
  font-size: 18px;
  font-weight: 700;
}
 /* About CSS MOBILE */

 .aboutpageheader {
  height: 150px;
}

.aboutpagesection {
  min-height: 180px;
  padding: 5px;
  min-height: auto;
  margin-top: 0px;
}
.imgDiv {
  display: none;
}
.aboutCard>img {
  margin: 15px;
  height: 160px;
}
.bannerAbout {
  width: 96% !important;
}
.middlebanner {
  height: 150px;
  margin-top: 20px;
}
.race_trainerbreader span {
  display: flex !important;
}
.pmclass > p {
  font-size: 11px;
  line-height: 4px;
}
.newabc {
  margin-top: -26px;
}

.accordion-body {
  margin-bottom: 0px;
  padding: 15px 20px !important;
}

.Currentpostheader {
  margin-top: -10px;
}

.RaceCardSection {
  padding-top: 20px;
}
.BlogCard {
  margin-left: 15px;
}
/* Card CSS */
.middlebanner section {
  left: 0;
  margin: auto !important;
  width: 90.7% !important;
  padding: 5px 2px;
  height:230px;

}
.Summarydata p {
  font-size: 10px;
  line-height: 15px;
  padding: 0px 0px;
  margin: 0 5px;
}

.Summarydata {
  display: flex;
  padding: 5px;
}

.SummaryDistance>p {
  font-size: 15.6px;
  line-height: 24px;
  margin: 8px 12px 6px 12px;
}
.SummaryDistance {
  width: 180px;
  height: 210px;
  margin-top: 10px;
  overflow: auto;
}
.customers td, .customers th {
  padding: 5px 10px;
}


.RaceCardSlider {
  height: 120px !important;
  width: 100%;
}
.carousel-control-next {
  margin-right: 0%;
}
p.cardnationality {
  padding: 0px 0px 0px 14px;
  margin: 0px 0px 10px 0px;
}

.orangeclass {
  font-size: 15px !important;
  margin: 0px 4px !important;
}
button.mobilec{
  margin-top: 9px !important;
}

.authhover > ul {
  width: 187px;
  margin-left: 0px;
}

.authhover>ul>li:last-child {
  border-radius: 0px 0px 0px 0px !important;
}

.dropdown-content {
  top: 35px;
}
.raceCarParent1.atozParent {
  padding: 0px;
}
.raceCardTable {
  overflow-x: scroll;
}
.container.mystages {
  margin-top: 10px;
  max-width: 100% !important;
}
table.tableHeaderCard {
  width: 100%;
  overflow-x: scroll;
}
.atozParent th {
  font-size: 11px;
  padding: 10px 0 !IMPORTANT;
  text-align: center;
}
.css-13cymwt-control {
  height: 43px;
  width: 160px;
  font-size: 11px;
  margin: 0px 10px;
}

.Homelayout {
  margin-top: 5px;
}
.mobiletophead1 {
  display: flex;
  justify-content: space-between;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 20px;
  align-items: center;
}
.dropdown {
  margin-top: 15px;
}
.rightlayout {
  display: none;
}

.exnews.newsD {
  display: block !important;
}

.searchchild {
  display: block;
  width: 197%;
  left: -12%;
  top: 65px;
}
.searchchild {
  left: -12% !important;
  width: 197% !important;
  margin-top: -12px;
}
.col-sm.abbc {
  width: 85px;
}
.row.searchabc {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
}

.searchhorsedata1 {
  font-size: 12px;
}
p.searchname {
  font-size: 10px;
}

*[dir="rtl"] .AddingtonStyle {
  float: left ;
}
p.mobilec {
  font-size: 11px;
}

.modal-body {
  width: 100%;
  margin: 0;
  padding: 0;
}
.horseshirt {
  margin: 12px 0px;
}
.horseshirtimage {
  width: 40px;
  margin: 0px 6px;
}
.horsecenterdata {
  flex: 3 1;
  margin-left: 2px;
  line-height: 11px;
}

.horsecenterdata > span > p {
  font-size: 10px !important;
  padding: 0px;
  margin: 4px 10px;
}

.horseimage {
  width: 57px;
  height: 40px;
  margin: 2px 10px;
}
.horseimage button {
  width: 70px !important;
  margin-left: -19px !important;
}

.imgDivs {
  width: 100%;
}
.imgDivs p {
  font-size: 13px;
  padding: 0 30px;
}

.aboutpagesection.bottom11 {
  margin-top: 100px;
}

.bannerAbout {
  height: min(20rem, 40rem);
}




.Statisticsbtn.Statisticsbtn1 {
  width:700px !important;
  overflow-x: scroll;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.modal-content {
  width: 135% !important;
  margin-left: 0% !important;
  overflow-x: scroll !important;
}
.trackerhead>h5 {
  display: none;

}


.raceCarParent th {
  padding: 2px 14px !important;
}

.stages th {
  font-size: 13px;
}
button.untrackdata {
  font-size: 14px;
}
.notificationmenu.dropdown-menu.show {
  margin-left: -40px;
  margin-top: 1px;
}
.leftlayout {
  margin-top: -8px;
}

.CompetitionHorse {
  width: 340px;
  height: 55px;
  justify-content: flex-start;
}
#castselect {
  width: 120px;
  margin: 4px;
}

.CompetitionModal {
  width: 100%;
}
.castmodal12.modal-body {
  width: 100%;
}

.popupheader {
  width: 100%;
}
.popupheader11{
  width: 100%;
}

.CompetitionBtn>button {
  margin: 0px;
  margin-right: 95px;
  width: 125px;
}

.ForgetPassword p {
  padding: 8px;
  font-size: 12px;
}
.ForgetEmailbody.modal-body {
  width: 100%;
}
.raceCarParent1 {
  min-height: 65vh;

}
.GenerateReport {
  margin: 20px 25px;
  padding: 10px;
  font-size: 12px;
}

p.resetrool {
  font-size: 12px;
  width: 30%;
}
div#selectNow {
  /* width: 113px; */
  padding: 0px;
  margin: 3px 15px;
  width: 150px;
  font-size: 12px;
}

.querypage {
  width: 100%;
}

.queryheader {
  margin-top: 15px;
  gap: 2px;
}
.rightquery {
  overflow-y: scroll;
}

.querytable {
  width: 750px;
}
svg.querypageicon {
  width: 50px;
  height: 50px;
  margin: 2px 10px;
}
.queryheader {
  display: block;
}
.querypageicon{
display: block;
}

.stages .raceCarParent.stages {
  padding: 0px;
  width: 800px;
  overflow-y: scroll;
}
div#selectNow2 {
  padding: 0px;
  margin: 0px 0px;

}
.css-13cymwt-control:hover {
  border-color: hsl(0, 0%, 70%);
  width: 160px;
}
.stages .accordion {
  margin: 8px 0 !important;
  /* width: 700px; */
}
.posttabs .row {
  justify-content: center !important;
}
.trainerStages td {
  font-size: 11px;
}
.statsTable {
  margin-left: 28px !important;
}
.statsTable {
  /* width: 620px; */
  margin: 7px 0px -10px 0px;
}
.raceCarParent .customers td {
  padding: 0 25px !IMPORTANT;
  font-size: 10px;
}
.stages h1 {
  margin-left: 0;
  font-size: 18px;
  padding: 12px 0px 0px 5px;
}


button#controlled-tab-example-tab-Horseshome {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 39px;
  width: 65px !important;
}


button#controlled-tab-example-tab-Jockeycontact1 {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 39px;
  width: 65px !important;


}


button#controlled-tab-example-tab-Ownercontact {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 39px;
  width: 65px !important;


}


button#controlled-tab-example-tab-Trainerprofile {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 39px;
  width: 65px !important;


}
span.spanForm {
  display: none;
}
.Profileimage {
  margin: 10px 0px -100px 25px;
}


svg.crossIcon {
  right: 2%;
}
.accordiontext12.accordion {
  width: 100%;
}
.resultss .col-sm-4 {
  width: 100%;
  margin-top: 8px !important;
}

/* .mylist.resultss {
  margin-top: 70px;
} */

.justsponser .aboutpagesection {
  min-height: 200px;
  margin-top: 30px;
}
.mycardclass1 {
    width: 100%;
}
.container.mystages.trainerStages {
  margin-top: 25px;
  overflow-x: scroll;
}
.raceCarParent.stages.racecard1 {
  width: 100%;
  margin-top: -30px;
}
.mylist h2 {
  font-size: 13px;
}
.stages p {
  font-size: 12px;
  line-height: 9px;
}
.headerCard1 {
  display: flex;
  width: auto;
}
.accordionHeader.row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: -14px;
}

.headerCard2 {
  padding: 9px 5px 5px 5px;
  text-align: right;
  font-size: 13px;
  width: 38%;
}
.stages .headerCard3.col-sm-1 {
  text-align: center;
  width: 68px;
}
.meetdetail p span {
  padding: 0;
}
.meetdetail p{
  margin-bottom: 10px ;
}
button.accordion-button {
  height: 65px;
  border: unset !important;
}
.dam {
  width: 129px;
  height: 160px;
}
.sire {
  width: 129px;
  height: 160px;
}
.gsire {
  width: 130px;
  height: 80px;
}

.ggsire {
  width: 129px;

}
.gdam{
  width: 129px;
}
.ggdam{
  width: 129px;
}

.Pedigree {
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  width: 100%;
  overflow-x: scroll;
}
.horsehistorylist {
  width: 100%;
  height: 35px;
}

.horsebodyitem {
  height: 45px;
  padding: 7px;
}
.horseheader1 {
  padding: 1px;
  width: 100%;
  overflow-x: scroll;
}

.horsecenterdata > span > p {
  font-size: 12px !important;
  padding: 0px;
  margin: 3px 2px;
  width: 92px;
}
.horsecenterdata.jockeyname1 {
  line-height: 15px;
  flex: 1;
}
.ownerSection>p>p {
  margin-left: 6px;
  font-size: 9px !important;
}
.modal-lg, .modal-xl {
  --bs-modal-width: 700px;
}
.modal-content {
  /* width: 135% !important; */
  /* margin-left: -17%; */
  min-height: 98vh !important;
  flex: 0 0 700px;
  max-width: 700px;
}
.modal-body .horseshirtimage {
    width: 70px !important;
    margin: 0px 6px;
}
.horseheader1 {
  flex: 0 0 100%;
  max-width: 100%;
}
.horsecenterdata span p{
  display: block !important;
  width: 100% !important;
}
span.ownerSection {
  height: 25px !important;
  margin: 0px 8px;
}
span.ownerSection p {
  display: flex !important;
}
span.ownerSection {
  padding-top: 10px;
}
.horsedetailimage span p {
  display: flex !important;
  align-items: center;
  max-width: 500px;
  flex: 0 0 235px;
}
.itemraces_right .distance {
  width: 115px;
  height: 70px;
  margin-left: 20px;
}
.nav-tabs {
  border: none !important;
  width: 100%;
  margin-inline: auto;
  margin-top: 20px;
  color: #fff;
  padding-bottom: 0px ;
}
*[dir="rtl"]  h2.liveResult {
  margin-bottom: 0 !important;
  margin: 28px 0 !important;
  padding-left: 30px;
  padding-right: 30px;
}
*[dir="rtl"] .middlesection {
  padding-right: 20px !important;
  padding-left: 0px !important;
}
/* span.itemraces_right.formobile1 {
  display: none !important;
} */
.RaceAndHorseModelDataCSS {
  margin: 5px 15px;
}
*[dir="rtl"] .cta {
  width: 23% !important;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  padding: 0 15px !important;
  align-items: center !important;
}
.row.desktopheader .col-sm-8 {
  width: 100%;
}
.row.desktopheader .col-sm-4 {
  width: 100%;
}
.searchbox {
  display: flex;
  width: 100%;
  margin: 0px 0px 5px 20px;
}
.mobilemenudata .logoclass {
  width: 100%;
}
svg.fafabar {
  margin-right: -5%;
}
.cta {
  width: 75%;
  display: flex;
}
.cta a {
  text-align: center;
  padding: 8px 10px;
}
.row.login {
  width: 100%;
  margin: auto;
  padding-top: 8%;
  position: relative;
}
.forget p {
  color: #fff;
  margin: 0 !important;
  text-align: right !important;
  font-size: 11px !IMPORTANT;
}
.submitbtn1 div button {
  margin: 12px;
  border-radius: 0px;
  width: 70%;
}
.container-fluid.register.registerpage .registration {
    height: 100% !important;
}
.mobilehide{
  display: none !important;
}
.col-sm-9 {
  width: 100%;
}
.desktophide {
  display: block !important;
  width: 100%;
}
.bannerHome {
  display: block !important;
}
.row.hassam .col-sm-8 {
  width: 96%;
  margin: 0 10px !IMPORTANT;
}
.row.hassam  .col-sm-4 {
  width: 98%;
  margin: auto;
  margin-top: 20px;
}
.Competition {
  margin-left: 20px;
  width: 90%;
  margin: auto !important;
  background: transparent !important;
}
ul.mb-4.tabdata1.nav.nav-tabs {
  height: 70px !important;
  justify-content: center;
}
.CompetitionHeader h2 {
  font-size: 22px !important;
}
.racestatuscolor {
  margin-bottom: 10px;
}
.RaceDetailHeader .nav-tabs .nav-link {
  color: #fff;
  font-size: 18px;
  padding-bottom: 15px;
}
button.showMore {
  margin-top: 20px;
  padding: 5px 15px;
  font-size: 10px;
}
li.nav-item button {
  width: 120px !important;
}
.RaceDetailHeader .nav-tabs .nav-link {
  color: #fff;
  font-size: 16px !important;
}
.tab-content>.active {
  display: block;
  margin-top: 45px !IMPORTANT;
}
.predict {
  height: 70px;
  align-items: center;
}
.predictDetail>h6 {
  font-size: 14px !important;
 }
 .mobiledropdown {
  background: #292E77 !important;
}
p.dropbtn.dropdowntag {
  padding-left: 10px;
}
.dropdown-content {
  box-shadow: unset !important;
}
span.react-reveal.mobiledropdown .dropdown-content  p {
  text-align: left;
  padding-bottom: 8px;
  font-size: 13px;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  /* padding-left: 20px !important; */
}
.container-fluid.mystages.racecards.myresults .col-sm-4 {
  width: 100%;
}
.rightlayoutinner.abouthead.dashboardH {
  display: none;
}
.col-sm-6.mission {
  width: 46% !important;
  padding: 20px 15px !important;
  height: 320px;
}
.col-sm-6.mission p {
  font-size: 14px;
  line-height: 20px;
}
.courseSection .col-sm-3 {
  width: 100%;
}
.container.raceCourseContent .col-sm-6 {
  width: 100%;
}
.COMMITTEE {
  background: #fff;
  margin-left: 15%;
  margin-top: 10%;
  height: 600px;
  padding-top: 63px !important;
  padding: 2px 15px !important;
  width: 90% !important;
  margin-bottom: 1%;
}
}

@media only screen and (max-width: 520px) {
  .profilepic > img {
    width: 3rem;
    height: 3rem;
    border-radius: 0%;
  }
  .mobilemenudata {
    display: flex !important;
    width: 90% !important;
    margin: 0 10% !important;
    justify-content: space-between;
}
  svg.fafabar {
    margin-right: 0%;
}
  .searchbox {
    display: flex;
    width: 100%;
    margin: 0px 0px 5px 0px;
}
  .racedown p {
    margin-right: 5px;
    font-size: 10px !important;
    font-weight: 700;
}
  .authhover > ul {
    width: 188px;
    margin-left: -94px;
}
.BlogCard {
  margin-left: 5px !important;
}
.Competition {
  margin-left: 0 !important;
  margin: 0 10px !IMPORTANT;
}
.LeaderData {
  padding: 0 10px !important;
}
.authhover {
  height: 255px;
  width: 135px;
  right: 0%;
  margin-top: 45px !important;
  top: -290px !important;
}

.userprofile {
  display: flex;
  text-align: center;
  height: 0px;
  margin-top: 30px;
}

*[dir="rtl"] .userprofile {
  display: flex;
  text-align: center;
  height: 0px;
  margin-top: 0px;
}

  .header {
    height: 60px !important;
    width: 100% !important;
    margin-right: 0px !important;
  }

  /* .cta {
    display: none;
} */
div#selectNow2 {
  word-wrap: normal;
  padding: 1px !important;
  margin: 0px 0px !important;
  width: 47% !important;
  border: none;
  border-radius: 0px;
  font-size: 13px;
  color: #3f3f3f;
  margin-right: 10px !important;
}
.statsTable {
  /* width: 620px; */
  margin: 0px 0px 0px 0px;
  height: 60px;
}
  .userprofile span:last-child {
    position: absolute;
    top: 25px;
    right: 10px;
  }
  .aboutpageheader {
    margin-top: 10px;
}
  span.react-reveal.mobiledropdown {
    padding: 15px;
  }

  span.react-reveal.mobiledropdown p {
    text-align: left;
    padding-bottom: 8px;
    font-size: 13px;
  }
  .dropdown.LinkStyle {
    /* float: left; */
    margin-left: 0;
    width: 100%;
    padding-left: 10px;
}
.dropdown.LinkStyle .dropdown-content {
  top: 35px;
  width: 108% !important;
  left: -5px !important;
}
.dropdown.LinkStyle .dropdown-content a {
  margin-top: -5px !important;
}
.dropdown:hover .dropdown-content {
  width: 108% !important;
}
.pagination {
  margin-top: 30px !important;
  padding: 0 !important;
}
  .dropdown.dropdownmenu {
    text-align: left;
    padding-left: 0 !important;
  }

  .dropdown.dropdownmenu button {
    text-align: left;
    padding-left: 0 !important;
    text-transform: uppercase;
  }

  .language {
    margin-left: 0 !important;
  }

  ul.dropdown-menu.show {
    width: 50%;
    margin-left: 25% !important;
    margin-top: -16% !important;
    background: transparent !important;
  }
  /* .dropdown-menu.show {
        display: block;
    } */

  .dropdown.dropdownmenu button:focus {
    outline: unset !important;
    border: unset !important;
  }

  button.slick-arrow.slick-prev.slick-disabled {
    top: 40%;
  }

  li.nav-item button {
    width: 120px !important;
  }

  .PredictionFlex {
    width: 100%;
    margin-inline: auto;
    margin: 0;
  }

  .BlogCard {
    height: auto !important;
  }

  .Policies {
    justify-content: center;
  }

  .Policies a {
    padding: 0px 10px;
    text-align: center;
}

  .Policies p {
    padding: 0px 0px;
  }
/* 
  .adstitlepos {
    top: 10%;
    left: 8%;
    line-height: 45px !important;
    font-size: 36px;
  } */

  .header1 {
    border-radius: 0px 0px 0px 0px;
    margin-right: 4px;
    height: auto !important;
    width: 83% !important;
  }

  .searchbox {
    display: flex;
    width: 95%;
    margin: auto;
}

  .rightlayoutinner.dashboardH img {
    margin-right: 10px;
  }

  .DashboardCard div {
    width: 45% !important;
  }

  .DashboardCard {
    gap: 10px !important;
  }

  .BodyNew {
    width: 125%;
}



  .newpost {
    border-radius: 0px 0px 0px 0px;
  }

  .logoclass {
    margin-top: 15px;
    height: 60px;
    margin-left: -25px;
}

  h6.liveResult1 {
    display: block !important;
  }

  .tooltipclass {
    max-width: 110px;
    font-size: 12px;
  }
  .RaceCardDetail {
    width: 98%;
  }

  .itemraces_center {
    flex: 0 0 100%;
    margin-top: 5px;
  }

  button.slick-arrow.slick-prev.slick-disabled {
    display: none !important;
  }
  button.slick-arrow.slick-next.slick-disabled {
    display: none !important;
  }
  button.slick-arrow.slick-prev {
    display: none !important;
  }
  button.slick-arrow.slick-next {
    display: none !important;
  }

  /* .mtfooter1 {
    width: 80px;
    margin: auto;
    display: block;
    margin-top: -30px;
  } */

  .racestatuscolor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-top: 6px;
  }
  .colorheader {
    display: block;
  }
  .RaceDetailHeader {
    width: 100%;
    /* margin: 0 5px; */
  }
  .RaceDetailHeader {
    max-height: 100%;
    /* overflow-x: hidden; */
    margin: 10px 0px !important;
    border-radius: 0px;
  }
  .rightlayoutinner {
    padding: 0px 0px;
    padding-top: 10px !important;
    padding-bottom: 4px !important;
    display: flex;
    flex-wrap: wrap-reverse;
}
  .racedisc {
    padding: 10px;
  }

  .itemraces_left > .race {
    height: 50px !important;
    font-size: 9px !important;
    width: 55px;
    margin-left: 20px;
  }
  span.itemraces_left a {
    height: 65px;
  }
  span.itemraces_center p {
    font-size: 11px;
    line-height: 25px;
}
  .inner_itemraces {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .resultimagesRunningTime>h4 {
    line-height: 1px;
    font-size: 12px;
    color: red !important;
    font-weight: 600;
}
span.resultimagesRunningTime {
    font-size: 12px;
}
.resultimagebox {
  width: 40px;
  height: 45px;
}

.resultimagevideo {
  width: 40px;
  height: 40px;
  font-size: 10px;
}
span.horseposition {
  font-size: 15.1065px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
  background: #19469D;
  padding: 2px 10px;
  border-radius: 0px;
}
.cardraces2 p {
    font-size: 10px !important;
    line-height: 10px;
}

.RaceAndHorseModelDataCSS .card {
  margin: 1px 0;
  width: 100% !important;
  padding: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
button.showMore {
  margin-top: 20px;
  padding: 10px 35px;
  font-size: 14px;
}
button#uncontrolled-tab-example-tab-home {
    max-width: 100% !important;
}
button#uncontrolled-tab-example-tab-ante {
  font-size: 13px !important;
  max-width: 220px !important;
  width: 170px !IMPORTANT;
  margin-right: 0;
  border-radius: 0 !IMPORTANT;
}

ul.mb-3.RaceNavItem.nav.nav-tabs {
    overflow-x: auto !important;
    margin-left: 0 !important;
    flex-wrap: inherit;
}
.userHeader>h3 {
    /* margin-top: 30px; */
    padding: 14px 45px;
    font-size: 20px;
    margin-top: 0px !important;
}
.ButtonTracker {
    /* margin-left: 45px; */
    margin: 3px -16px 18px;
    display: flex;
}

.ButtonTracker {
    display: flex;
    justify-content: space-evenly;
}

.updateButton {
    margin-top: 0rem !important;
    width: 100px;
    height: 50px;
    transition: all ease-in-out 0.5s;
}

.SubmitButtons:hover {
    background: var(--primary-color) !important;
    height: 50px;
}
.icon11 {
    display: block;
    margin-top: 0 !important;
}
.itemraces_right {
    flex: 0 0 !important;
    margin-top: -25px !important;
    display: none;
}
.inner_itemraces .sponsor {
  /* height: 60px !important; */
  width: 90px !important;
  object-fit: contain;
}
.formobilescroll1 {
  width: 100%;
  overflow-x: scroll;
}
.resultimages {
    margin-top: -25px !important;
}
.itemraces_left>.race {
    width: 60px !important;
}
.PredictionCard {
    margin-top: 0px;
}
.AddingtonStyle img {
  width: 180px !important;
  height: 70px !important;
}
.Addingtonname {
    font-size: 12px;
    line-height: 2px;
    margin-bottom: 0px;
    /* margin: 1.1rem; */
}

.racetime1 {
    font-size: 16.4333px;
    line-height: 24px;
}

.AddingtonStyle {
  margin-top: 0px;
  height: 155px;
  float: right;
}
.slidercards {
  width: 100%;
  text-align: center;
}
.slick-track {
  display: flex !important;
}
.singleracecard {
  width: 170px !important;
  height: 110px;
}


.owner {
  padding: 0px 5px 0px 14px;
}
.clubname {
  padding: 0px px 0px 15px !important;
  font-size: 14px;
}

.racetime {
  font-size: 15.4333px;
 
}
.cardraces4 p {
  font-size: 12px !important;
}
.racedistance {
  font-size: 12px;
}
.raceNo {
  font-size: 12px;
}
.RaceCard {
  width: 98%;
  margin: 10px auto;
}
.racepageitem {
  height: px;
  margin: 8px;
  padding: 5px;
}
.cardraces2 {
  flex: inherit !important;
  padding-left: 3px;
    margin-top: 3px;
}
.racecardrow {
  margin: -10px -8px 0px 8px;
}
.cardraces1box {
  width: 85px;
  height: 80px;
  padding: 5px;
  margin: 12px 0px;
  margin: 15px auto;
  margin-right: 0;
}
span.cardraces1box h3 {
  font-size: 10px !important;
  margin: 0;
  padding: 20% 0;
}
span.cardraces1box p {
  font-size: 10px  !important;
  padding: 0;
  margin: 0;
}

.cardraces2 p {
  font-size: 12px !important;
  margin: 2px 15px 10px 0px !important;
  line-height: 12px !important;
}
.cardracesAccordion {
  height: fit-content;
}
.uaecareer {
  margin-top: 10px !important;
  width: 620px;
  gap: 15px;
  margin-bottom: 0px;
  height: 30px;
  overflow-x: scroll;
}
.myoveflow{
  overflow-x: scroll;
}

.card-header {
  padding: 3px 3px !important;

}
.cardracesjockeyimg {
  width: 55px;
  height: 55px;
  margin: 0;
}
.cardracesjockeyleft > p {
  font-size: 10px  !important;
  line-height: 10px;
  margin: 0 !important;
}
.RaceDetailCard {
  padding: 0px;
  /* margin: -17px; */
}
.uaecareer > p {
  font-size: 10px !important;
  line-height: 12px;
}
.trainerbreader_section {
  margin-top: 2px !important;
}

.cardracesjockeycards > ul li {
  margin: 2px;
  margin-top: -10px; 
}
ul.mb-3.RaceNavItem.nav.nav-tabs{
  padding-bottom: 0px !important;
  padding-top:0px !important ;
}
.itemraces_center div p:nth-child(2) {
  margin-left: -65px;
  margin-top: -30px;
  width: auto !important ;
}

.itemraces > p {
  font-size: 11px !important;
  margin: 5px 5px 0px 0px !important;
}

.RaceAndHorseModelDataCSSFlex {
  max-height: fit-content;
  min-height: fit-content;
}
button.filterbtn {
  display: none;
}
.itemtime {
  margin: -10px 0px 7px 30px;
}

.racenameflex {
  margin-left: 12px;
}

.prizecard {
  padding: 5px !important;
  height: fit-content !important;
}

.Competitiontrophy {
  margin-top: -12px !important;
}
.nonrunner{
  font-size: 30px !important;
  margin: 0px;
}
.cardracesjockeycards>ul li {
  font-size: 11px;
  width: 20px;
  height: 20px;
}
 .redclass{
  font-size: 15px;
 }

 .auth1 {
  margin: 10px -10px;
  color: #fff;

}
/* .cta {
  width: 100%;
  display: flex;
  position: fixed;
  background: var(--primary-color);
  bottom: 0;
  right: 0;
  z-index: 999999999999999999999999 !important;
  height: 45px;
  border-radius: 10px 10px 0px 0px;
} */


.cta a {
  font-size: 12px;
  color: #fff;
}


/* Slider CSS */
.slideimg1 {
  width: 100%;
  height: 22.9vh !important;
}
.slick-slide.slick-active.slick-current {
  width: 215px !important;
}
.sponsor {
  height: 230px !important;
  margin-top: 0;
  margin-bottom: 10px;
}
.sponsorimg{
  height: 230px !important;

}
.header1 {
  border-radius: 0px 0px 0px 0px;
  margin-right: 0px;
  height: 70px !important;
  width: 100% !important;
}
.mtfooter {
  margin-top: -25px;
  font-size: 18px;
  font-weight: 700;
}
 /* About CSS MOBILE */

 .aboutpageheader {
  height: 150px;
}

.aboutpagesection {
  min-height: 180px;
  padding: 5px;
  min-height: auto;
  margin-top: 0px;
}
.imgDiv {
  display: none;
}
.aboutCard>img {
  margin: 15px;
  height: 160px;
}


.headerCard1.carddisplay121.col-sm-6 {
  display: block;
}

.headerCard1.carddisplay121.col-sm-6 h4 {
  font-size: 12px;
}

.headerCard2.imgDiv.col-sm-2 {
  display: none;
}

.headerCard2.imgDiv.col-sm-1 p {
  padding-top: 8px;
}



.container-fluid.mystages.over {
  margin-top: -30px;
}

.bannerAbout {
  width: 96% !important;
}
.middlebanner {
  height: 150px;
  margin-top: 20px;
}
/* .race_trainerbreader span {
  display: block !important;
} */

.newabc {
  margin-top: -26px;
}

.accordion-body {
  margin-bottom: 0px;
  margin: 0px;
}

.Currentpostheader {
  margin-top: -10px;
}

.RaceCardSection {
  padding-top: 5px;
}

/* Card CSS */
.middlebanner section {
  left: 0;
  margin: auto !important;
  width: 90.7% !important;
  padding: 5px 2px;
  height:230px;
  top: 100%;

}
.Summarydata p {
  font-size: 10px;
  line-height: 15px;
  padding: 0px 0px;
  margin: 0 5px;
}

.Summarydata {
  display: flex;
  padding: 5px;
}

.SummaryDistance>p {
  font-size: 15.6px;
  line-height: 24px;
  margin: 8px 12px 6px 12px;
}
.SummaryDistance {
  width: 165px;
  height: 210px;
  margin-top: 10px;
  overflow: auto;
}
.customers td, .customers th {
  padding: 5px 10px;
  font-size: 11px;
}


.RaceCardSlider {
  height: 110px !important;
  width: 100%;
}

p.cardnationality {
  padding: 0px 0px 0px 14px;
  margin: 0px 0px 10px 0px;
}

.orangeclass {
  font-size: 15px !important;
  margin: 0px 4px !important;
}
button.mobilec{
  margin-top: 15px !important;
  width: 80px !important;
  height: 20px !important;
}

.authhover > ul {
  width: 187px;
  margin-left: -51px;
}

.authhover>ul>li:last-child {
  border-radius: 0px 0px 0px 0px !important;
}

.dropdown-content {
  top: 35px;
}
.raceCarParent1.atozParent {
  padding: 0px;
}
.raceCardTable {
  overflow-x: scroll;
}
.container.mystages {
  margin-top: 10px;
  max-width: 100% !important;
}
table.tableHeaderCard {
  width: 100%;
  overflow-x: scroll;
}
.atozParent th {
  font-size: 11px;
  padding: 5px 10px;
}
.css-13cymwt-control {
  height: 43px;
  width: 160px;
  font-size: 11px;
  margin: 0px 10px;
}

.Homelayout {
  margin-top: 5px;
}
.mobiletophead1 {
  display: flex;
  justify-content: space-around;
  margin-top: -10px;
  margin-bottom: 5px;
}
.dropdown {
  margin-top: 18px;
}
.rightlayout {
  display: none;
}
.notification {
  margin-top: 16px;
}
.exnews.newsD {
  display: none;
}
*[dir="rtl"] .header {
  padding-right: 0px;

}
*[dir="rtl"] .dropdown {
  margin-top: 28px;
}
*[dir="rtl"] .notification {
  margin-top: 10px;
}

.rightlayout {
  display: none;
}
.searchchild {
  display: block;
  width: 197%;
  left: -12%;
  top: 65px;
}
.searchchild {
  left: 0% !important;
  width: 95% !important;
  margin-top: -25px;
}
.col-sm.abbc {
  width: 85px;
}
.row.searchabc {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
}

.searchhorsedata1 {
  font-size: 12px;
}
p.searchname {
  font-size: 10px;
}

*[dir="rtl"] .AddingtonStyle {
  float: left ;
  margin-left: 15px;
}
p.mobilec {
  font-size: 11px;
}

.modal-body {
  width: 100%;
  margin: 0;
  padding: 0;
}
.horseshirt {
  margin: 12px 0px;
}
.horseshirtimage {
  width: 60px;
  margin: 10px 6px;
  height: 45px !important;
}
.horsecenterdata {
  flex: 0;
  margin-left: 2px;
  line-height: 11px;
  margin-top:2px;
}
.mobilesitewidth.accordion {
  width: 100% !important;
}
.horsecenterdata > span > p {
  font-size: 10px !important;
  padding: 0px;
  margin: 4px 10px;
}
.nodisplay{
  display: none;
}
.horseimage {
  width: 57px;
  height: 40px;
  margin: 2px 10px;
}
.horseimage button {
  width: 70px !important;
  margin-left: -19px !important;
}

.imgDivs {
  width: 100%;
}
.imgDivs p {
  font-size: 13px;
  padding: 0 30px;
}

.aboutpagesection.bottom11 {
  margin-top: 100px;
  margin-top: 60%;

}

.bannerAbout {
  height: min(20rem, 40rem);
}

.BodyNew.mpobile0 {
  width: 100% !important;
}

.accordionHeader.displaymobile1.row {
  justify-content: space-between;
}
.Statisticsbtn.Statisticsbtn1 {
  width: 100% !important;
  display: block;
  overflow-x: initial;
}
.modal-content {
  width: 135% !important;
  margin-left: 0% !important;
  overflow-x: scroll !important;
}
.trackerhead>h5 {
  display: none;

}


.raceCarParent th {
  padding: 2px 0 !important;
}
.stages th {
  font-size: 11px;
}


.headerCard1.carddisplay121.col-sm-8 {
  display: block;
  font-size: 11px;
}

.headerCard1.carddisplay121.col-sm-8 h4{
  font-size: 12px;
}


button.untrackdata {
  font-size: 14px;
}
.notificationmenu.dropdown-menu.show {
  margin-left: -40px;
  margin-top: 1px;
}
.leftlayout {
  margin-top: -8px;
}

.CompetitionHorse {
  width: 340px;
  height: 55px;
  justify-content: flex-start;
}
#castselect {
  width: 120px;
  margin: 4px;
}

.CompetitionModal {
  width: 100%;
}
.castmodal12.modal-body {
  width: 100%;
}

.popupheader {
  width: 100%;
}
.popupheader11{
  width: 100%;
}

.CompetitionBtn>button {
  margin: 0px;
  margin-right: 95px;
  width: 125px;
}

.ForgetPassword p {
  padding: 8px;
  font-size: 12px;
}
.ForgetEmailbody.modal-body {
  width: 100%;
}
.raceCarParent1 {
  min-height: 65vh;

}
.GenerateReport {
  margin: 20px 25px;
  padding: 10px;
  font-size: 12px;
}

p.resetrool {
  font-size: 12px;
  width: 30%;
}
div#selectNow {
  /* width: 113px; */
  padding: 0px;
  margin: 2px 7px !important;
  width: 45%;
  font-size: 12px;
}
*[dir="rtl"] .middlebanner section {
  left: -0.9%;
}

*[dir="rtl"] .aboutData {
  margin-right: 0px;
}


h5.text-white.mb-3 {
  display: none;
}

.col-lg-3.col-md-6.mobiledata1 {
    padding-left: 21px !important;
    display: block;
}
.querypage {
  width: 100%;
}

.queryheader {
  margin-top: 15px;
  gap: 2px;
}
.rightquery {
  overflow-y: scroll;
}

.querytable {
  width: 750px;
}
svg.querypageicon {
  width: 30px;
  height: 30px;
  margin: 2px 10px;
}
.queryheader {
  display: block;
}
.querypageicon{
display: block;
}

.stages .raceCarParent.stages {
  padding: 0px;
  width: 100%;
  overflow-y: scroll;
}
div#selectNow2 {
  padding: 2px;
margin: 0px 0px;

}

button.PrintOutClass {
  padding-bottom: 4px;
  padding-top: 9px;
  width: 80px;
  margin-bottom: 0px;
  margin-top: 40px !important;
  font-size: 12px;
}



.css-13cymwt-control:hover {
  border-color: hsl(0, 0%, 70%);
  width: 160px;
}
.stages .accordion {
  margin: 8px 0 !important;
  width: 725px !important;
}

.BodyNew.mobiledatascroll {
  margin: 0px;
  border: none;
}
.fafabar {
  margin-top: 15px;
  color: #fff;
  position: relative;
  width: 30px;
  height: 30px;
}

.logoclass {
  margin-top: 12px;
  height: 50px;
  object-fit: scale-down;
}

time {
  font-size: 10px;
}

.load1 {
    margin: -18px 90px;
    width: 200px;
}


.load {
  margin: -18px 90px;
  width: 200px;
}
.posttabs .row {
  justify-content: space-between;
}
.trainerStages td {
  font-size: 11px;
}
.statsTable {
  margin-left: 28px !important;
}
.statsTable {
  /* width: 620px; */
  margin: 7px 0px -10px 0px;
}
.raceCarParent .customers td {
  padding: 6px;
  font-size: 10px;
}
.stages h1 {
  margin-left: 0;
  font-size: 18px;
  padding: 12px 0px 0px 5px;
}


button#controlled-tab-example-tab-Horseshome {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 33px;
  width: 70px !important;
}


button#controlled-tab-example-tab-Jockeycontact1 {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 33px;
  width: 90px !important;


}


button#controlled-tab-example-tab-Ownercontact {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 33px;
  width: 70px !important;


}


button#controlled-tab-example-tab-Trainerprofile {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 33px;
  width: 90px !important;


}
span.spanForm {
  display: none;
}
.Profileimage {
  margin: 10px 0px -100px 25px;
}


svg.crossIcon {
  right: 2%;
}
.accordiontext12.accordion {
  width: 100%;
}
.resultss .col-sm-4 {
  width: 100%;
  margin-top: 8px !important;
}

/* .mylist.resultss {
  margin-top: 70px;
} */

.justsponser .aboutpagesection {
  min-height: 200px;
  margin-top: 30px;
}
.mycardclass1 {
    width: 100%;
}
.container.mystages.trainerStages {
  margin-top: 25px;
  overflow-x: scroll;
}
.raceCarParent.stages.racecard1 {
  width: 100% ;
  margin-top: -30px;
}
.mylist h2 {
  font-size: 13px;
}
.stages p {
  font-size: 12px;
  line-height: 14px;
}
.headerCard1 {
  display: none;
  width: auto;
}
.accordionHeader.row {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0px;
  background: transparent;
  border: navajowhite;
  margin-top: -7px;
}


.headerCard2 {
  padding: 9px 5px 5px 5px;
  text-align: right;
  font-size: 11px;
  width: 50px;
  display: flex;
}
.stages .headerCard3.col-sm-1 {
  text-align: center;
  width: 68px;
}

.dam {
  width: 129px;
  height: 160px;
}
.sire {
  width: 129px;
  height: 160px;
}
.gsire {
  width: 130px;
  height: 80px;
}

.ggsire {
  width: 129px;

}
.gdam{
  width: 129px;
}
.ggdam{
  width: 129px;
}

.Pedigree {
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  width: 100%;
  overflow-x: scroll;
}
.horsehistorylist {
  width: 100%;
  height: 35px;
}

.horsebodyitem {
  height: 45px;
  padding: 7px;
}
.horseheader1 {
  padding: 1px;
  width: 100%;
  overflow-x: scroll;
}

.horsecenterdata > span > p {
  font-size: 12px !important;
  padding: 0px;
  margin: 3px 2px;
  width: 92px;
}
.horsecenterdata.jockeyname1 {
  line-height: 10px;
  flex: 1 1;
  padding-top: 10px;
}
.ownerSection>p>p {
  margin-left: 6px;
  font-size: 9px !important;
}
*[dir="rtl"] .cta {
  width: 100%;
  margin-left: 10px;
  padding: 0 10px;
}
*[dir="rtl"] .cardracesjockeycards li {
  padding: 1px 20px !important;
  margin-left: 0 !important;
  width: auto !important;
}
*[dir="rtl"] .cardracesjockeycards ul {
  padding-right: 0;
  margin-right: 0 !important;
}

*[dir="rtl"] .nonrunner {
  margin: 0px 0px;
}
*[dir="rtl"] .Pedigree {
  margin: 8px !important;
}


*[dir="rtl"] button#controlled-tab-example-tab-Trainerprofile {
  width: 65px !important;
}

*[dir="rtl"] .racecardcss1 {
  margin-top: 20px;
}

*[dir="rtl"] .itemraces_left {
  display: flex !important;
  position: relative;
  right: 0%;
  margin-bottom: 15px;
  height: 100% !important;
}
*[dir="rtl"] .itemraces_right {
  margin: 0px 0px 0px 0px !important;
}
*[dir="rtl"] .uaecareer > p {
  font-size: 9px !important;
}

*[dir="rtl"] button.showMore {
  margin-top: 29px;
  padding: 12px 50px;
  font-size: 14px;
}

*[dir="rtl"] button#dropdown-basic {
  margin-top: -15px;
}


*[dir="rtl"] span.react-reveal.mobiledropdown p {
  text-align: right;
}

*[dir="rtl"] .dropdown.LinkStyle {
  /* float: left; */
  margin-left: 79%;
}


.tab-content {
  margin-top: 0;
}

span.notificationicon1 svg {
  width: 40px;
  height: 30px;
  margin-left: 20px;
  margin-top: 8px;
}

.nav-tabs {
  margin-left: 10px;
}
.headerCard1 .carddisplay121{
  display: block;
}
.registrationform>div {
  width: 350px;
 
}
.posttabs {
  display: flex;
  width: 100%;
}

.GenerateReports p {
  margin-top: 45px;
  font-size: 17px;
  height: 114px !important;
}
.GenerateReports{
  height: 120px !important;
}
.css-13cymwt-control {
  margin: 0 !important;
}

.predictor {
  width: 100%;
  overflow: scroll;
}
.predictdata {
  display: flex;
  position: relative;
  left: 0%;
  width: auto;
}

.first-txt{
  line-height: 30px;
}
.posttabs .col-sm-1 {
  flex: 0 0 auto;
  width: min-content;
}

.posttabs p {
  font-size: 10px !important;
}
.raceCarParent button.accordion-button {
  padding: 0;
  height: 55px;
}

button.eventdata1 {
  height: 40px;
  margin-left: 0px;
  opacity: .7;
  padding: 15px 0px;
  width: 34px;
}
ul.list-unstyled.text-muted.text-bold {
  margin-bottom: 0;
}
.mtfooter1 {
  width: 90% !important;
  display: block;
  margin: auto;
}
ul.list-unstyled.text-muted {
  margin-bottom: 0;
}
.auth1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center !important;
}
h5.text-black.mb-3.mtfooter {
  padding-top: 20px !important;
}
button.page-item {
  padding: 6px 15px;
}
button.page-item1 {
  padding: 6px 15px;

}
.form-group>span {
 
  padding-left: 0px;
}
button#dropdown-basic1 {
  margin-top: -35px;
}

.trackerhead {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
}

.mobilesitewidth.accordion {
  width: 100% !important;
  z-index: 0 !important;
  position: relative;
}
.RaceDetailCard.datamobiletop {
  margin-top: 28px !important;
}
.modal .modal-header .btn-close {
  color: white;
}
span.ownerSection {
  height: fit-content;
}
.one{
margin-left: 35px;
}

.horsecenterdata.jockeyname1 p {
  font-size: 12px !important;
}
.cta {
  z-index: 99 !important;
  width: 50%;
  display: flex;
}

.resultslider_head {
  width: 100%;
}
.resultCard {
  height: 330px !important;
  width: 93%;
  margin: 10px 10px !IMPORTANT;
}
.resultCardHeader {
  height: 310px;
}
.nocompetitionimg1 img {
  width: 100% !important;
}

.ScrollContainer {
  width: 700px;
  display: flex;
  overflow-x: auto;
  margin-bottom: 1.5rem;
  gap: 5px;
}
.graphParent {
  display: block;
  margin-top: 0px;
  gap: 2px;
  width: 100%;
  margin: auto;
}
.GraphCard {
  width: 90%;
  height: 310px;
  margin: auto !important;
  margin-left: 4% !important;
}
.PredictionCard {
  width: 100%;
  height: 310px;
  margin-top: 10px;
}
.policy {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}
.policy p {
  font-size: 15px;
  line-height: 160.02%;
}

.resultCardHeader h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 3px;
    color: #FFFFFF;
    padding: 10px 0px 0px 24px;
}

.resultslider_head{
  display: block !important;
}
.registrationform.dataverify {
  width: 100% !important;
}
*[dir="rtl"] .cta {
  width: 100% !important;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  padding: 0 15px !important;
  align-items: center !important;
}
*[dir="rtl"] .logoclass {
  margin-top: 12px;
  height: 50px;
  /* margin-right: -50px !important; */
  /* object-fit: scale-down; */
}
.otpModal input {
  width: 3rem;
  margin-right: 5px;
  height: 3rem;
  font-size: 18px;
  text-align: center;
}
.RaceAndHorseModelDataCSS {
  margin: 5px;
}
.RaceAndHorseModelDataCSS .cardracesAccordion {
  display: block;
  gap: 8px;
}
.cardraces1 > img {
  width: 85px;
  height: 85px;
  object-fit: contain;
  margin-right: 10px;
  margin-bottom: 10px;
}
.cardraces2 p span p {
  margin-left: 15px !important;
  display: block;
  margin-top: -1px !important;
}
.racepageitem h5 {
  margin-left: 0 !important;
}
.racepageitem {
  padding-left: 10px;
}
.cardracesjockeyleft {
  width: 50%;
}
.cardracesjockeyleft p {
  font-size: 14px !IMPORTANT;
  margin-bottom: 14px;
  display: block;
  line-height: 1.5;
}
.cardracesjockeyleft p:nth-child(3) {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 22px !IMPORTANT;
  color: rgba(0, 0, 0, 0.5);
}
.cardracesjockeycards li {
  width: 50px !important;
}
::-webkit-scrollbar {
  width: 0px !important;
  height: 0;
}
.myoveflow{
  overflow-x: scroll !important;
}
.myoveflow .pmclass {
  margin-top: 10px;
}
button.ShowPreviousHistory {
  width: 70%;
  margin: auto;
  display: block;
}
tbody.customers1 td {
  padding: 0px 25px !important;
}
.trainerstatsbtn1.accordion-body {
  padding: 0 5px !important;
}
*[dir="rtl"] .mobiledata .nav-item button {
  margin: 0 !important;
  width: 95px !important;
}
.Addington {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-tabs .nav-link {
  margin: 4px -10px !important;
}
button#uncontrolled-tab-example-tab-home, button#uncontrolled-tab-example-tab-ante {
  font-size: 14px !important;
}
.itemraces_left {
  display: flex;
  align-items: center;
}
.itemraces_right .distance {
  width: 110px !important;
  height: 70px;
  margin-left: 0px !important;
}
.modal-lg, .modal-xl {
  --bs-modal-width: 100% !important;
}
.modal-content {
  min-height: 98vh !important;
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
.modal-content .horseheader1 {
  padding: 1px;
  overflow-x: scroll;
  display: grid;
}
.modal-content .horseshirt {
  text-align: center;
}
.modal-body .horseshirtimage {
  width: 80px !important;
  margin: 0px 6px;
  height: 60px !important;
  object-fit: contain;
}
.modal-body  .horsecenterdata > span > p {
  font-size: 12px !important;
  padding: 0px;
  margin: 3px 2px;
  width: 92px;
  text-align: center;
}
.modal-body .horseimage {
  width: 90px !important;
  height: 70px !important;
  padding: 3px !important;
  margin: 2px 10px;
}
.modal-body .horseimage {
  display: table;
  margin: 10px auto;
}
.modal-body .horseimage button {
  width: 70px !important;
  margin-left: 20px !important;
  margin-top: 10px;
}
.modal-body .horsebodyitem {
  margin: 10px 20px;
}
.modal-body .mycardclass1 {
  width: 100%;
  margin-top: 15px;
}
.modal-body .RaceNav ul.mb-3.RaceNavItem.nav.nav-tabs {
  margin-left: 0px !important;
  margin-bottom: 40px !IMPORTANT;
}
.modal-body .horsecenterdata.jockeyname1 p {
  text-align: center;
}
.ownerTrack button {
  margin: 10px auto !important;
}
.modal-body .formobilescroll1 button.accordion-button {
  width: 1000px !important;
}
.modal-body .tab-content table.statsTable.innermodalstats th{
  padding: 0 30px !important;
}
.modal-body .BodyNew {
  width: 100% !important;
}
.modal-body .accordion-collapse.collapse.show {
  width: 1000px;
  overflow-x: scroll;
}
.modal-body .BodyNew.mpobile0 table.customers th {
  padding: 0 20px !IMPORTANT;
}
.modal-body .accordion {
  width: 1000px;
}
.horsecenterdata p {
  text-align: center;
}
.modal-body table.customers th {
  padding: 0 20px !IMPORTANT;
}
*[dir="rtl"] .nav-tabs {
  padding: 0px 0px;
  margin-bottom: 35px !important;
}
.racepageheadflex {
  display: flex;
  flex-direction: column !important;
  margin-bottom: 25px !important;
  margin-top: 20px !important;
}
*[dir="rtl"] .racepageitem p {
  font-weight: 800;
  font-size: 12px !important;
  text-transform: uppercase !important;
}
*[dir="rtl"]  .racepageheadflex {
  display: flex;
  width: 94%;
  align-items: flex-start;
  margin-inline: auto;
  justify-content: space-between;
  padding-right: 20px;
}
.racepageheadflex {
  display: flex;
  width: 94%;
  align-items: flex-start;
  margin-inline: auto;
  justify-content: space-between;
  padding-left: 20px;
}
.registrationform {
  margin-top: 25%;
}
.registrationform h5 {
  padding-top: 20px !IMPORTANT;
 }
 .register .col-sm-6{
  padding: 0 !important;
  width: 100%;
 }
 .registration {
  height: 100%;
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%);
  padding: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 9;
  padding: 10px 38px !IMPORTANT;
}
.register {
  padding: 0 !important;
  position: relative;
  top: 0;
}
.registration img {
  width: 80%;
  padding-top: 35%;
}
.submitbtn1 div button {
  margin: 12px;
  width: 100%;
  margin: 0 !important;
  margin-top: 20px !important;
  padding: 12px 20px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%);
  border: unset !important;
  margin-bottom: 20px !IMPORTANT;
}
.registrationimg {
  margin-top: 25px;
  margin-bottom: 20%;
  height: 100% !important;
}
.register h5 {
  color: #fff;
  margin-top: 20px;
  font-size: 18px;
  padding-bottom: 20px;
}
.row.login {
  width: 100%;
  margin: auto;
  padding-top: 8%;
  position: relative;
  padding-bottom: 8%;
}
.row.login .col-sm-6 {
  padding: 10px 20px !IMPORTANT;
}
.login div button {
  width: 100%;
}
.mainlogin .registration {
  padding: 10px 15px !IMPORTANT;
}
.mainlogin .showIcon:hover {
  transform: scale(1) !important;
}
.slick-slider {
  margin-left: 0px !important;
}
.Addingtonname {
  font-size: 12px !important;
}.Addington {
  position: relative;
   left: -1%;
}
.RaceCardSection {
  align-items: center !important;
}
.owner {
  font-weight: 600 !important;
  font-size: 14px !important;
}
.row.desktopheader .col-sm-4 {
  padding: 0 !important;
  margin-left: 5%;
}
.mobilehide{
  display: none !important;
}
.desktophide{
  display: block !important;
}
.topbar .dropdown {
  padding-right: 0;
  margin-top: 0px;
  margin-left: 3%;
}
.auth1 a {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
}
.searchparent {
  margin-top: 10px;
  margin-bottom: 5px;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
  right: unset !IMPORTANT;
  top: 88%;
  z-index: 99;
  bottom: unset !important;
  left: 0% !important;
  z-index: 999999;
  padding: 0 20px;
  display: flex !important;
}
.swiper {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.col-sm-2.Addingtondiv {
  background: #d2d2d2;
  padding: 25px 45px;
  margin-bottom: 22px;
  margin-right: 10px;
  width: 100%;
  margin-top: 50px;
}
.swiper-button-next {
  right: -0%;
}
.swiper-slide {
  justify-content: center;
  display: flex;
}
.swiper-button-next {
  padding: 0 10px;
}
.swiper-button-prev {
  padding: 0 10px;
}
.racecardrow {
  justify-content: center !important;
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 12px !important;
  color: #fff !important;
}
.swiper-button-prev, .swiper-button-next{
  width: 10px !important;
}
.AddingtonStyle {
  width: 100%;
}
p.Addingtonname {
  margin-bottom: 10px !important;
  font-size: 18px !IMPORTANT;
  margin-top: 10px;
}
.match {
  margin: 0 !important;
}
button#uncontrolled-tab-example-tab-home {
  border-radius: 0;
}
ul.mb-4.tabdata1.nav.nav-tabs {
  justify-content: center;
}
.nav-link.active {
  border-bottom: 1px solid #efefef !important;
}
button#uncontrolled-tab-example-tab-ante.active {
  border-bottom: 1px solid #efefef !important;
}
.sponsorimg {
  height: 230px !important;
  width: 98% !important;
  margin: 0 !important;
}
h2.liveResult {
    margin: 5px 0 !important;
    padding-left: 15px;
}
.PredictionCard {
  margin-left: 0;
  width: 90%;
  margin: auto !important;
  margin-top: 3% !IMPORTANT;
  margin-left: 4% !important;
  margin-bottom: 4% !important;
}
h6.liveResult1 {
  left: 24px;
  line-height: 10px;
  margin-top: 10px;
}
.PredictionFlex :nth-child(1) {
  font-weight: 700;
  font-size: 18px !IMPORTANT;
  margin: 20px 0px 0px 12px;
}
.PredictionFlex :nth-child(2) {
  margin: 20px 20px 0 10px;
}
.playerPredictTable {
  margin-inline: auto;
  width: 100% !important;
}
.chartheader {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  margin: 10px 10px 0px 10px;
  padding-top: 10px;
}
.HomeRightSection {
  border-radius: 0px !important;
  margin-left: 0%;
  margin-right: 3%;
}
.HomeRightLeaderBoader{
  margin-left: 0%;
  margin-right: 3%;
}
.Copyrights {
  padding: 0 2px !important;
}
.tooltipclass1 {
  max-width: 40vw !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 15px;
}
.tooltipclass {
  max-width: 40vw !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 40px;
}
.cardracesjockey {
  justify-content: center !important;
  display: flex;
  margin-bottom: 25px !IMPORTANT;
}
.cardracesjockeycards {
  margin-left: 0 !important;
}
.col-sm-3.cardraces4.arabic {
  padding: 0 10px !IMPORTANT;
}
.row.uaestats .col-sm-2 {
  padding: 0 !important;
  width: 45%;
  margin-right: 10px;
}
.row.uaestats .col-sm-2 p{
  text-align: left !important;
}
.col-sm-12.pmclass {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}
.pmclass > p {
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 10px;
}
.cardracesjockeyleft p {
  margin-bottom: 5px !IMPORTANT;
}
.mobiledropdown {
  top: 70px !important;
}
.col-sm-6.mission {
  text-align: center;
  height: 350px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 96%;
  margin: 0 10px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 45px 20px;
  margin-bottom: 5%;
}
.col-sm-6.mission p {
  font-size: 14px;
  line-height: 20px;
}
.abtcont {
  padding: 50px 0;
}
.col-sm-12.racedown p {
  text-align: center !important;
}
svg.chosecalender {
  height: 20px;
  color: #fff;
  margin-top: 10px;
  width: 100%;
}
button.react-calendar__tile {
  margin: 10px 0 !important;
}
button.eventdata1 {
  height: 30px;
  margin-top: -10px;
}
.accordionHeader.displaymobile1.row .headerCard1.col-sm-2 {
  display: block !important;
}
.accordionHeader.displaymobile1.row .headerCard2 {
  width: 175px !important;
  justify-content: flex-end;
}
.accordionHeader.displaymobile1.row .headerCard2.col-sm-4 {
  width: 250px !important;
  justify-content: flex-start;
} 
.mobilesitewidth.racecardpage.accordion.accordion-flush  button.accordion-button {
  padding: 0;
height: 88px;
}
.headerCard2 span {
  font-size: 12px;
  padding: 0 10px;
}
.myresults button.eventdata1 {
    height: 30px;
    margin-top: -3px !important;
}
.myresults .headerCard1.col-sm-2 {
  display: block !important;
}
.myresults .headerCard2 {
  width: 175px !important;
  justify-content: flex-end;
}
.myresults .headerCard2.col-sm-4 {
  width: 250px !important;
  justify-content: flex-start;
}

.myresults  button.accordion-button {
  padding: 0;
height: 88px;
}
.myresults .headerCard2.col-sm-5 span {
  padding-left: 0 !important;
  padding-right: 15%;
  width: 33%;
  display: block;
}
.myresults .headerCard2.col-sm-5:nth-child(3){
  width: 100% !important;
}
.myresults .headerCard2.col-sm-5 span {

  width: 33%;
  display: block;
  padding: 0 5% !IMPORTANT;
}
.meetdetail p {
  margin-left: 0;
}
.mylist h2 {
  margin-left: 0;
}
.meetdetail p span{
  padding-left: 0 !important;
}
.raceCarParent1.atozParent .posttabs {
  display: flex;
  width: 100%;
  justify-content: center;
}
.rightlayoutinner.abouthead.dashboardH {
  display: none;
}
.atozParent th {
  font-size: 11px;
  padding: 8px !IMPORTANT;
  text-align: center;
}
.register {
  padding: 0 !important;
  position: relative !important;
  top: 0;
}
.queryheader {
  display: block;
  width: 100% !important;
}
.queryheader svg{
  margin-bottom: 20px;
}
.qfilter1 {
  text-align: center;
}
.leftquery {
  margin-bottom: 10px;
}
.raceCarParent1.atozParent table.tableHeaderCard {
  width: 1000px;
  overflow-x: scroll;
}
.stages .statsTable {
  width: 670px !important;
}
.stages table.statsTable th:nth-last-child(2) {
  text-align: center !important;
  padding-left: 0px !important;
  width: auto !important;
}
.stages th {
  font-size: 11px;
  text-align: center;
  width: auto !important;
}
.sti {
  width: 100%;
  overflow-x: scroll;
}
.com .stages.tens th {
  text-align: left;
  padding-left: 30px !important;
  width: 257px !important;
}
.row .SummaryDistance.col-sm-4 {
  width: 47% !important;
  margin: 10px 4px;
  padding: 0 !IMPORTANT;
}
.raceCarParent1.atozParent.raced table.tableHeaderCard {
  width: 100% !important;
  overflow-x: scroll;
}
span.react-reveal.mobiledropdown {
  padding: 15px;
  border-radius: 0 !important;
}
.dashboardpage p{
  height: auto !important;
}
::placeholder{
  font-size: 14px !important;
  padding-left: 0px !important;
}
.row.datarow {
  margin: 35px 10px;
}
.ButtonSection {
  display: flex;
  justify-content: flex-end;
  margin-right: 27px;
  margin-top: 0;
}
.topbar .cta {
  z-index: 99 !important;
  width: 50%;
  display: flex;
  height: 80px;
  padding-top: 10px;
}
.topbar .notification {
  padding-right: 0px;
  margin-top: 15px;
}
.authhover {
  width: 100% !important;
  top: 35px !important;
  z-index: 9999;
}
.authhover ul{
  margin-left: 0 !important;
}
.headerlayout {
  z-index: 1 !important;
}
.authhover>ul>li {
  line-height: 35px !important;
}
.nav-tabs .nav-link {
  color: #fff;
  font-size: 12px !important;
}
td.rb img {
  width: 100% !important;
  object-fit: cover;
}
.modal-body .formobilescroll1 .mycss1{
  display: flex;
}
.modal-body .formobilescroll1  .mycss1 td{
  width: 92px !important;
  padding-left: 35px !important;
  text-align: center;
}
table.statsTable.innermodalstats.mycss {
  width: 100%;
  margin-left: 0 !important;
  height: 40px;
}
.formobilescroll1 .mycss1 td {
  width: 65px !important;
  padding-left: 10px !important;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
}

.bannerCard > img {
height: 150px !important;
}
.row.uaestats {
  padding: 0px 15px !important;
  justify-content: flex-start;
}

.Trophydata>span:nth-child(1) {
  font-size: 17.3439px;
}

.racestatusright {
  display: flex;
  /* justify-content: space-evenly; */
  /* gap: 22px; */
  /* justify-content: center; */
  /* text-align: center; */
}
.top1 {
  width: max-content;
  margin-left: -12px;
}

.racestatusclass {
  width: 100%;
  height: 50px;
}

p.StartTimeCards{
  padding-top: 15px;
}
.resultData {
  display: flex;
  color: white;
  margin-left: 0px;
  flex-wrap: wrap;
}


.resultData p {
  margin: -30px 7px;
  font-size: 12px;
  padding: 0px 10px;
}
.col-sm-6.mission {
  width: 95% !important;
  height: 300px;
}
*[dir="rtl"] marquee p {
  font-weight: 400 !important;
  font-size: 15px !important;
}

.querypage th {
  padding: 0px 60px !important;
}
.rightquery {
  margin-right: -10px !important;
}
input[type="text"]::placeholder {
  font-size: 14px !important;
}
input[type="text"] {
  background-color: #fff !important;
  border: none !important;
}

.css-1jqq78o-placeholder {
  margin-left: 8px !important;
}

.formobile1{
  display: flex !important;
  margin-top: 0px !important;
}

.register h5 b {
  margin-right: 0px;
}
.registration .col-sm-6 i {
  left: 21% !important;
}

.registration .col-sm-6.passports i {
  position: inherit;
  right: 73%;
}

.registration .col-sm-6.passports img.PreviewImage {
  position: inherit;
  width: 30%;
  right: 2% !important;
}

.registration .col-sm-6 img.PreviewImage {
  position: inherit;
  width: 30%;
}
.mainlogin .registration {
  padding: 10px 15px !IMPORTANT;
  height: 70vh !important;
}
.CompetitionData {
  width: 94%;
}
.cardracesjockeycards li {
  padding: 0px !IMPORTANT;
}

.cardraces2 div:nth-child(3) p {
  width: auto !important;
}


}
