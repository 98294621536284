:root {
    --primary-color: var(--primary-color);
    --secondary-color: var(--secondary-color);
  }

.BlogCard{
    background: #EFEFEF;
    width: 98%;
    border-radius: 20px;
    margin-top: 5%; 
}
.blognews {
    width: 100%;
     
}
.blognews h1{
    font-style: normal;
    font-weight: 700;
    font-size: 35.4118px;
    line-height: 43px;
    text-align: center;
    color: #19469D;
    margin-top: 2.2rem;
    padding-top: 2rem;
}
.innerCardBlogs{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.singleCardBlogs {
    margin-top: 2.5rem;
    height: fit-content;
    max-height: 380px;
    padding: 10px;
}
.singleCardBlogs img{
    width: 215.76px;
    height: 212.07px;
    border-radius: 14.7783px;
}
.singleCardBlogs h2{
    font-style: normal;
    font-weight: 700;
    font-size: 15.8px;
    margin-top: 0.8rem;
    line-height: 19px;
    width: 200.76px;
    margin-inline: auto;
    color: #000000;
}
.singleCardBlogs h3{
    font-style: normal;
font-weight: 400;
font-size: 15.8px;
width: 200.76px;
line-height: 19px;
margin-inline: auto;
}
