:root {
  --primary-color: var(--primary-color);
  --secondary-color: var(--secondary-color);
}

.dashboardpage {
  display: flex;
  gap: 20px;
}

.GenerateReports {
  color: #003bad !important;
  font-family: "Inter";
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  width: 360.35px;
  height: 246.19px;
  border-radius: 0px;
  border: 3px solid rgba(0, 0, 0, 0.2);
}

.GenerateReports:active {

     background: var(--primary-color) !important; 
}

.GenerateReports:hover {

  border: none;
  color: #fff;
     background: var(--primary-color) !important; 
}

.GenerateReports:hover  p{
  color: #fff !important;
}


.GenerateReports p {
  text-align: center;
  margin-top: 85px;
  color: #19469d;
}

.OngoingRaces {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Inter";
  font-weight: 300;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;

  font-size: 20px;
  width: 360.35px;
  height: 246.19px;
     background: var(--primary-color) !important;   border-radius: 20px;
}

.OngoingRaces h3 {
  position: relative;
  left: 38px;
  top: 40px;
}

.GenerateReports h3 {
  position: relative;
  left: 115px;
  top: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 47px;
  color: #979695;
}

.DashboardCard {
  display: flex;
  flex-wrap: nowrap;
}

.dashboardH {
  margin-top: 15px;
}

.DashboardCard {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  margin: 1rem;
}

.userHeader {
  width: 96%;
  background: var(--secondary-color) !important; 
  border-radius: 0px;
  margin: 0 auto;
  height: 77px;
}

.userHeader>h3 {
  /* margin-top: 30px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15.5949px;
  padding: 26px 44px;
  color: #fff;
  font-size: 25px;
}
.form-control{
  border-radius: 0px !important;
}
.mb-3.form-floating:active {
  border: none;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(2.5rem + 2px);
  margin-top: 8px;
  line-height: 2.25;
  border: none;
}

.form-control:focus {
  color: #212529;
  background: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  font-size: 14px;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.8rem 0.25rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.floatingInputAr>label {
  right: -31px;
  left: initial;
}

button.Approvedbtn.resultbtn {
  color: #000;
}

input#floatingInput {
  margin-right: -22px;
}

.mb-3.form-floating {
  margin-top: -2px;
  width: 91%;
  height: 32px;
  border: none;
  border-radius: 20px;
  border: none !important;
  padding-left: -1px;
  font-weight: 300;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}

.spanForm {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
  margin-top: -52px;
  font-weight: 100;
  color: lightgray;
}

.floatingInputAr>label {
  right: -31px;
  left: initial;
}

.Updateprofile p {
  margin: 18px 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}

.form {
  margin-left: 45px;
  margin-right: 50px;
  display: block;
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.form input {
  margin-top: 6px;
  width: 94%;
  height: 50px;
  border-radius: 20px;
  border: none !important;

  font-weight: 300;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}

.form>input::placeholder {
  font-weight: 600;
}

.form input:focus {
  outline: none;
}

.ButtonSection {
  display: flex;
  justify-content: flex-end;
  margin-right: 27px;
  margin-top: -7rem;
}

.SubmitButtons {
  width: 186px;
  height: 51px;
     background: var(--primary-color) !important;   border-radius: 20px;
  color: white;
  border: none;
  transition: all ease-in-out 0.5s;
}

.SubmitButtons:hover {
  background: var(--primary-color) !important; 
}

.RaceButtonDiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  margin-top: 5rem;
}

.updateButton {
  margin-top: 1.5rem;
  width: 186px;
  height: 51px;
  background: var(--primary-color) !important;
  border-radius: 20px;
  color: white;
  border: none;
  transition: all ease-in-out 2s;
}

.updateButton:hover {
  background: linear-gradient(186.98deg, #010306 23.67%, #4d5a62 97.94%);
}

input::file-selector-button {
  display: none;
}

.mainrow {
  margin-top: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 57.43px;
  border-radius: 20px;

  width: 98%;
  margin-left: 19px;
}

.spanForm {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
  margin-top: -52px;
  font-weight: 100;
  color: lightgray;
}

.formInput {
  width: 97%;
  height: 50px;
  border: none;
  border-radius: 20px;
  margin-top: 10px;
  padding-left: 20px;

  font-weight: 300;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

span.react-date-picker__inputGroup__divider {
  display: none !important;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 2;
  width: 100% !important;
  flex-shrink: 0;
  border: none !important;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
  width: 800px;
}

.react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
  width: 100%;
}

.react-datetime-picker.react-datetime-picker--open.react-datetime-picker--enabled {
  width: 100%;
}

.react-datetime-picker__wrapper {
  border: none;
}

.react-datetime-picker__inputGroup__input {
  min-width: 2.54em;
}

.react-datetime-picker__inputGroup__divider {
  display: none;
}

.react-datetime-picker__wrapper {
  border: thin solid #fff !important;
}

.react-date-picker__calendar .react-calendar {
  border-width: 3px !important;
  border-radius: 20px;
  font-size: 12px;
  border-color: #19469d;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  font-size: 12px;
  box-sizing: border-box;
}

.addmore {
  position: relative;
  background: transparent;
  border: none;
  color: lightgray;
  margin-right: 18px;
  margin-top: 10px;
  cursor: pointer;
}

.mb-3.form-floating {
  margin-top: -6px;
  width: 94%;
  height: 32px;
  border: none;
  border-radius: 20px;
  border: none !important;
  padding-left: -1px;
  font-weight: 300;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
}

.mb-3.form-floating:active {
  border: none;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(2.5rem + 2px);
  line-height: 2.25;
}

.form-control:focus {
  color: #212529;
  background: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  font-size: 14px;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.8rem 0.25rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.floatingInputAr>label {
  right: -31px;
  left: initial;
}

button.Approvedbtn.resultbtn {
  color: #000;
}

input#floatingInput {
  margin-right: -22px;
  height: 52px;
}

input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__day::placeholder {
  width: 133px;
}

.userHeader2 {
  display: flex;
  justify-content: space-between;
  width: 96%;
  background: var(--primary-color) !important; 
  border-radius: 0px;
  height: 58px;
  color: white;
  margin: 0 auto;
}

.userHeader2>h6 {
  padding: 19px 40px;
  padding-right: 70px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}

.winnerRow {
  margin-top: 15px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  /* height: 57.43px; */
  border-radius: 20px;
  width: 96%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
}

h2.winnerRowHeader.accordion-header {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 20px;
}

.winnerRow h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}

.compDetail {
  /* margin-right: 35px; */
  margin-top: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  /* width: 96%; */
  /* margin-left: 35px; */
  height: fit-content;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background: #e8e8e8;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.compDetail h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.winnerDetail {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 60px;
}

.winnerDetail h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  padding-left: 10px;
}

.compDetail h5 {
  padding: 20px 1px;
  position: relative;
  left: 8px;
  top: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #000;
}

.div_maintb {
  /* height: calc(100vh - 180px); */
  width: calc(100vw);
  overflow: scroll;
  margin-left: 20px;
  width: 100%;
}

.div_maintbAr table {
  border-spacing: 0px 6px;
  margin-top: 10px;
  caption-side: bottom;
  border-collapse: inherit;
  width: 118%;
}

.div_maintb table {
  border-spacing: 0px 6px;
  margin-top: 10px;
  caption-side: bottom;
  border-collapse: inherit;
  width: 118%;
}

.div_maintb th {
  position: sticky;
  top: 0;

  color: #d1d1d1;

  width: 100vw;
  padding: 6px;

  font-weight: normal;

  padding: 10px 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.div_maintb td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.div_maintb th {
  padding: 10px 35px;
}

.div_maintb th:nth-child(1),
.div_maintb td:nth-child(1) {
  position: sticky;
  background: #f1f1f1;
  left: 0;
  border-radius: 20px;
}

.div_maintb td:nth-child(1) {
  height: 58px;
}

.div_maintb th:nth-child(1) {
  z-index: 300;
}

.div_maintbAr th:nth-child(1) {
  z-index: 300;
}

.div_maintbAr td:nth-child(1) {
  z-index: 300;
  background: rgb(211, 211, 211);
}

.div_maintb th:nth-child(1) {
  width: 15%;
     background: var(--primary-color) !important; }

.div_maintbAr th:nth-child(1) {
  width: 15%;
     background: var(--primary-color) !important; }

/* .div_maintb th:nth-child(2) {


  border-radius: 20px 0 0 20px;
} */

.div_maintb th:nth-child(1),
.div_maintb td:nth-child(1) {
  left: 0 !important;
  border-radius: 0 20px 20px 0 !important;
  position: sticky !important;
}

.div_maintbAr th:nth-child(1),
.div_maintbAr td:nth-child(1) {
  right: 0 !important;
  border-radius: 20px 0 0 20px !important;
  position: sticky !important;
  height: 58px;
}

.div_maintb td {
  position: relative;
  left: -20px !important;
}

.div_maintbAr td:nth-child(1) {
  z-index: 2;
}

.div_maintb td:nth-child(1) {
  z-index: 2;
}

/* .div_maintb th:nth-child(2) {
  padding-left: 6rem !important;
  border-radius: 20px 0 0 20px;
} */
.div_maintbAr th {
  position: relative !important;
  left: 25px !important;
  text-align: center;
}

.div_maintb th {
  position: relative !important;
  left: -20px !important;
  text-align: center;
}

.div_maintbAr td {
  padding: 10px 65px;
}

.div_maintbAr thead {
     background: var(--primary-color) !important;   width: 100%;
}

.div_maintb thead {
     background: var(--primary-color) !important;   width: 100%;
}

.div_maintbAr thead {
     background: var(--primary-color) !important;   width: 100%;
}

/* width */
.div_maintb::-webkit-scrollbar {
  width: 0px !important;
}

/* Track */
.div_maintb::-webkit-scrollbar-track {
  background: transparent !important;
}

.thirdChild {
  text-overflow: " [..]";
  white-space: nowrap;
  max-width: 400px;
}

/* Handle */
.div_maintb::-webkit-scrollbar-thumb {
  background: transparent !important;
}

/* Handle on hover */
.div_maintb::-webkit-scrollbar-thumb:hover {
  background: transparent !important;
}

.div_maintb:hover {
  cursor: grab;
  transition: all 200ms ease-in-out;
}

.div_maintbAr:hover {
  cursor: grab;
  transition: all 200ms ease-in-out;
}

.ButtonTracker {
  /* margin-left: 45px; */
  margin: 5px 38px 30px;
}

.ButtonTracker>button {
  margin: 0 10px;
}

.trackerhead {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 336px;
}

.trackerhead>h6 {
  padding-left: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* color: #ffffff; */
}

.trackerhead>h5 {
  padding-left: 8rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #ffffff;
}

.trackerbody {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.trackerbody>h6 {
  padding-left: 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #000;
  margin-left: 30px;
}

.trackerbody>h5 {
  padding-left: 1.8rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000;
}

.bodytracker {
  background: #fff;
  color: #000;
}

.Trackerbtn:active {
  background: #00a3ff;
}

li.page-item {
  margin: 0 5px;
}

button.page-item {
  border: 1px solid #fafdff;
  border-radius: 0px;

  background: #ecf1f4;

  padding: 10px 21px;

  transition: all 300ms ease-in-out !important;
}

button.page-item:hover {
  border: 1px solid #19469d !important;
    background: var(--primary-color) !important; 
  transition: all 300ms ease-in-out !important;
  color: white;
}

button.page-item1 {
  border: 1px solid #19469d !important;

  padding: 10px 21px;

  border-radius: 0px;
    background: var(--primary-color) !important; 
  color: #fff !important;

  transition: all 300ms ease-in-out !important;
}

.pagination {
  margin-top: 10px;
}

.forflex {
  display: flex;
}

.page {
  width: 98%;
}

.winnerlistaccordion.accordion {
  width: 97%;
  margin: auto;
  border-radius: 0px;
  padding: 10px;
}

.winnerlistcard.card-header {
  padding: 10px;
}

.row.winnerRow112.accordion-item {
  background: #fff !important;
  /* margin: 73px; */
  margin: 0px;
}

.row.compDetail12.accordion-body {
  background: #fff;
  padding: 0px;
  margin: -15px 10px 10px 10px;
  width: 100%;
}

.div_maintb .rb {
  border-radius: 0px !important;
}

.div_maintbAr .rb {
  border-radius:0px !important;
}

.div_maintb th {
  font-weight: 700;
  word-spacing: 2px;
  letter-spacing: 0px;
  font-size: 18px !important;
  padding: 20px 19px;
}

.div_maintbAr th {
  font-weight: 700;
  word-spacing: 2px;
  letter-spacing: 0px;
  font-size: 18px;
  padding: 25px 19px;
}

.pointandstagings {
  color: white;
}

.pointandstagings h5 {
  text-align: end;
}

.PointsParent {
  width: 95%;
  margin: 0 auto;
}

.PointsParent .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.pointShown {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.pointShown h5 {
  text-align: end;
  padding: 10px 0px;
}

.pointsData h5 {
  margin: 0 auto;
}

.pointsData {
  display: flex;
}

.PointsParent table {
  width: 100%;
  align-items: center;
}

.PointsParent table th {
  font-size: 17px;
  color: #fff;
  text-align: center;
}

.PointsParent table td {
  font-size: 20px;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}

.div_maintb .div_maintbAr .rb {
  border-radius: 0px !important;
}

.div_maintbAr .rb {
  border-radius: 0px !important;
}

.div_maintb .div_maintbAr .lb {
  border-radius: 0px !important;
}

.div_maintbAr .lb {
  border-radius: 0px !important;
}

/* .div_maintb td {
  text-align: center;
  padding: 10px;
} */

.div_maintb td {
  font-weight: 700;

  text-align: center;
}

.div_maintbAr td {
  font-weight: 600;
  word-spacing: 2px;
  letter-spacing: 0px;
  font-size: 17px;

  text-align: center !important;
}

.div_maintbAr th {
  color: #ffff;
  text-align: center !important;
}



.align {
  text-align: end !important;
}

.userHeader3 {
  display: flex;
  justify-content: space-between;
  width: 96%;
  border-radius: 0px;
  border: 1px solid #9c9999;
  height: 58px;
  color: #3a3a3a;
  margin: 10px auto;
}

.userHeader3>h6 {
  padding: 19px 40px;
  padding-right: 70px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}

div#selectNow3 {
  word-wrap: normal;
  padding: 8px;
  margin: 4px 0px;
  width: 20%;
  border: none;
  border-radius: 0px;
  font-size: 13px;
  color: #3f3f3f;
}

button.untrackdata {
  border: none;
  background: transparent;
  font-size: 16px;
  /* padding-left: 20px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  color: #585353;
  /* font-size: 14px; */
  margin: 10px 45px;
  /* line-height: 17px; */
}
button.untrackdata:hover{
  color: #19469d;
}

button#controlled-tab-example-tab-Horseshome {
  background: var(--secondary-color);
  margin-left: 0px;
  height: 39px;
  border-radius: 0px;
}


button#controlled-tab-example-tab-Jockeycontact1 {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 39px;
  border-radius: 0px;


}


button#controlled-tab-example-tab-Ownercontact {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 39px;
  border-radius: 0px;


}


button#controlled-tab-example-tab-Trainerprofile {
  background: var(--secondary-color);
  margin-left: 12px;
  height: 39px;
  border-radius: 0px;


}



.querypageicon{
  display: none;
}

.ForgetEmailheader.modal-header{
background: var(--primary-color);
color: #fff;
}

.ForgetEmailbody.modal-body{
  background: var(--primary-color);

}

.profilefile2{
  background: var(--primary-color);
  width: 150px !important;
  color: #fff;
  text-align: center;
  padding-top: 15px;
  margin: 12px;
}

.tab-tracker {
  min-height: 30rem;
}