.profilepage {
  min-height: 400px;
}

.card11 {
  width: 60%;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  padding: 16px;
  text-align: center;
  background: #f1f1f1;
  margin: auto;
}

.title11 {
  color: grey;

  font-size: 18px;
}

.coldashboard {
  width: 60%;
  margin: auto;
  margin-top: 4rem;
}

.flexcontainer {
  display: flex;
  flex-wrap: wrap;
}

.flexcontainer>div {
  background: #f1f1f1;
  width: 200px;
  height: 200px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  border-radius: 10px;
  cursor: pointer;
}

.flexcontainer>div:hover p {
  color: #078BE6;
}

.flexcontainer>div>p {
  display: block;
  font-size: 14px;
}

.backbtn {
  text-align: center;
  margin-top: 20px;
}

.button {
  background: #078BE6;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.card11>input {
  width: 80%;
  padding: 10px;
  margin: 10px;
}

.card11>button {
  margin: 20px;
  width: 150px;
}
.Profileimage {
  margin: 25px;
}