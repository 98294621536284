.modal-content ::-webkit-scrollbar {
  width: 0px;
}

:root {
  --primary-color: var(--primary-color);
  --secondary-color: var(--secondary-color);
}

.TableLottie {
  height: 200px;
  width: 200px;
  margin: auto;
}
.row.uaestats .col-sm-2 p.uae {
  color:rgb(1 97 223) !important;
  font-weight: bolder;
}
.row.uaestats .col-sm-2 p.AW {
    color:rgb(90, 90, 90) !important;
  font-weight: bolder;
}
.row.uaestats .col-sm-2 p.Lifetime {
  color: rgb(1 97 223) !important;
  font-weight: bolder;
}
.row.uaestats .col-sm-2 p.Turf {
  color: rgb(0, 97, 42) !important;
  font-weight: bolder;
}
.row.uaestats .col-sm-2 p.Dirt {
  color: rgb(128, 87, 0) !important;
  font-weight: bolder;
}
.row.uaestats .col-sm-2 p.Dist {
  color: rgb(255, 55, 0) !important;
  font-weight: bolder;
}
.menuNav {
  margin: unset !important;
  margin-left: 30px !important;
  padding-top: 6px;
}
.horsebodyitem p {
  color: #000 !important;
  margin: 0 !important;
}
.header {
  height: 100px !important;
  width: 83% !important;
  margin-right: 40px;
}
.noimageclass {
  margin: auto !important;
}
.menuNav p {
  font-weight: 500 !important;
}
.profilepic > img {
  object-fit: cover;
}
.cta {
  width: 100%;
  display: flex;
}
span.profilepic {
  margin-right: 15px;
}
p.username {
  margin-bottom: 3px !important;
  font-weight: 500;
}
.pointuser {
  font-size: 17px !important;
}
.userprofile svg {
  margin-left: 35px;
}
.authhover > ul > li {
  border-bottom: #fff !important;
  background: var(--primary-color) !important;
  color: #fff;
  transition: all 200ms ease-in-out;
  text-transform: capitalize !important;
  padding-left: 20px !important;
}
.authhover > ul > li:last-child {
  width: unset !important;
  background: var(--primary-color);
  border-radius: 0px 0px 30px 0px !important;
  height: auto !important;
  font-weight: 800;
  text-transform: uppercase !important;
}
.authhover > ul > li:hover {
  background: var(--primary-color) !important;
  transition: all 200ms ease-in-out;
}
.dropdown-item:hover {
  background: transparent !important;
}

.btn.btn-link.dropdown-toggle.show {
  background: var(--primary-color) !important;
}
ul.dropdown-menu.show p {
  color: #fff !important;
  margin-bottom: 0;
  padding-bottom: 10px;
}
button#dropdown-basic {
  background: var(--third-color) !important;
  text-transform: uppercase;
  border: none;
  border-radius: 0px;
  color: #000;
}
.dropdown-menu {
  background: var(--primary-color) !important;
  color: #fff;
}
button#dropdown-basic:focus {
  border: unset;
  outline: unset;
}
.btn.btn-link.dropdown-toggle:focus {
  border: none;
  outline: none;
  border-color: #fff !important;
  box-shadow: none !important;
}
.dropdown-item .lgpointer {
  color: #fff;
}
ul.dropdown-menu.show li:hover {
  background: var(--primary-color) !important;

  transition: all 200ms ease-in-out;
}

ul.dropdown-menu.show li {
  background: linear-gradient(
    89.98deg,
    #19469d 23.67%,
    #00a3ff 97.94%
  ) !important;
}

ul.dropdown-menu.show {
  background: linear-gradient(
    89.98deg,
    #19469d 23.67%,
    #00a3ff 97.94%
  ) !important;
}

ul.dropdown-menu.show p {
  color: #fff !important;
}

.exnews {
  height: auto !important;
}

.newsflex p {
  margin-bottom: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

marquee p {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.newsflex {
  padding: 25px 25px;
}

.rightlayoutinner {
  padding: 0px 10px;
  padding-top: 10px !important;
  padding-bottom: 18px !important;
}

button#dropdownMenuButton1 {
  font-weight: 600;
  text-transform: uppercase;
}

.sponsor {
  /* height: 100% !important; */
  flex: 1 1;
  margin: 10px -10px 0px 10px;
  z-index: -1;
}
.AddingtonStyle {
  text-align: center !important;
}
.racetime1 p {
  color: #19469d;
  font-size: 21px;
}
.sponsorimg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background: #00000029;
}

.first-txt {
  z-index: 999999;
  color: #fff;
  text-transform: uppercase;
}

.slideimg1 {
  object-fit: cover;
}

.clubname {
  padding: 0px 5px 0px 17px !important;
  font-weight: 500;
}

/* .singleracecard:hover>.singleracecardbtn {
  margin-top: -8px !important;
}

.singleracecard:hover {
  height: 160px !important;
} */

.singleracecardbtn button {
  height: 35px;
  width: 100%;
  border-radius: 0px;
  /* opacity: 0; */
  transition: all 500ms ease-in-out;
}

.RaceCardSlider {
  height: auto;
  padding-top: 0px;
  /* padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid #929292; */
}

/* .singleracecard:hover button {
  opacity: 1;
  transition: all 500ms ease-in-out;
} */

.singleracecardbtn {
  display: block !important;
  transition: 0.4s linear all;
}

.exnews {
  background: var(--fourth-color) !important;
}
::placeholder {
  font-size: 12px !important;
  opacity: 0.5;
  text-transform: capitalize;
}
.owner {
  font-weight: 600 !important;
  font-size: 15px !important;
  padding-top: 0px;
  padding-bottom: 4px;
  color: var(--secondary-color);
}

.Addingtonname {
  line-height: 20px !important;
  margin-top: -5px;
  margin-bottom: 10px;
}

.AddingtonStyle img {
  width: 100%;
  height: 95px !important;
  object-fit: none;
}

.RaceCardSection {
  padding-top: 20px;
}

.CompetitionHeader h2 {
  font-size: 26px !important;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.AccordionHeader {
  align-items: center;
}

.AccordionHeader p {
  margin: 0 !important;
  text-transform: capitalize;
 
}

.AccordionHeader > p:nth-child(2) {
  padding: 0 !important;
}

.accordion-button {
  padding: 20px 25px;
}

.accordion-button:focus-within .AccordionHeader p:first-child {
  font-weight: 800 !important;
}

.HomeRightSection .accordion-button:not(.collapsed) {
  border-radius: 0px !important;
  background: #fff !important;
  border: 1px solid #fff !important;
}

.HomeRightSection .accordion-item {
  background: var(--primary-color) !important;
}

.HomeRightSection .accordion-body {
  background: var(--primary-color) !important;
  border-radius: 0px;
  margin-bottom: 15px;
}

.Competition_Matches p {
  margin: 0 !important;
}

.Competition_Matches {
  padding: 2px;
  align-items: center;
}
.antepostbody p{
  font-weight: bolder !important;
  color: #292d76;
}

.bannerHome {
  height: 400px !important;
  margin-top: 5px;
}

.bannerText {
  padding: 30px !important;
  padding-top: 130px !important;
  position: relative !important;
}

.registerbtn {
  width: 175px;
  height: 45px;
  margin-top: 3px;
}

.Currentpostdiv {
  padding: 5px;
}
.accordion-button {
  background: var(--fourth-color) !important;
  border: 1px solid var(--fourth-color) !important;
}
.match .accordion-button {
  background: #fff !important;
  border: 1px solid #fff !important;
}
.accordion-button:not(.collapsed) {
  border-radius: 0px !important;
  background: var(--fourth-color) !important;
  border: 1px solid var(--fourth-color) !important;
}

.accordion-item {
  background: var(--primary-color) !important;
  border-radius: 0px !important;
}

.accordion-body {
  background: #fff !important;
  border-radius: 0px !important;
  margin-bottom: 1px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0px !important;
}

.match ul button {
  font-size: 16px !important;
}

button#uncontrolled-tab-example-tab-contact {
  font-size: 25px !important;
}

.newpost {
  min-height: unset !important;
  max-height: 700px !important;
  padding-bottom: 20px;
  /* overflow: scroll !important; */
}

.newpost .react-reveal.Currentpostdiv {
  min-height: unset !important;
  max-height: fit-content;
  padding-bottom: 20px;
  /* overflow-y: scroll; */
}

.HomeRightSection .CompetitionData {
  max-height: 700px;
  overflow-y: auto;
}

.HomeRightSection {
  max-height: unset !important;
  min-height: unset !important;
}

.LeaderData {
  height: auto !important;
}

p.adstitlepos {
  position: absolute;

  text-align: center;
  color: #fff;
  line-height: 55px;
  width: 100%;
}

.livedatacard p {
  margin-bottom: 10px !important;
}

.customers1 th:first-child {
  border-radius: 0px 0px 0px 00px;
}

.customers1 th:last-child {
  border-radius: 0px 0px 0px 0px;
}

/* .customers1 th {
    font-size: 15px;
}
.customers1 th {
    padding-top: 18px;
    padding-bottom: 18px;
} */

.live_result_data {
  padding-bottom: 30px;
}

.customers1 {
  border: none;
  /* width: 100%; */
  margin: auto;
  background: transparent;
  margin-top: 1px;
  margin-bottom: 50px;
}

.chartPrediction {
  /* display: flex; */
  /* gap: 1.1rem; */
  /* margin-top: 1rem !important; */
  display: contents;
}

.customers13 td {
  font-size: 13px;
  padding-top: 15px !important;
  padding-bottom: 5px !important;
  font-weight: 500;
}

.resultCard {
  border-radius: 0px;
  background: var(--secondary-color) !important;
  height: 420px !important;
  margin-top: 1.1rem;
  overflow-y: scroll;
}

.resultCard::-webkit-scrollbar {
  width: 0px;
}

.BlogCard {
  background: var(--secondary-color) !important;
  width: 100%;
  border-radius: 0px;
  /* margin-top: 13%; */
}
.innerCardBlogs img {
  margin: 41px;
  width: 100%;
}
.authhover {
  z-index: 9999999999;
}

ul.dropdown-menu.show {
  z-index: 9999999;
}

ul.dropdown-menu.show li:hover {
  cursor: pointer;
}

.cta {
  justify-content: end;
}

.rightlayoutinner {
  align-items: baseline;
}

.modal-body img.sponsor {
  height: auto !important;
}

.AddingtonStyle {
  margin-top: -20px;
}

.chartheader {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}

.presictData h6 {
  text-transform: capitalize;
}

.blognews h1 {
  padding-bottom: 20px;
}

.Copyrights {
  font-size: 11px;
  padding: 0 20px;
}

footer li b {
  font-size: 18px !important;
  color: var(--secondary-color);
}

.LinkStyle > li > p {
  color: #3b3b3b;
  font-size: 14px;
  margin-bottom: 10px;
}

.text-muted > li > p {
  color: #3b3b3b;
  font-size: 14px;
  margin-bottom: 10px;
}
.formclass1 > input {
  height: 50px;
  margin-bottom: 7px;
}

.newabc .sponsor {
  height: 80px !important;
  flex: 1 1;
  margin-top: 19px;
  width: 120px;
  object-fit: scale-down;
}

.racenameflex > p {
  font-size: 23px;
  text-transform: capitalize;
}

p.itemtime {
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.RaceDetailHeader {
  border-radius: 0px !important;
}

.itemraces_left > .race {
  width: 100px !important;
  height: 77px;
  font-size: 12px;
  padding-top: 15px;
}

span.race p:nth-child(2) {
  font-weight: 600;
}

span.race p {
  color: #fff;
}

.itemraces_center > h5 {
  text-transform: uppercase !important;
}

/* .itemraces_center div p:nth-child(2){
    margin-left: 80px;
} */
.itemraces_center > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-transform: capitalize !important;
}

.itemraces_left > .race {
  height: 80px !important;
}

.itemraces > p {
  font-size: 14px;
  margin: 20px 10px 30px 16px;
}

.itemraces_center {
  margin-top: 20px;
  flex: 0;
  flex: 0 0 30%;
  margin-left: 20px;
}

.itemraces_right {
  display: flex;
  margin: 0px 15px 0px 0px;
  flex: 0 0 20%;
}

.itemraces_right .distance {
  width: 200px;
  height: 90px;
}

.itemraces_right .Favourite {
  font-weight: 400;
  font-size: 13px;
}

span.itemraces_right p {
  padding: 15px;
  display: none;
}

.racedisc {
  padding: 10px;
}

.prizecard {
  padding: 12px;
  height: 180px;
}

.prizecardheaders {
  padding-bottom: 0px;
}

b.mx-1 {
  padding-left: 10px;
}

.Trophydata span {
  margin-right: 6px;
  font-weight: 700;
  font-size: 20px;
}

.Trophydata_P > h6 {
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: auto;
  text-align: center;
}

.Trophydata span {
  display: inline-flex;
}

.RaceDetailHeader .nav-tabs {
  padding: 20px 0;
}

.RaceDetailHeader .nav-tabs .nav-link {
  color: #fff;
  font-size: 15px;
}

.RaceDetailHeader .nav-tabs .nav-link {
  color: #fff;
  font-size: 15px;
  border-radius: 0px;
}

.RaceDetailHeader .nav-tabs .nav-link:focus {
  color: #fff;
  font-size: 15px;
  font-weight: 600 !important;
}

.cardraces1 > img {
  width: 100px;
  height: 90px;
  margin-top: 15px;
  object-fit: scale-down;
}

span.cardraces1box h3 {
  font-size: 30px;
  text-align: center;
}

.cardraces1box {
  width: 85px;
  height: 100px;
  padding: 10px;
}

span.cardraces1box p {
  color: #000;
  font-size: 10px;
  font-weight: 400;
}

.card-header {
  padding: 15px 15px;
  background: #fff;
}

.cardraces2 div:nth-child(1) {
  align-items: center;
}

.cardraces2 div:nth-child(2) {
  align-items: center;
}

.cardraces2 p {
  /* margin-right: 10px; */
  line-height: 0px;
  font-size: 15px;
  margin: 0px 10px 10px 0px;
  display: flex;
  align-items: baseline;
}

.cardraces2 b {
  margin-left: 10px;
  /* color: #000 !important; */
  line-height: 14px;
}

.cardraces2 div:nth-child(3) p {
  font-size: 14px !important;
  padding: 10px 0px 5px 0px;
  width: 100%;
}

.cardraces2 img {
  width: 30px;
  height: 30px;
  margin: 0 5px;
  border-radius: 0px;
  object-fit: cover;
  display: none;
}

.trainerbreader_section {
  margin-bottom: 25px;
}

.cardraces2 {
  padding-left: 7px;
}

.cardracesjockeyleft > p {
  font-size: 16px;
  /* line-height: 10px; */
  line-height: 15px;
}

.cardracesjockeyleft p:nth-child(3) {
  font-weight: 600 !important;
  font-size: 11px !important;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
}

.cardracesjockeycards > ul li {
  font-weight: 400;
  font-size: 13px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
  width: 30px;
  height: 30px;
  margin: 2px;
}

.pmclass > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: rgba(0, 0, 0, 0.5);
}

.pmclass {
  margin: 0px 10px 0 2px;
  display: flex;
  gap: 30px;
}

.uaecareer > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: rgba(0, 0, 0, 0.5);
}

button.showMore {
  border: 1px solid var(--fourth-color) !important;
  padding: 10px 30px;
  border-radius: 0px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background: var(--fourth-color) !important;
  transition: all 300ms ease-in-out;
  margin-top: 5px;
}

button.showMore:hover {
  transform: scale(1.05);
  transition: all 300ms ease-in-out;
}

.nonrunner {
  position: absolute;
  height: 72%;
  background: rgb(216 226 226);
  opacity: 0.4;
  width: 99%;
  text-align: center;
  font-size: 70px;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10px;
  font-weight: bolder;
  cursor: not-allowed;
  z-index: 9999999999;
  border-radius: 0px;
}
.modal-lg,
.modal-xl {
  --bs-modal-width: 1000px;
}
.card-header {
  position: relative;
  z-index: 999;
}

.RaceDetailCard {
  background: transparent !important;
}

.RaceAndHorseModelDataCSS .card {
  border-radius: 0px !important;
  border: none;
}
.modal-body .horsebodyitem {
  margin: 10px 90px;
}
.card-header:first-child {
  border-radius: 0px;
  border: 1px solid transparent;
  z-index: 1;
}

.RaceAndHorseModelDataCSS .card {
  border-radius: 0px !important;
  border: 1px solid #efefef;
  margin: 10px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.RaceAndHorseModelDataCSS {
  border: unset;
  border-radius: 0px;
}

.RaceAndHorseModelDataCSS {
  border: unset;
  border-radius: 0 !important;
}

.RaceNav {
  background: #fff !important;
}

/* ul.mb-3.RaceNavItem.nav.nav-tabs {
    background: var(--primary-color) !important; 
  border-radius: 0px 0px 20px 20px;
  padding-bottom: 15px;
  padding-top: 10px;
} */
ul.mb-3.RaceNavItem.nav.nav-tabs {
  background: var(--fourth-color) !important;
  border-radius: 0px;
  padding-bottom: 6px;
  padding-top: 0px;
  padding-left: 10px;
}
.RaceNav ul.mb-3.RaceNavItem.nav.nav-tabs {
  margin-left: 0px !important;
}

button.ShowPreviousHistory {
  width: 20%;
  margin: auto;
  display: block;
}

/* .RaceDetailCard {
    border-color: transparent !important;
    margin-top: -45px;
} */
.RaceDetailCard .accordion-collapse.collapse.show .card-body {
  padding: 0px 0;
  margin-bottom: 5px;
}

.RaceDetailCard .accordion-collapse .card-body {
  padding: 0px 0;
  margin-bottom: 5px;
}

.RaceDetailCard .BodyNew {
  border-radius: 0px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.cta {
  padding-right: 60px;
  /* background: var(--secondary-color); */
}

.RaceDetailCard {
  border-color: transparent !important;
  margin-top: -16px;
  background: #fff !important;
  padding: 0px;
}

button.PrintOutClass {
  padding-bottom: 30px;
  padding-top: 20px;
}

button.PrintOutClass {
  padding-bottom: 30px;
  padding-top: 20px;
  width: 20%;
  /* text-align: center; */
  display: block;
  margin: auto;
  margin-bottom: 60px;
}

button.PrintOutClass {
  padding-bottom: 10px;
  padding-top: 10px;
  width: 20%;
  /* text-align: center; */
  display: block;
  margin: auto;
  margin-bottom: 45px;
  margin-top: 40px !important;
  border-radius: 0px;
  color: #fff;
  background: var(--primary-color) !important;
  transition: all 300ms ease-in-out;
}

button.PrintOutClass:hover {
  transform: scale(1.05);
  transition: all 300ms ease-in-out;
}

.itemraces_right {
  align-items: center;
}

.justsponser .aboutpagesection {
  height: fit-content;
  width: 100% !important;
  display: flex;
  flex-direction: row;
  min-height: 600px;
  margin: 0 auto;
  padding: 0 !important;
}

.stages .raceCarParent.stages {
  width: 100%;
  margin: 10px auto;
  border: 1px solid rgb(244, 244, 244);
  min-height: 0;
  border-radius: 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
  padding: 30px;
}
.stages h1 {
  margin-left: 0;
  font-size: 24px;
  padding: 6px 0;
}

.stages p {
  margin-left: 0;
}

.justsponser .sponsorimg {
  height: 63vh;
}

.container-fluid.mystages {
  padding: 40px 0px;
  overflow: hidden;
}

.stages .accordion {
  margin: 8px 0 !important;
}

.stages .headerCard3.col-sm-1 {
  text-align: center;
}
.nocompetitionimg1 {
  margin-top: 25px;
}

.stages .customers {
  width: 100%;
}

.over .customers {
  width: 100% !important;
}

.stages th {
  width: 300px;
  text-transform: capitalize !important;
  font-size: 15px;
}

.stages td {
  width: 100px;
  text-transform: capitalize;
}

.voted p {
  margin-bottom: 5px !important;
  text-align: center;
}

.voted h3 {
  text-align: center;
}

/* .stages table:hover {
  cursor: grab;
} */

.headerCard1.col-sm-8 h4 {
  text-transform: capitalize;
}

.stages p {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
}

.posttabs p {
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  padding: 6px;
  transition: all 200ms ease-in-out;
  font-size: 16px;
  text-align: center;
}

.posttabs p:hover {
  cursor: pointer;
  background: var(--secondary-color) !important;
  border-radius: 0px;
  font-size: 15px;
}

.posttabs p:focus-within {
  background: var(--secondary-color) !important;
  padding: 10px;
  border-radius: 0px;
}

.posttabs {
  background: var(--primary-color) !important;
  padding: 8px;
  border-radius: 0px;
  margin: auto;
}

.posttabs .row {
  justify-content: center;
}

/* .querytoolpagination {
  margin-top: 50px;
} */

.querytoolpagination {
  margin-top: 50px;
  position: absolute;
  bottom: -110px;
  left: 50%;
}

.posttabs .col-sm-1 {
  flex: 0 0 auto;
  width: 12.333333%;
}

.headerCard2.col-sm-5 h5 {
  text-align: left;
  margin: 0px 20px;
}
.css-b62m3t-container {
  margin-top: 0px !important;
}
.headerCard2 span {
  font-size: 12px;
  padding: 0 20px;
}

.headerCard1.col-sm-2 img {
  border-radius: 10px;
}

.mylist p {
  color: #fff;
}

.mylist i {
  color: #fff;
}

.mylist h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.mylist span {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.col-sm-2.arr {
  text-align: end;
}

.col-sm-2.arr:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #fff;
}

.mylist h2:hover {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

.mylist i:hover {
  cursor: pointer;
}

.resultss .col-sm-4 {
  background: var(--secondary-color) !important;
  border-radius: 0px;
  margin: 0 5px;
  padding: 25px;
}

.resultss p {
  text-align: left;
  margin-bottom: 5px;
}

.resultss h2 {
  text-align: left;
  font-size: 22px;
  line-height: 25px;
}

.resultss h2:hover {
  text-align: left;
  font-size: 22px;
  line-height: 25px;
}

.meetingtyp p {
  padding-top: 10px;
  font-size: 16px;
  font-weight: 500;
}

.meetingtyp span {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}

.raceresult p {
  font-size: 14px;
  font-weight: 700;
}

.raceresult i.fa.fa-video-camera {
  font-size: 24px !important;
}

.meetdetail p {
  font-size: 12px !important;
  font-weight: 700;
}
.Currentpostheader {
  margin-top: 0 !important;
}
.meetdetail p span {
  font-size: 12px !important;
  color: #fff;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 700;
}

.meetdetail h4 {
  color: #fff;
  font-size: 18px;
}

.meetdetail h2 {
  text-align: left;
  font-size: 16px !important;
  font-weight: 700;
}

.meetdetail h2:hover {
  text-align: left;
  font-size: 16px !important;
  font-weight: 700;
}

.resultss .col-sm-4 {
  width: 32%;
  margin-top: 8px !important;
}

.meetdetail button {
  background: var(--primary-color) !important;
  color: #fff !important;
  box-shadow: unset !important;
  border: unset;
  padding: 5px 50px;
  border-radius: 10px;
  margin-top: 15px;
}

.Statisticsbtn1 {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2px !important;
}

.accordion-button:hover {
  z-index: 0 !important;
}

.css-13cymwt-control {
  height: 45px;
}

.resultracelength img {
  height: 50px;
  width: 100px;
  margin: -30px 18px;
  border-radius: 15px;
  object-fit: fill;
}
table.customers {
  border-collapse: separate;
  border-spacing: 0 0.2em;
}
tr.daygapclass p {
  padding: 6px;
}

.com_nnerdata {
  padding: 0 !important;
}
p.updownicon {
  /* font-size: 10px; */
  margin: 0;
  color: #000;
  font-size: 15px;
}

svg.crossIcon {
  vertical-align: middle;
  position: relative;
  right: -20%;
  margin-top: -10%;
  z-index: 9999;
  font-size: 12px;
}

.imagedimension {
  width: 100%;
  height: 400px;
  object-fit: initial;
}

.queryheader {
  display: flex;
}
button.react-calendar_tile.react-calendarmonth-viewdays_day {
  height: 65px;
}

button.eventdata1 {
  background: #19469d;
  /* z-index: 999999999999 !important; */
  border-radius: 0px;
  color: #fff;
  /* padding: 10px; */
  height: 35px;
  width: 35px;
  /* position: absolute; */
  /* top: 92px; */
  margin-left: 0px;
  opacity: 1;
  /* margin-top: 3px; */
  padding: 12px 0px;
  /* z-index: -12 !important; */
  position: absolute;
}
.react-calendar__tile--active {
  background: transparent !important;
  color: #423e3e;
}
button.react-calendar__tile {
  display: block;
  text-align: center !important;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #00a3ff !important;
  color: #fff !important;
}
.querypage .querytoolpagination {
  position: relative !important;
  bottom: unset;
  left: unset;
}

.querypage .rightquery {
  flex: 3;
  background: #fff !important;
  border-radius: 0px;
}
.querypage time {
  font-size: 12px;
}
.querypage .leftquery {
  padding: 20px 0;
}
p.resetrool {
  margin-bottom: 10px;
}
.css-13cymwt-control {
  border-radius: 0px !important;
}
.querypage th {
  color: #fff;
}
.rightquery {
  margin-right: 20px;
}
button.bgcolor abbr {
  color: #fff;
  z-index: 999;
}
.posttabs p:hover {
  color: #1066bf;
}
.inner_itemraces {
  gap: 60px;
}
.racestatuscolor {
  margin-right: 10px !important;
}
.verdictrace {
  text-align: center;
}
.verdictrace th {
  width: 200px;
}
.tooltipclass {
  max-width: 14vw !important;
}
.racepageitem p {
  text-align: initial !important;
}
.menuNav .logoclass {
  margin-top: 5px !important;
  width: 80% !important;
  height: auto !important;
}
.newsflex p {
  color: #1653ab !important;
}
.Addingtontimer {
  justify-content: center !important;
  width: auto !important;
}
span.raceroursecolor {
  height: 4px !important;
  width: 30% !important;
  position: absolute;
  left: 5% !important;
  top: 90%;
}
.Currentpostheader {
  align-items: end !important;
}
p.StartTimeCards {
  text-transform: uppercase;
}
p.searchname {
  line-height: 12px;
  cursor: pointer;
  font-size: 12px;
  cursor: pointer;
  text-align: left !important;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 18px;
}
.searchhorsedata1 {
  font-size: 18px;
  font-weight: 600;
  color: #646161;
  text-align: left;
  padding-left: 20px;
  color: #000;
  margin-bottom: 0 !important;
}
.racedown p {
  font-weight: 800;
}
.racepageitem p {
  font-weight: 600 !important;
}
.RaceDetailsName h5 {
  font-weight: 800;
}
.itemraces_right .distance {
  border: unset !important;
}
span.itemraces_left p {
  font-weight: 800;
  font-size: 13px;
}
h5.tooltipclass {
  font-weight: 700;
}
.racecardcss1 p {
  margin-bottom: 20px;
}
.cardracesjockeyimg {
  width: 55px;
  height: 55px;
  border-radius: 60px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 4px;
}
h4.noFoundHeader:first-child {
  display: block !important;
  text-align: center !important;
  padding-left: 0 !important;
  font-size: 20px !important;
  letter-spacing: 0 !important;
  font-weight: 600 !important;
  margin: 35px 0 !important;
}
span.cardraces1box p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.imgDiv img {
  width: 85% !important;
  height: 100% !important;
  object-fit: contain;
}
.imgDiv {
  width: 45px !important;
  height: 45px !important;
}
.Summarydata p {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  width: 20px;
}
.Summarydata {
  display: flex;
  padding: 4px;
  align-items: baseline;
}
tr.BodyNew1.historyhorseth td {
  font-weight: 900;
  font-size: 13px;
}
.horsecenterdata span p:last-child {
  color: #000;
  font-weight: 600;
}
.horseshirtimage {
  width: 100% !important;
  object-fit: contain;
}
.mynation {
  color: #000 !important;
}
.modal-body .horseshirtimage {
  width: 100px !important;
}
.AdsCard .carousel-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutpagesection .imgDiv img {
  width: 100% !important;
  height: auto !important;
  object-fit: contain;
}
.aboutpagesection .imgDiv {
  width: auto !important;
  height: auto !important;
}
.newsflex p {
  color: var(--primary-color) !important;
  font-weight: 700 !important;
}
.match .nav-link {
  height: 60px !important;
}
.Summarydata {
  width: 95% !important;
  max-width: 100%;
  border-bottom: 1px solid #efefef;
  margin: auto;
  display: flex;
  align-items: center;
}
.container.mystages {
  margin-top: 25px;
  max-width: 100%;
  padding: 0;
}
.mystages td {
  padding: 12px 0 !important;
}
.mystages .accordion-header table {
  width: 95% !important;
}
.stages th {
  text-align: center;
}
.stages .statsTable {
  caption-side: bottom;
  border-collapse: collapse;
  margin-left: 0 !important;
  width: 93% !important;
  margin-top: 12px;
}
.stages th {
  text-align: left;
  padding-left: 70px !important;
  width: 257px !important;
}
.stages table.statsTable th:nth-last-child(2) {
  text-align: left;
  padding-left: 50px !important;
  width: 257px !important;
}
.stages .BodyNew.mobiledatascroll th {
  text-align: center !important;
  padding-left: 0 !important;
  width: 257px !important;
}
.stages .BodyNew.mobiledatascroll .customers {
  width: 95%;
  margin: auto;
}
.stages.tens th {
  text-align: left;
  padding-left: 60px !important;
  width: 257px !important;
}
.rightlayoutinner {
  padding-left: 0 !important;
}
.atozParent th {
  padding-left: 0 !important;
  text-align: center;
}
.react-calendar__tile--now:enabled:hover {
  background: transparent !important;
}
button.react-calendar__tile {
  background: transparent !important;
}
.atozParent p:hover {
  background: transparent !important;
  color: #fff;
  font-size: 16px;
  padding-bottom: 0 !important;
}
.AccordionHeader > p:nth-child(1) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 750;
  font-size: 17px !important;
  line-height: 19px;
  color: #000;
}
.uaecareer > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 11px;
  color: rgba(0, 0, 0, 0.5);
}
.race_trainerbreader p:last-child {
  font-weight: 800 !important;
}
div p.ownernamestyle1:last-child {
  font-weight: 700 !important;
}
.cardracesjockeyleft p:last-child {
  font-weight: 800 !important;
  font-size: 12px !important;
}
.stages .mycardclass1 td.rb img {
  width: 13%;
  object-fit: cover;
}
td.rb img {
  width: 25%;
  object-fit: cover;
}
.modal-body .RaceNav.HorseNav .BodyNew td {
  font-weight: 400 !important;
  font-size: 12px;
}
.stages .mycardclass1 th {
  text-align: left;
  padding-left: 42px !important;
  width: 257px !important;
}
.stages .mycardclass1 td {
  font-weight: 400 !important;
}
.mycardclass1 img {
  width: 20%;
}
.modal-body .horsecenterdata.jockeyname1 span:first-child {
  flex-direction: column;
}
.modal-body .horsecenterdata.jockeyname1 span:first-child p {
  font-weight: 500 !important;
}
.racecardcss1 p:last-child {
  font-weight: 800;
  font-size: 13px;
}
.resultimages {
  height: 100px;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
}
.resultimages span {
  margin-right: 20px;
  margin-left: 15px;
}
.accordion-button {
  border-radius: 0px !important;
}
.profilefile2 {
  background: var(--primary-color);
  width: 20% !important;
  color: #fff;
  text-align: center !important;
  padding-top: 14px;
  margin: 12px;
  display: inline-flex;
  justify-content: center !important;
  padding-left: 4%;
}
.registrationform h5 {
  text-align: center !important;
  padding-top: 40px !important;
  margin-left: 0 !important;
  text-transform: uppercase !important;
}
.registrationform p {
  text-align: center !important;
  padding-top: 5px !important;
  margin-left: 0 !important;
}
.buttonRegister {
  margin-top: 0 !important;
}
.registrationform {
  margin-top: 7%;
}
.mylogo {
  width: 330px !important;
}

.submitbtn1 div button {
  margin: 12px;
  width: 95%;
}

label.form-label {
  color: #fff;
  font-size: 14px;
  padding: 0px;
}
.registration {
  height: 100vh;
  background-color: var(--secondary-color);
  padding: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  /* position: absolute;
  width: 50%;
  top: 0; */
  z-index: 9;
  margin-top: 0px;
}
.registration img {
  width: 30%;
}
.register {
  padding: 0 !important;
  position: relative;
  /* top: 0; */
}
.registration label {
  text-align: left !important;
}
.registration .col-sm-6 {
  flex: 0 0 auto;
  width: 50%;
  text-align: left;
  margin-top: 10px;
}
.register h5 {
  color: #fff;
  margin-top: 20px;
}
.register h5 b {
  color: #fff;
  margin-right: 10px;
  text-transform: uppercase;
}
.register ::placeholder {
  padding-left: 0 !important;
}
.register input {
  padding: 12px 10px;
  font-size: 14px !important;
  border-radius: 0px;
}
.register select {
  padding: 12px 10px;
  font-size: 14px !important;
}
.headerlayout {
  display: flex;
  position: relative;
  z-index: 999;
  background: var(--primary-color);
}

.registrationimg {
  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  margin-top: 0px;
  justify-content: center;
}
.submitbtn1 div button {
  margin: 12px;
  border-radius: 0px;
  width: 30%;
  margin: 0 !important;
  margin-top: 20px !important;
  padding: 12px 20px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: var(--primary-color);
  border: unset !important;
}
.register .invalid-feedback {
  background: red;
  color: #fff;
  padding: 1px 10px;
  font-size: 10px;
  width: 70%;
  border-radius: 0px;
}
.forget p {
  color: #fff;
  margin: 0 !important;
  text-align: right !important;
}
.login input {
  padding: 30px 10px !important;
}

.login div button {
  margin: 12px;
  width: 80%;
  margin: 0 !important;
  margin-top: 0px !important;
  padding: 12px 20px;
  font-size: 18px;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 600;
  background-color: var(--primary-color);
  border: unset !important;
}
.row.login .form-group {
  padding: 10px 0;
  position: relative;
}
.row.login {
  width: 70%;
  margin: auto;
  padding-top: 8%;
  position: relative;
}
.row.login .showIcon {
  position: absolute;
  font-size: 20px !important;
  cursor: pointer;
  color: #747272 !important;
  top: 30%;
  bottom: 35px;
  left: auto;
  right: 2%;
}
.row.login ::placeholder {
  font-size: 14px !important;
}
.mainlogin .showIcon:hover {
  transform: scale(1) !important;
}
.forget p {
  cursor: pointer;
}
.ForgetEmailbody.modal-body input {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0px !important;
}
.form.forgetclass {
  margin: 0;
}
.ForgetEmailbody.modal-body button.buttonRegister {
  width: 40%;
  color: #fff;
  margin: 0 !important;
  background-color: var(--secondary-color);
}
.ForgetEmailbody.modal-body {
  text-align: center;
  padding-bottom: 20px;
}
.form.forgetclass {
  text-align: left;
  height: auto !important;
  padding-bottom: 15px;
}
.form.forgetclass label {
  color: #fff;
  font-weight: 700;
  padding-bottom: 10px;
}
.form.forgetclass ::placeholder {
  font-size: 14px !important;
}
.form.forgetclass input {
  padding-left: 15px !important;
}
.mylogo img {
  width: 60%;
  height: 100%;
  margin: 0px 50px;
  /* padding: 0px 0px; */
  object-fit: scale-down;
}

.col-sm-10.menus {
  margin-left: -1% !important;
}

.header {
  height: auto !important;
  width: 100% !important;
  margin-right: 40px;
}
.menuNav {
  justify-content: flex-start;
  margin-left: 0 !important;
  padding: 0 !important;
}
.menuNav p {
  color: #fff;
  align-items: center;
  margin-top: 0px !important;
  margin-bottom: 0 !important;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
}
.menus {
  display: flex;
  align-items: center;
  justify-content: start;
}
.menus a {
  padding: 0 40px !important;
}
.col-sm-10.menus .dropdown {
  padding: 0 40px !important;
}
.menus div {
  padding: 0px 0px !important;
  /* width: 100%; */
}
.mainmenurow {
  padding: 10px 0;
}
.menus .dropdown a {
  padding: 0 !important;
}
.menus .dropdown a p {
  margin-top: 30px !important;
  padding-left: 20px;
}
.menus .dropdown a p:hover {
  background-color: #2086ba;
}
.menus div.dropdown-content {
  padding: 10px 0px !important;
}
.menus .dropdown-content {
  min-width: 250px !important;
}
.racetime1 p {
  color: #fff !important;
  font-size: 16px;
  margin: 0 !important;
  margin-top: -5px !important;
  position: relative;
  font-weight: 700;
}
footer .container.py-4 {
  max-width: 85%;
}

.topnav {
  display: flex;
}

.HomeRightSection {
  border-radius: 0px !important;
  margin-top: 5px !important;
}

.HomeRightLeaderBoader {
  border-radius: 0px !important;
  margin-top: 1rem;
}

/* .liveresult1 {
  border-top: 2px solid #a39b9b;
} */
.bannerHome {
  border-radius: 0px !important;
}

.newpost {
  border-radius: 0px !important;
}

.row.racesliderrow {
  margin-top: 10px;
}

ul.mb-4.tabdata1.nav.nav-tabs {
  height: 70px !important;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 1px -200px;
  padding: 0;
}
.HomeRightSection .accordion-body {
  background: #fff !important;
}
.HomeRightSection .accordion-button:not(.collapsed) {
  border-radius: 0px !important;
  background: var(--fourth-color) !important;
  border: 1px solid var(--fourth-color) !important;
}
.HomeRightSection .accordion-button {
  border-radius: 0px !important;
  background: var(--fourth-color) !important;
  border: 1px solid var(--fourth-color) !important;
}
.HomeRightSection .accordion-body p {
  color: #000 !important;
}
.HomeRightSection .accordion-button p {
  color: #fff !important;
}
.slick-slide {
  outline: none;
  width: 250px !important;
}
.swiper-button-lock {
  display: block !important;
}
.swiper-button-next {
  background: var(--primary-color);
  opacity: 1 !important;
  right: 1%;
  top: 88%;
  z-index: 99;
  bottom: unset !important;
  left: unset !important;
  z-index: 999999;
  transition: all 200ms ease-in-out;
  padding: 0 20px;
  display: flex !important;
}
.swiper-button-prev {
  background: var(--primary-color);
  opacity: 1 !important;
  z-index: 999999;
  transition: all 200ms ease-in-out;
  padding: 0 20px;
  display: flex !important;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: var(--secondary-color);
  transition: all 200ms ease-in-out;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  right: 5%;
  top: 88%;
  z-index: 99;
  bottom: unset !important;
  left: unset !important;
  z-index: 999999;
  padding: 0 20px;
  display: flex !important;
}
.swiper {
  padding-bottom: 50px;
  margin-bottom: 20px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 18px !important;
  color: #fff !important;
}
span.mybor {
  display: block;
  margin-bottom: 20px;
  border-bottom: 2px solid #c0c0c0;
  /* position: absolute; */
  width: 95%;
  margin-top: -3%;
  margin-bottom: 3%;
}

h2.acciem12.accordion-header .accordion-button {
  background: #fff !important;
}

.Competition_Matches.com_nnerdata {
  margin: 0px 0px 15px 0px;
}

.tabdata1 .nav-item {
  color: black !important;
  /* z-index: 999 !important; */
  padding: 1px;
}

/* Set the color for the tab with <BsCalendarDate /> icon to white when it's active */
.tabdata1 .nav-item.active {
  color: white !important;
}

.tabdata1 .nav-item.active svg {
  fill: white !important;
}

span.react-loading-skeleton {
  border-radius: 0px;
  height: 50px;
}

.LeaderData span.react-loading-skeleton {
  width: 97%;
  margin: auto;
  margin: 1px 5px;
}
.racetime {
  color: #fff !important;
  text-align: right !important;
}
p.cardnationality {
  padding-left: 0 !important;
}
p.cardnationality {
  padding: 3px 10px !important;
  align-items: center !important;
}
span.newsflex p {
  text-transform: uppercase !important;
}
span.newsflex marquee p {
  text-transform: capitalize !important;
}
.loginrow {
  align-items: center;
}
.topbar .notification {
  padding-right: 0;
  margin-top: 10px;
}
.accordion-body p {
  font-size: 14px !important;
}
.accordion-body div {
  font-size: 14px !important;
}

.match .accordion-button:not(.collapsed) {
  border-radius: 0px !important;
  background: #00A6A7 !important;
  color: #fff;
  border: 1px solid #fff !important;
}
.match .Ante_Post .accordion-button:not(.collapsed) div p {
  color: #fff !important;
}
.liveresult1 .resultracelength {
  display: none;
}
.resultslider_head h2:nth-child(2) {
  font-size: 22px;
  font-weight: 600;
  text-decoration: underline;
}
.liveresult1 .customers1 th {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  background: #fff;
  color: #000;
  font-size: 16px !important;
  padding-left: 15px;
}
.livebtn {
  border: unset;
  color: #fff;
  background-color: #121945;
  text-transform: uppercase;
  padding: 10px 30px;
}
.liveresult1 .customers1 td {
  color: #000;
  font-size: 14px !important;
  padding: 15px 0px;
  padding-left: 15px !important;
  background-color: #e2f4f5;
  border-bottom: 3px solid #fff;
}
.op {
  opacity: 0 !important;
}
.liveresult1 table.customers1 {
  margin: 0 !important;
}
.authhover {
  width: 16% !important;
}
.authhover > ul {
  width: 100% !important;
}
/* .authhover>ul {
  width: 24% !important;
} */
.authhover > ul > li:last-child {
  border-radius: 0 !important;
}
.menus div.dropdown-content {
  border-radius: 0 !important;
}
.liveresult1 .customers1 td:first-child {
  font-weight: 700 !important;
}
.mtfooter1 {
  margin-top: 25px;
  width: 80% !important;
  padding-left: 5%;
}
.footertext1 {
  font-size: 14px !important;
}
ul.list-unstyled.text-muted.text-bold li {
  padding-bottom: 5px !important;
}
.LinkStyle > li > p {
  font-weight: 600 !important;
}
.racecardcss1.classtyperaces1 p {
  font-size: 13px;
}
.horseheader1 {
  border-radius: 0 !important;
  border-bottom: 0 !important;
}
.modal-body .tab-content .mycardclass1 {
  background: #fff !important;
  padding: 10px 0 !important;
}
.modal-body .tab-content table.statsTable.innermodalstats th {
  padding-bottom: 15px;
}
.modal-body .tab-content table.statsTable.innermodalstats th {
  padding-left: 0% !important;
}
.modal-body .tab-content tr.statsmodalhorse1 td {
  color: #fff;
}
.row .SummaryDistance.col-sm-4 {
  width: 32% !important;
  margin: 10px 5px;
  padding: 0 !important;
}
.row.allsum {
  padding: 6px 0px;
  border-bottom: 1px solid #efefef;
  margin: 0 10px;
  align-items: center;
}
.row.allsum p {
  margin: 0 !important;
}
.dropdown.multidropdown .css-13cymwt-control {
  height: 50px !important;
  margin: 0 !important;
  padding: 0 !important;
}
.dropdown.multidropdown .css-t3ipsp-control {
  height: 50px !important;
  margin: 0 !important;
  padding: 0 !important;
}
.container.mystages.trainerStages.hotform th {
  padding-right: 0 !important;
}
.racecardpage .racepagesection {
  background: transparent !important;
}
.racecardpage .accordionHeader {
  background: #fff !important;
  border-color: #292d76 !important;
}
.racecardpage .accordion-button {
  background: #fff !important;
  border-color: #292d76 !important;
  margin-top: 5px !important;
}
.racecardpage .accordionHeader i {
  color: #000 !important;
}
.accordion-body .top p {
  font-size: 12px !important;
}

.myresults .accordionHeader {
  background: #fff !important;
  border-color: #292d76 !important;
}
.myresults .accordion-button {
  background: #fff !important;
  border-color: #292d76 !important;
  margin-top: 5px !important;
}
.myresults .posttabs p:hover {
  color: #fff !important;
}
.cardSec.active {
  background-color: #292d76 !important;
  transition: all 300ms ease-in-out;
}
.cardSec.active p {
  color: #fff !important;
  transition: all 300ms ease-in-out;
}
.searchbox INPUT {
  padding-left: 15px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: transparent !important;
}
.colorshorse {
  color: #000 !important;
}
.searchbox {
  display: flex;
  /* width: 350px; */
  align-items: center;
}
.racetime p {
  font-size: 17px !important;
  justify-content: end;
  width: 100% !important;
  display: block !important;
  margin-left: 0 !important;
}
p.ownernamestyle1 b {
  margin: 0 !important;
}
.race_trainerbreader b {
  margin: 0 !important;
}
b.breedername2 {
  margin-left: 4px !important;
}
.race_trainerbreader b:nth-child(2) {
  margin-left: 4px !important;
}
p.ownernamestyle1 b:nth-child(2) {
  margin-left: 4px !important;
}
.row.uaestats p {
  font-size: 12px;
}
.row.uaestats {
  padding: 0 !important;
  justify-content: flex-start;
}
.row.uaestats .col-sm-2 {
  padding: 0px 10px !important;
  width: auto;
  margin-right: 10px;
}
button.ShowPreviousHistory {
  display: block !important;
  width: 100%;
}
.tooltipclass {
  max-width: 9vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tooltipclass1 {
  max-width: 9vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 15px;
}
.courseSection .cardinner {
  background-color: #2086ba !important ;
}
.courseSection h4 {
  color: #fff !important;
}
.col-lg-3.col-md-6.mobiledata1 {
  padding-left: 90px !important;
}
.GenerateReports {
  color: #003bad !important;
  font-family: "Inter";
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  width: 360.35px;
  height: 246.19px;
  border-radius: 0px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/src/Webiste/assets/footer.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.GenerateReports p {
  text-align: center;
  margin-top: 0 !important;
  color: #19469d;
}
.DashboardCard {
  display: flex;
  gap: 10px !important;
  flex-wrap: wrap;
  margin: 1rem;
}
.GenerateReports {
  width: 24% !important;
}
.container-fluid.myabout {
  padding: 100px 0;
  background: url("./Webiste/assets/abtbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
}
.container-fluid.myabout .col-sm-12 {
  text-align: center;
}
.container-fluid.myabout .col-sm-12 h2 {
  color: #fff;
  position: relative;
  z-index: 99999;
  font-size: 58px;
  font-weight: 600;
}
.container-fluid.myabout:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #00000040;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.sideimg img {
  width: 100%;
}
.abtcont {
  padding: 82px 0;
}
.container.abtcont {
  max-width: 80%;
}
.container.abtcont .row {
  align-items: center;
}
.col-sm-6.contss h3 {
  font-size: 34px;
  font-weight: 700;
}
.col-sm-6.contss p {
  font-size: 15px;
  line-height: 29px;
}
.col-sm-6.mission {
  text-align: center;
  height: 300px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 48%;
  margin: 0 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 45px 50px;
}
.col-sm-6.mission p {
  font-size: 15px;
  line-height: 29px;
}
.col-sm-6.mission h3 {
  font-size: 34px;
  font-weight: 700;
}
.missions {
  padding-top: 0 !important;
}
.container-fluid.register .row {
  align-items: center;
}
.col-sm-4.meetingtyp h2 {
  text-align: left !important;
}
.col-sm-4.meetingtyp p {
  text-align: left;
}
span.Predictiontable > img {
  width: 35px !important;
  height: 35px !important;
}
.cardracesjockeycards li {
  padding: 7px !important;
}
.aboutpageheader {
  margin-top: 0px !important;
}
.searchchild {
  border: 1px solid #cbc3c3;
  border-radius: 0px 0px 20px 20px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  z-index: 9999999 !important;
  background: #fff;
  width: 250%;
  border-top: 0;
  padding-top: 20px;
  top: 77px;
  right: 1.5% !important;
  height: 300px;
  overflow-x: hidden;
  top: 100%;
}
.ant-picker ::placeholder {
  color: #000 !important;
  opacity: 0.7;
}
.registration .col-sm-6 {
  position: relative;
}
.registration .col-sm-6 img.PreviewImage {
  position: absolute;
  width: 50%;
}
.registration .col-sm-6 i {
  position: absolute;
  z-index: 9999;
  left: 39%;
  top: 100px;
  background: #fff;
  padding: 0 5px;
}
.submitbtn1.col-sm-12 {
  padding-top: 35px;
}
.registration .col-sm-6.passports img.PreviewImage {
  position: absolute;
  width: 50%;
  right: 2% !important;
}
.registration .col-sm-6.passports i {
  position: absolute;
  z-index: 9999;
  left: 40%;
  top: 100px;
  background: #fff;
  padding: 0 5px;
  right: 2%;
  left: unset;
}
.mobilehide {
  display: block !important;
}
.desktophide {
  display: none !important;
}
button#uncontrolled-tab-example-tab-ante.active {
  border-bottom: 1px solid #efefef !important;
}
.trainerstatsbtn1.headerss.accordion-item button {
  background: transparent !important;
  pointer-events: none;
  border-bottom: unset !important;
}
.headerss table.statsTable.innermodalstats {
  width: 98% !important;
  margin-left: 0px !important;
}
.headerss button {
  padding: 0 25px !important;
}
.mycss1 {
  display: flex;
}
.mycss1 td {
  width: 92px;
  padding-left: 35px !important;
  text-align: center;
}
table.statsTable.innermodalstats.mycss {
  width: 100% !important;
  margin-left: 0 !important;
  height: 40px;
  /* new code */
}

.formobilescroll1 .mycss1 td {
  width: 9.4% !important;
  padding-left: 35px !important;
  text-align: center;
}
.raceCardTable td {
  padding: 30px 0px ! IMPORTANT;
}
.raceCardTable th {
  padding: 10px 0px ! IMPORTANT;
}
.raceCarParent1.atozParent.stages tr td {
  border-top: 1px solid #92929280 !important;
  border-bottom: 1px solid #92929280 !important;
}
.raceCarParent1.atozParent.stages tr td:first-child {
  border-left: 1px solid #92929280 !important;
}
.raceCarParent1.atozParent.stages tr td:last-child {
  border-right: 1px solid #92929280 !important;
}

.CompetitionData .skeleton-p {
  width: 97%;
  margin: auto;
  margin-bottom: 20px;
}
table.tableHeaderCard {
  border-collapse: separate;
  border-spacing: 0 7px;
}
p.sk-class {
  width: 98%;
  margin: auto;
  margin-bottom: 10px;
}
.CompetitionData.CompetitionData1 {
  max-width: 98% !important;
}

.CompetitionData.CompetitionData1 .accordion-button {
  background: var(--fourth-color) !important;
  border: 1px solid var(--fourth-color) !important;
  height: 55px;
}

.CompetitionData.CompetitionData1 .compickdisplay {
  display: flex;
  color: #fff;
  margin-left: 16px;
  font-weight: 700;
}

h1.Competition122 {
  font-weight: 700;
}

.raceCardHeader1.Competition1224 {
  margin: auto;
  width: 96%;
}

/* element.style {
  background: aqua;
  background: aqua;
  position: absolute;
  height: 30px;
  width: 25px;
  top: 100%;
} */

.pre-next-btn-newone .carousel.slide a.carousel-control-prev {
  position: absolute;
  height: 30px;
  width: 25px;
  top: 100%;
}

.pre-next-btn-newone .carousel.slide a.carousel-control-next {
  position: absolute;
  height: 30px;
  width: 25px;
  top: 100%;
  margin-right: 0;
}

.pre-next-btn-newone .carousel.slide a.carousel-control-next::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent; /* Top side of the arrow */
  border-bottom: 10px solid transparent; /* Bottom side of the arrow */
  border-left: 10px solid var(--primary-color); /* Color of the arrow */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0; /* Adjust as needed for positioning */
}

/* Optional: Adjust the size and color of the arrow */
.pre-next-btn-newone .carousel.slide a.carousel-control-next {
  font-size: 0; /* Hide any default content, if present */
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  background-color: transparent; /* Set the background color as needed */
}

.pre-next-btn-newone .carousel.slide a.carousel-control-prev::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent; /* Top side of the arrow */
  border-bottom: 10px solid transparent; /* Bottom side of the arrow */
  border-right: 10px solid var(--primary-color); /* Color of the arrow */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0; /* Adjust as needed for positioning */
}

/* Optional: Adjust the size and color of the arrow */
.pre-next-btn-newone .carousel.slide a.carousel-control-prev {
  font-size: 0; /* Hide any default content, if present */
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  background-color: transparent; /* Set the background color as needed */
}

span.checkvoted {
  position: absolute;
  top: -18px;
  right: -6px;
  font-size: 25px;
  color: var(--primary-color);
}

.cardracesAccordion.data_pdf {
  height: 180px !important;
}

.tooltipclass2 {
  max-width: 10vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 15px;
}

p.news_ticker {
  color: #fff !important;
}

h2.ticker_error {
  padding: 25px 25px;
  background: var(--fourth-color);
  height: auto;
  margin-bottom: 0px;
}
img.gohoradora {
  width: 12%;
  height: 20px;
  object-fit: cover;
  margin-right: 5px;
}
.tems {
  text-align: center;
}
.winners .imgDiv {
  width: 12% !important;
}
.winners h4 {
  color: #fff !important;
}
.winners p {
  color: #fff !important;
  text-transform: capitalize !important;
}
.mycardclass1 thead {
  background: #00A6A7;
}
.mycardclass1 thead th{
  color: #fff !important;
}
.mycardclass22 thead th{
  color: #000 !important;
}
.formobilescroll1 .mycss1 {
  font-weight: bold;
}
.copyrightinner {
  text-align: center;
}
.Competition_Matches.antepostbody p {
  width: 40%;
}
.Competition_Matches {
  display: flex;
  justify-content: unset !important;
  color: #000;
}

span.itemraces_right.addtracklength {
  display: block;
}
h6.trcklength1 {
  left: -10px;
  margin: 0;
  bottom: 0;
  font-weight: 800;
  text-align: center;
  color: red;
  font-size: 12px;
}

.newdatepicker{
  width: 100% !important;
    padding: 13px 0px !important;
    border-radius: 0px !important;
    margin-top: -4px !important;
}

tr.mycss1.forownerhorse {
background-color: var(--primary-color);
}
tr.mycss1.forownerhorse td {
  color: #fff !important;
  padding: 15px;
}

.NoData-CSS {
  text-align: center;
  margin-top: 100px;
  font-size: 22px;
}
.NoData-CSS p {
  font-size: 22px;
}

.NoData-CSS p.span {
  font-weight: 600;
}