:root {
  --primary-color: var(--primary-color);
  --secondary-color: var(--secondary-color);
}  
  
  
  .resultCard {
    border-radius: 0px;
    background: #D9D9D9;
    height: min(20rem, 40rem);
    margin-top: 0px !important;
  }

  .resultCardHeader {
    background:var(--secondary-color) !important;
    border-radius: 0px;

  }

  .livedatacard {
    background:  var(--secondary-color);
    border-radius: 0px;
    margin-top: 15px;

  }
.noresultyet{
  width: 100%;
  height: 200px;
  background: var(--secondary-color);
}
  .resultCardHeader h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 5px;
    color: #FFFFFF;
    padding: 24px 4px 0px 24px;
    font-family: 'Inter';
  }

  .livedatacard1 {
    display: flex;
    height: 700px;
    text-align: center;
  }


  .live_result_data span {
    display: flex;

  }

  .result_match_name {
    font-style: normal;
    font-weight: 700;
    font-size: 20.5949px;
    line-height: 28px;
    color: #FFFFFF;
    padding: 4px 5px 0 24px;
  }

  .livedatacard {
    width: 100%;
    margin: auto;
    height: 34px;
  }

  .result_match_left {
    display: flex;
    gap: 10px;

    margin-top: -38px;
    margin-right: 21px;
  }

  .result_match_right {
    display: flex;
    gap: 17px;
    align-items: center;
    padding-right: 24px;
  }

  .result_match_right>p {
    color: #fff;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
  }

  .result_match_raceNo {
    font-style: normal;
    font-weight: 700;
    font-size: 16.5949px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 4px 5px 0 24px;

  }

  .result_match_totalRunner {
    font-style: normal;
    font-weight: 400;
    font-size: 13.5949px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 4px;
  }

  .customers1 {
    border: none;
    width: 104%;
    margin: auto;
    background: #D9D9D9;
  }

  .customers1 td,
  .customers1 th {
    border: none;
    padding: 4px 6px;
    /* text-align: center; */
  }


  /* .customers1 tr:hover {
  background: #ddd;
} */

  .customers1 tr {
    color: #000;
  }

  .customers1 th {
    padding-top: 12px;
    padding-bottom: 12px;
    /* text-align: left; */
    background: #fff;
    color: #000;
  }

  .resulttablehome {
    height: 50px;
  }

  .customers12 {
    background: #fff;
    height: 50px;
    border-radius: 0px;
    /* width: fit-content; */
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: -12px;
    align-items: end;
  }

  .customers12>span {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 12px;
  }

  .customers12>span>p {
    width: 20%;
  }

  .customers1>tr>td {
    width: 12%;
  }

  .customers1>tr>td:last-child {
    /* width: 100%; */
    text-align: center;
  }

  .customers13 {
    border: none;
    width: 100%;
    background: #D9D9D9;
  }

  .customers1>tr>td:nth-child(2) {
    width: 15rem;
    text-align: center;
  }

  .customers12>span>p:nth-child(2) {
    width: 25%;
  }

  .resultimages {
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .resultimagebox {
    width: 88px;
    height: 62px;
    left: 536px;
    top: 398px;
    background: #CFCFCF;
    border-radius: 0px;
  }

  span.resultimagebox img {
    /* display: flex; */
    /* justify-content: center !important; */
    padding: 6px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .resultimagevideo {
    width: 88px;
    height: 62px;
  }

  .resultcardposition {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 38.1065px;
    line-height: 46px;
    letter-spacing: -0.07em;

    color: #19469D;
  }

  .resultimagesRunningTime>h4 {
    line-height: 1px;
  }

  button.raceresultbtn {
    border: none;
    padding: 6px;
  }

  span.horseposition {
    font-style: normal;
    font-weight: 400;
    font-size: 38.1065px;
    line-height: 46px;
    letter-spacing: -0.07em;
    color: #19469D;
  }

  iframe.videoiframe {
    width: 100%;
    height: 343px;
  }

  .resultParent {
    display: flex;
    justify-content: space-between;
  }

  .resultData {
    display: flex;
    color: white;
    margin-left: 82px;
}

.resultData p {
  margin: 15px 7px;
  font-size: 13px;
  padding: 0 10px;
}
  .resultData h6 {
    margin: 3px 5px;
    font-size: 18px;
  }

  .resultslider_head {
    display: flex;
    /* margin-top: -2px; */
    justify-content: space-between;
    padding: 0px 25px 0px 0px;
    width: 100%;
    margin-top: 0px;
}

button.redirectBtn {
  cursor: pointer;
  float: right;
  position: absolute;
  bottom: 0%;
  right: 30px;
  font-size: 14px;
  color: #19469D;
  background: #fff;
  border-radius: 0px;
  padding: 6px 30px;
  text-transform: capitalize;
  border: none;
  transition: all ease-in-out 1s;
  z-index: 9999;
  font-weight: 600;
}

  button.redirectBtn:hover {

    color: #fff;
      background: var(--primary-color) !important; 


  }

  h2.liveResult {
    font-weight: 800;
    color: red;
    text-transform: uppercase;
    font-size: 18px;
    margin-left: 3px;
    padding: 5px 0px;
    position: relative;
    margin-bottom: 0 !important;
    display: flex;
    align-items: inherit;
}

  .livedatacard1 {
    display: flex;
    height: 112px;
    text-align: center;
    color: black;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    background: #d9d9d9;
    width: 204%;
  }

  .resultCard .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -70px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}

.liveResult span {
  margin: 0px 2px;
  font-weight: 900;
  font-size: 45px;
  animation: blink-text 1.9s linear infinite;
  position: relative;
  top: -5px;
  line-height: 0;
}

  @keyframes blink-text {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .resultData img {
    width: 60px;
    border-radius: 0px;
  }

  h6.liveResult1 {
    display: block;
    position: absolute;
    text-transform: capitalize;
    font-weight: 900;
    color: red;
    top: 15px;
    /* right: 345px; */
    font-size: 16px;
    left: 53%;
    padding: 0px;
    text-transform: uppercase;
}


  h6.liveResult1 span {

    animation: blink-text 1.9s linear infinite;
    margin: 10px;
    font-weight: 900;
    font-size: 42px;
  }

  span.Predictiontable>img {
    width: 25px;
  }
  .carousel-indicators {
    display: none;
}

.noresultyeth2{
  height: 200px;
}

button.btn-close {
  filter: invert(1) !important;
}



.noresultyet {
  height: 200px;
  position: absolute;
  background: var(--secondary-color);
  text-align: center; 
}

/* 
.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  bottom: -5px !important;
  color: #000 !important;
} */

/* span.visually-hidden::before {
  content: "«";
  display: inline-block;
  margin-left: 0.5em;
} */