@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&display=swap');

*[dir="rtl"] body,
html,
#app {

    font-family: 'monospace' !important;

}

*[dir="rtl"] p,
h5,
h1,
h2,
h3,
h4,
h6,
th,
td,
button {
    font-family: 'monospace' !important;

}

*[dir="rtl"] .dropdown-content {
    border-radius: 0px 0px 0px 50px;
    left: -150px;
}

*[dir="rtl"] .menuNav p {
    font-family: 'monospace';
    font-size: 19px !important;
    font-weight: 600 !important;
}

*[dir="rtl"] .statsTable th {
    text-align: left !important;

}

*[dir="rtl"] h6.liveResult1 {
    right: 0px;
    font-family: 'Noto Sans Arabic', sans-serif;

}

*[dir="rtl"] .CompetitionHeader h2 {
    font-family: 'Noto Sans Arabic', sans-serif;

}

*[dir="rtl"] .Competition_Matches {
    font-family: 'Noto Sans Arabic', sans-serif;
}

*[dir="rtl"] .bannerText1 {
    font-family: 'Noto Sans Arabic', sans-serif;
}

*[dir="rtl"] button#uncontrolled-tab-example-tab-home {
    font-family: 'Noto Sans Arabic', sans-serif;
}

*[dir="rtl"] button#uncontrolled-tab-example-tab-ante {
    font-family: 'Noto Sans Arabic', sans-serif;
}

*[dir="rtl"] .Currentpostheader h2 {
    font-family: 'Noto Sans Arabic', sans-serif;

}

*[dir="rtl"] .historyhorseth .rb {

    border-radius: 0px !important;
}

*[dir="rtl"] .historyhorseth .lb {
    border-radius: 0px !important;
}

*[dir="rtl"] .filterbtn {

    right: 86%;
}

*[dir="rtl"] span.racecardcolor1 {

    left: initial !important;
}

*[dir="rtl"] .racenameflex {
    margin-right: 50px !important;
}

*[dir="rtl"] p.itemtime {
    margin-right: 50px !important;
}

*[dir="rtl"] .pleaseWait {
    right: initial !important;
}

*[dir="rtl"] span.itemraces_center {
    font-size: 12px;
}

*[dir="rtl"] .itemraces_right {
    margin: 0px -15px 0px 0px;
}

*[dir="rtl"] .itemraces>p {
    font-size: 17px;
}


*[dir="rtl"] .uaecareer>p {
    font-size: 11px;

}

*[dir="rtl"] .card-header {
    padding: 5px 15px;
}


*[dir="rtl"] .Trophydata_P>h6 {
    font-size: 12px;

}

*[dir="rtl"] .race_trainerbreader>span>p>b {
    margin-right: 10px;

}

*[dir="rtl"] .noFoundHeader {
    letter-spacing: 0px;
}


*[dir="rtl"] .singleracecard {
    padding: 0 0px;


}

*[dir="rtl"] .searchchild {
    left: -46%;
}

*[dir="rtl"] .cardnationality {
    padding: 0px 10px 0px 0px;
    color: #fff;
    width: 100%;
}


*[dir="rtl"] p.searchname {
    line-height: 22px;
    font-size: 15px;
}

*[dir="rtl"] .registrationform>form>h5 {
    text-align: right;
    margin-left: 0px !important;
    margin-right: 20px;

}

*[dir="rtl"] .registrationform>form>p {
    text-align: right;
    margin-left: 0px !important;
    margin-right: 20px;
    font-size: 14px;

}

*[dir="rtl"] .showIcon {
    top: 20%;
    right: 92%;
    left: initial;
}

*[dir="rtl"] .footertext1 {
    font-size: 16px!important;
}


*[dir="rtl"] .containe.otpModal {
    direction: ltr !important;
}


*[dir="rtl"] .raceCarParent.stages.stages123.tens .statsTable {
    width: 98.5% !important;
}

*[dir="rtl"] label.PassportPicturelabel {
    color: #fff;
    font-size: 18px;
    padding-right: 24px;
}
*[dir="rtl"].form.forgetclass {
    text-align: right;
}
*[dir="rtl"] .register select {
    direction: ltr;
}

*[dir="rtl"] .form-group>input::placeholder {
    font-size: 17px;
}

*[dir="rtl"] .raceCarParent .customers td.rb {
    border-radius: 0px !important;
}


*[dir="rtl"] .menuNav .dropdown-content p {
    text-align: right !important;
}


*[dir="rtl"] .raceCarParent .customers td.lb {
    border-radius: 0px !important;
}

*[dir="rtl"] div#selectNow2 {

    font-size: 15px;
    color: #3f3f3f;
    font-weight: 600;
}

*[dir="rtl"] div#selectNow {

    font-size: 15px;
    color: #3f3f3f;
    font-weight: 600;
}



*[dir="rtl"] p.resetrool {

    font-size: 12px;

}

table {
    filter: blur(0) !important;
    -webkit-filter: blur(0) !important;
    /* Safari 6.0 - 9.0 */
}

@media (max-width: 1900px) and (min-width: 1600px) {
    *[dir="rtl"] body {
        overflow-x: hidden !important;
    }

    /* *[dir="rtl"] button#uncontrolled-tab-example-tab-ante {
        width: 117px;
    } */

    *[dir="rtl"] button#uncontrolled-tab-example-tab-home {
        width: 115px;
    }

    *[dir="rtl"] .playerPredictTable {
        min-height: 180px;
    }
}