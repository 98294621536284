.aboutpage {

  font-family: "Inter";

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* Apply your desired styles here */
  background-color: #fff !important; /* Set the background color */
  color: #333 !important; /* Set the text color */
}
.bannerText2 {
  /* position: absolute !important; */
}

.aboutpageheader {
  height: 330px;
  width: 100%;
  background-image: url("../../../assets/header.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}


.bgcolorprint{
  background-color: #19469D;
}
.printcard1 {
  width: 100% !important;
}

.aboutpageheader h2 {
  color: #fff;
  font-weight: 600;
}

.aboutpagesection {
  height: fit-content;

  width: 80%;
  display: flex;
  flex-direction: row;
  min-height: 600px;
  margin: 0 auto;
  padding: 40px;
}

.bannerAbout {
  background: var(--secondary-color) !important; 
  border-radius: 20px;
  height: min(20rem, 40rem);
  width: 80%;
  margin: 0px auto;
  margin-top: 80px;
}

.aboutCard>img {
  float: right;
  margin: 2rem;
  width: 18%;
  filter: brightness(0) invert(1);
}

.middlebanner {
  height: 734px;
  width: 100%;
  background-image: url("../../../assets/aboutmiddlebanner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 100px;
}
body{
overflow-x: hidden !important;
}

.middlebanner section {
  width: 31.7%;
  text-align: center;
  height: 500px;
  background: white;
  position: relative;
  top: 25%;
  left: 12.1%;
  padding: 120px 60px;
}

.middlebanner section h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  color: #19469d;
  margin: 27px 0px;
}

.imgDivs h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  color: #fff;
  margin: 27px 0px;
}

.middlebanner section p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;

  color: #000000;
}

.aboutData {
  width: 60%;
  text-align: left;
  padding: 100px 76px;
}

.aboutData p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 153.52%;
}

.aboutData h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;

  color: #19469d;
  margin: 38px 0px;
}

.imgDivs {
  background-image: url("../../../assets/About2.jpg");
  background-repeat: no-repeat;
  z-index: 999;
  text-align: center;
  width: 41.5%;
  background-size: cover;
  background-position: center;
}

.imgDivs p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  padding: 0 30px;
  color: #000000;
}

.aboutpagesection2 {
  height: fit-content;

  display: flex;
  flex-direction: row;
  min-height: 600px;
  padding: 100px 260px;
}

.aboutData2 {
  width: 50%;
  padding-top: 100px;
  margin-left: 100px;
}

.aboutData2 p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 153.52%;
}

.aboutData2 h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  color: #19469d;
}

.policy {
  width: 80%;
  margin: 0 auto;
  padding: 100px 0;
}

.policy p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 199.02%;
}

.blogMain {
  width: 80%;
  margin: 80px auto;
}

.blogMain h4 {


  color: #19469D;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;

}

.blogBanner img {
  width: 100%;

}

.blogDatas {
  display: flex;
  margin: 0 auto;
  margin-top: 60px;
}

.blogDatas img {
  width: 190%;
  height: 263px;
}


.blogDesc {
  padding: 30px 40px;
  background: gainsboro;
  width: 50%;

}

.blogDesc p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
}

.aboutpagesectioncontent {
  height: 358px;
  /* left: 18px; */
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.sponsorslider {
  width: 80%;
  margin: auto;
}

.abouthead {
  padding: 7px 7px 0px 0px !important;
  margin: 10px 0px -18px !important;
}

.RaceAndHorseModelDataCSSFlexPdf{
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-height: 650rem !important;
  min-height: 55rem !important;
  overflow: auto;
}

.imageSection img {
  width: 100%;
  height: 100%;
}

.form-control.is-valid, .was-validated .form-control:valid {
  border: 1px solid #ced4da;
background-color: #fff !important; 
background-image:none;
}