/* .auth{
    width: 15%;
    height: 96px;
    top: 0px;
    background: #FFFFFF;
    border-radius: 0px 0px 0px 20px;
    
    
} */

:root {
  --primary-color: var(--primary-color);
  --secondary-color: var(--secondary-color);
}

.buttonLogout {
  align-items: center;
  border: none;
  margin: 30px;
}

.auth1 {
  margin-top: 0px;
  color: #000;
}

.buttonSubscribe {
  text-decoration: none;
  color: #ffffff;
  /* display: block; */
  padding:10px 50px;
  cursor: pointer;
  width: 83px !important;
  margin: 0px 35px;
  background-color: var(--primary-color);
}


a.buttonSubscribe:hover{
  color: #ffffff !important;
}
a.buttonLogin:hover{
  color: #ffffff !important;
}
.buttonLogin {
  text-decoration: none;
  color: #ffffff;
  padding: 10px 70px;
    cursor: pointer;
    background-color: var(--primary-color);
}

.userprofile {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-inline: auto;
  padding: 0px;
}

.profilepic>img {
  width: 5rem;
  height: 65px;
  border-radius: 0px;
}

.profilename p {
  margin-bottom: 0 !important;
}

.pointuser {
  color: #19469d;
  font-style: normal;
  font-weight: 700;
  font-size: 18.6726px;
  line-height: 23px;
}

.username {
  color: var(--primary-color);
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14.6133px;
  line-height: 18px;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 185px;
  margin-top: 120px;
  padding-top: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.userprofile:hover .dropdown-content {
  display: block;
}

.authhover {
  height: fit-content;
  width: 270px;
  visibility: hidden;
  z-index: 999;
  background: transparent;
  transition-duration: 0.5s;
  position: absolute;
  margin-top: -10;
  transition: 1s ease-in-out all;
  padding-left: 0;
  /* border-radius: 0px 0px 0 20px; */
  height: 255px;
}

.userprofile:hover+.authhover {
  visibility: visible;
}

.authhover>ul {
  width: 255px;
  margin-left: -30px;
}

.authhover>ul>li {
  border-bottom: 0.5px solid rgb(125, 122, 122);
  list-style: none;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14.6133px;
  line-height: 43px;
}

.authhover>ul>li:last-child {
  background: #19469d;
  border-radius: 0px 0px 0px 20px;
  color: #fff;
  height: 50px;
  margin: 0 - 39px !important;
  width: 225px;
}

.authhover:hover {
  visibility: visible;
}

.fa-search {
  cursor: pointer;
}

.SearchDataPanel {
  position: fixed;
  top: 18.7%;
  z-index: 9999;
  width: 19%;
  border-radius: 20px;
  max-height: 100px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  color: rgb(49, 48, 48);
}

.row.desktopheader {
  width: 100%;
}

.row.desktopheader {
  width: 100%;
  align-items: baseline;
}

button#uncontrolled-tab-example-tab-contact svg {
  color: #000 !important;
}

button#uncontrolled-tab-example-tab-contact:active svg{
  color: #000;
}

/* .col-sm-6 input {
  background: #e2f1f9 !important;
} */
.ant-picker.css-dev-only-do-not-override-1wazalj {
  padding: 12px 10px;
  font-size: 14px !important;
  width: 100%;
  border-radius: 0px;
}