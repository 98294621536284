.header {
  height: 96px;
  width: 82%;
  left: 0px;
  top: 0px;
     /* background: var(--primary-color) !important;   border-radius: 0px 0px 50px 0px; */
}

.EmailForget {
  width: 100% !important;
}

.EmailForgetBtn {
  margin: 25px;
}

.ForgetPassword p {
  text-align: left;
  padding: 20px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  padding-left: 2px;
}

.highlight {
  color: rgb(9, 255, 0);
    background: var(--primary-color) !important; 
}

.blue {
  color: red;
}
.horizontal-flip {
  transform: scaleX(-1);
}
.pink {
  color: rgb(60, 92, 50);
}

.header1 {
  height: 96px;
  width: 100%;
  left: 0px;
  top: 0px;
     background: var(--primary-color) !important;   border-radius: 0px 0px 0px 0px;
}

.headerlayout {
  display: flex;
}

.menuNav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: auto;
}

.logoclass {
  margin-top: 15px;
  height: 50px;
}

.menuNav p {
  color: #fff;
  align-items: center;
  margin-top: 32px;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 300;
  font-size: 16px;
}

.tracker>img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.notification>img {
  width: 25px;
  height: 25px;
}

.languagedropdown {
  border: none;
  width: 45px;
  height: 32px;
  margin: 5px 28px 5px 0px;
  background: #d9d9d9;
  border-radius: 9px;
  color: rgb(74, 72, 72);
}

.languagedropdown>select:active {
  border: none;
}

button#dropdownMenuButton1 {
  background: rgb(197, 198, 198);
  text-decoration: none;
  color: #fff;
}

/* .TooltipTracker{
    background: #19469D !important;
} */

button#dropdownMenuButton2 {
  text-decoration: none;
  color: #fff;
  margin-top: -20px;
}

button#dropdownMenuButton2:active {
  border: none;
}

.dropbtn {
  color: white;

  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
     background: var(--primary-color) !important;   position: absolute;
  border-radius: 0px 0px 20px 0px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 0px !important;
  padding-top: 0px !important;
  left: 0px;
  top: 18px;
}

.dropdown-content a {
  color: #fff;
  padding: 0px !important;
  text-decoration: none;
  display: block;
  margin-top: -30px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdowntag {
  font-size: 12px;
  color: #fff !important;
}

.dropdown-content p {
  color: #fff !important;
  font-size: 15px;
}

.dropdown svg {
  font-size: 12px;
}

p.searchname:hover {
  color:  #19469d ;
  text-decoration: underline;
  font-weight: 500;
}
.searchbox > input::placeholder{
  font-size: 10px;
}
.searchhorsedata1 {
  font-size: 18px;
  font-weight: 600;
  color: #646161;
}
p.active {
  cursor: pointer;
  background: var(--secondary-color) !important;
  border-radius: 0px;
  font-size: 15px;
}

/* new */

.topbar .col-sm-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topbar .searchparent {
  padding-right: 24px;
}
.topbar .dropdown{
  padding-right: 24px;
  margin-top: 0px;
}
.topbar .notification{
  padding-right: 24px;
  margin-top: 10px;
}
.topbar .tracker{
  padding-right: 24px;
  margin-top: 0px;
}

.row.hassam .col-sm-8 {
  padding-right: 0px !important;
}

.row.hassam .col-sm-4 {
  padding-right: 0px !important;
}

.col-sm-10.menus {
  padding: 0px;
  margin-left: -3%;
}