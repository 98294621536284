  .registrationform {
  width: 90%;
  margin-inline: auto;
  margin-top: 18px;
  height: max-content;
  margin-bottom: 100px;
}
.pass-regex li {
  color: white;
  list-style: none;
}
.registrationform > form {
  width: 500px;
  margin: auto;
  background: var(--primary-color) !important;
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.form-group {
  padding: 15px;
}

.form-group > input {
  width: 100%;
  height: 40px;
  border-radius: 0px;
  border: none;
  background: #fff;
  padding: 8px;
}

.buttonRegister {
  border: none;
  border-radius: 0px;
  height: 50px;
  width: 90%;
  margin: 25px;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
}

.forgetclass {
  /* border: 1px solid #747272; */
  height: 60px;
  border-radius: 0px;
}

select#exampleFormControlSelect1 {
  border: transparent !important;
}

.PreviewImage {
  height: 125px;
  width: 125px;
  padding: 20px;
}

.form-group.PassportPicturefile > input {
  width: 160px;
}

:focus-visible {
  outline: none !important;
}

label.PassportPicturelabel {
  color: #fff;
  font-size: 14px;
  padding-left: 18px;
}

.form-group > span {
  font-size: 12px;
  color: #c30808;
  font-weight: 600;
  padding-left: 3px;
}

.showIcon {
  position: relative;
  font-size: 20px !important;
  cursor: pointer;
  color: #747272 !important;
  top: 20%;
  bottom: 35px;
  left: 93%;
}

.showIcon:hover {
  color: black !important;
  transform: scale(1.8);
}

.ForgetPassword {
  display: flex;
  justify-content: space-between;
}

.otpModal {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otpModal input {
  width: 3rem;
  margin-right: 0.5rem;
  height: 4rem;
  font-size: 20px;
  text-align: center;
}

.otpModalContainer p {
  text-align: center;
  margin-top: 10px;
}

p.errormessageotp {
  text-align: center !important;
  margin-top: 20px;
}
input.ant-input.css-dev-only-do-not-override-1wazalj {
  height: 40px !important;
}

input.ant-input.css-dev-only-do-not-override-1wazalj {
  height: 40px !important;
  border-radius: 0px;
}
.form-group.aaaaaaa {
  margin-top: -55px !important;
  margin-bottom: -45px !important;
}

.css-13cymwt-control {
  BORDER: NONE;
  width: 100% !important;
  margin-top: 7px;
}
.dropdown.multidropdown.css-b62m3t-container {
  width: 100%;
  border: none;
}
.css-qbdosj-Input {
  padding-bottom: 20px !important;
}

.css-13cymwt-control{
  border: none ;
  height: 40px !important;
  border-color: transparent !important;
  box-shadow: none !important;
} 
.css-13cymwt-control:hover {
  border-color: transparent !important;
  box-shadow: none !important;

}
.css-t3ipsp-control{
  border-color: transparent !important;
  box-shadow: none !important;
  margin-top: 10px;
}

.css-1fdsijx-ValueContainer {
  height: 45px !important;
}

.error-message {
  color: red;
  font-size: 12px;
  padding: 4px;
}


.error-message1 {
  background: red;
  color: #fff;
  padding: 1px 10px;
  font-size: 10px;
  width: 75%;
  border-radius: 0px;
  margin-top: 5px;
}

.registration img {
  width: 40%;
  border-radius: 0px;
  margin: 0px -18px;
}

.css-1dimb5e-singleValue {
  margin-top: -8px !important;
}

.imgplaceholder1 {
  display: flex;
  flex-direction: column;
}

.imgplaceholder1 label.Multipleownerlabel {
  padding: 5px 40px !important;
  font-size: 14px;
  color: #675c5c;
}

.row.datarow {
  margin: 25px;
}

button.btn123.btn.btn-primary {
  width: 120px;
}
.row.datarow {
  margin: 35px 30px;
}

label.labelnew1.form-label {
  color: #534d4d;
}


b.mx-1 {
  padding-left: 2px !important;
}


/* .forget p {
  cursor: pointer;
  text-transform: uppercase !important;
} */


/* .register h5 {
  text-transform: uppercase !important;
} */
.container-fluid.register row {
  align-items: center !important;
  top: 0% !important;
}