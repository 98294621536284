:root {
  --primary-color: var(--primary-color);
  --secondary-color: var(--secondary-color);
}

#customers {

  border-collapse: collapse;
  width: 100%;
}

#customers>tr {
  border: none;
  text-align: center;
}

#customers>tr>th {

  padding: 20px 16px !important;
  text-align: center;
      background: var(--primary-color) !important; 
  color: white;
  border-radius: 0px;
}

#customers>tr>td {

  border: 1px solid #ddd;
  padding: 8px;
}

#customers>tr>th {

  border: 1px solid #ddd;
  padding: 8px;
}

#customers>tr:hover {
  background: #ddd;
}



.horseTable {
  width: 90%;
  margin-inline: auto;
}

/* Horse form data */

.horseheader1 {
  padding: 1rem;
  box-sizing: border-box;
  /* width: 688px; */
  height: fit-content;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  display: flex;
}

.horseshirt {
  margin: 10px;
}

.horsecardtop {
  box-sizing: border-box;
  width: 60px;
  text-align: center;
  height: 98px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 8.07692px;
}

.horsecenterdata {
  flex: 3 1;
  margin-left: 20px;
}

.horseimage {
  box-sizing: border-box;
  width: 157px;
  height: 131px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.horsebody {
    background: var(--primary-color) !important; 
  height: fit-content;
}

.horsebodyitem {
  background: var(--secondary-color) !important; 
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  padding: 10px;
}

.horsebodyitem1 {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  padding: 10px;
}

.horsehistorylist {
  width: 688px;
  height: 50px;
}

.horses1 {
  margin-left: 20px;
}

.Pedigree {
  height: fit-content;
  background: #fff;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.sire {
  box-sizing: border-box;
  width: 229px;
  height: 160px;
  background: #078BE6;
  border: 1px solid #D9D9D9;
  border-radius: 0px;
}

.dam {
  box-sizing: border-box;
  width: 229px;
  height: 160px;
  background: #CD4CCF;
  border: 1px solid #D9D9D9;
  border-radius: 0px;
}

.gsire {
  width: 230px;
  height: 80px;
  background: #078BE6;
  border: 1px solid #D9D9D9;
  border-radius: 0px;
}

.gdam {
  width: 230px;
  height: 80px;
  background: #CD4CCF;
  border: 1px solid #D9D9D9;
  border-radius: 0px;
}

.ggsire {
  box-sizing: border-box;
  width: 229px;
  height: 40px;
  background: #078BE6;
  border: 1px solid #D9D9D9;
  border-radius: 0px;
}

.ggdam {
  box-sizing: border-box;
  width: 229px;
  height: 40px;
  background: #CD4CCF;
  border: 1px solid #D9D9D9;
  border-radius: 0px;
}

.horseTable>input {
  margin: 20px;
  float: right;
}

.horsebodyitemfirst>p {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #FFFFFF;
  padding: 6px;
}

.horseimage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.horserecord {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding-top: 10px;
}

.horserecord>h6 {
  color: #000;
}

.customers6 {
  margin-left: 6px;
  width: 100%;
}

.customers6 td,
.customers6 th {
  border: transparent;
  padding: 8px;
  background: #5EC40F;
  color: #fff;
}

.RaceNav.HorseNav {
    background: var(--primary-color) !important; 
}

.customers6 th {
  padding-top: 20px;

  text-align: center;
  background: transparent;
  color: #000;
  font-size: 15px;
}

.popupheader {
      background: var(--primary-color) !important; 
  color: #fff;
  font-family: inter;
}

.horsedetailimage {
  display: flex;
}

.horsedetailimage img {
  width: 30px;
  height: 30px;
}

.horseshirtimage {
  width: 100px;
}

.horsefoal {
  font-weight: 400;
  font-size: 32.7337px;
  line-height: 10px;
  /* letter-spacing: -0.1em; */
  color: #000000;
}


p.racestatusclasstime {
  font-size: 12px !important;
}

.pedigreeclass {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.statstabheader {
  height: 210px;
      background: var(--primary-color) !important; 
  border-radius: 0px;
  margin-top: 2px;
  color: #434343;
  overflow-y: auto;
}

.Statisticstabbtn {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2px;
}

.Statisticstabbtn>select {
  padding: 3px;
  margin: 16px 3px 0px;
  border: none;
  border-radius: 0px;
  color: #4a4a4a;
}

.statstabtablehead {
  background: #fff;
  height: 50px;
  color: #4a4a4a;
  text-align: center;
}

.statsTablewidth {
  width: 100% !important;
}

.horseModalTable thead th {

  color: #ffff;
}

.horseModalTable thead th {
  color: #000 !important;
  text-transform: uppercase;
}

.horseModalTable tbody td {
  color: #000;
}


.filterbtndetailpage {
  position: absolute;
  margin-top: 35px;
  right: 25px;
  /* margin-left: 24px; */
  font-size: 16px;
  color: #fff;
  padding: 0px 10px;
  background: transparent;
  border: none;
}

.showfilterclass1 {
  display: flex;
  justify-content: space-between;
  margin-top: 76px;
  width: 86%;
  position: absolute;
  left: 55px;
  font-size: 14px;
  /* bottom: 264px; */
  /* margin-bottom: 9px; */
  /* padding: 74px; */
}

.horseModalTable .lb {

  border-radius: 25px 0px 0px 25px;

}

.horseModalTable .rb {

  border-radius: 0px 25px 25px 0px;

}

h2.Pedigreeclass2 {
  text-align: center;
  padding: 25px;
  color: #000;
  font-size: 18px;
}

table.tableHeaderCard td {
  padding: 2px;
}


/* Onwer and Jockey */
.jockeyname1{
  padding-top: 30px;
}

/* .modal-content {
 
  width: 135% !important;
  margin-left: -17%;
  min-height: 98vh !important;

} */

.modal-content {
  /* width: 135% !important; */
  /* margin-left: -17%; */
  /* min-height: 98vh !important; */
  flex: 0 0 1000px;
  max-width: 1000px;
}


.SummaryDistance img {
  width: 100% !important;
  height: 85% !important;
}

.tooltipclass1 {
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* padding-top: 25px; */
  /* line-height: 3px; */
}

#tooltip-disabled{
background: #19469D !important;
}

span.ownerSection {
  height: 25px;
  margin: 0px 8px;
}