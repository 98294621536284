.column11 {
  float: left;
  width: 25%;
  padding: 0 10px;
  margin-top: 20px;
}

.row11 {
  margin: 10px;
}

.row11:after {
  content: "";
  display: table;
  clear: both;
}
.raceCourseCard {
  border-radius: 0px;
  background: #19469d;
  height: 600px;
  width: 100%;
  border-radius: 0px;
  padding: 35px 0;
}
.courseSection {
  margin: 30px 10px;
  height: 100%;
}
.courseSection .cardinner {
  background: #efefef;
  height: 100%;
  margin: 0 auto;
  width: 87%;
  border-radius: 0px;
}
.courseSection .cardinner h4 {
  color: #000;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.cardinner .cardheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 22px;
}
.cardinner .cardSec {
  border-radius: 0px;
  background: #fff;
  cursor: pointer;
  padding: 0px 20px;
  margin: 10px 5px;
}
.cardinner .cardSec p {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 20px 0;
}
.raceCourseBanner {
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.imageSection {
  width: 100%;
  height: 100%;
  background: #e1e1e1;
  margin: 31px 0px;
}
.raceCourseTxt {
  padding: 135px 65px 60px 20px;
}
.raceCourseTxt h3 {
  color: #19469d;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* .raceCourseTxt p {
  color: #000;
  text-overflow: ellipsis;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
} */
.bgBanner.row {
  background-image: url("../../../assets/racecoursebg.png");

  background-size: cover;
  background-repeat: no-repeat;
  background-position: inherit;
}
.COMMITTEE {
  background: #fff;
  margin-left: 15%;
  margin-top: 10%;
  height: 600px;
  padding-top: 63px !important;
  padding: 2px 80px;
  width: 75%;
  margin-bottom: 1%;
}
.COMMITTEE h4 {
  color: #19469d;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 22px 32px;
}
.COMMITTEE ul {
  list-style: none;
  color: #000;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
}
.COMMITTEE ul li:last-child {
  padding-top: 100px;
}
.listSection {
  padding: 50px 15px;
  padding-bottom: 0 !important;
}

@media screen and (max-width: 600px) {
  .column11 {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .COMMITTEE {
    padding: 0 !important;
    margin: 0 auto;
    margin-top: 74px;
    width: 80%;
  }
  .raceCourseBanner {
    height: auto;
    margin: 20px 0;
  }
  .raceCourseTxt {
    padding: 0 !important;
  }
  .COMMITTEE ul {
    font-size: 14px !important;
  }
  .raceCourseTxt p {
    font-size: 14px !important;
  }
}

.card11 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background: #f1f1f1;
}
.formobile1 {
  display: none;
}
@media screen and (max-width: 1540px) {
  .cardinner .cardSec p {
    font-size: 15px !important;
  }
  .raceCourseTxt p {
    font-size: 16px !important;
  }
  .COMMITTEE ul {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1366px) {
  .cardinner .cardSec p {
    font-size: 14px !important;
  }
}
