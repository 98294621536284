:root {
    --primary-color: var(--primary-color);
    --secondary-color: var(--secondary-color);
  }

@media (max-width: 1900px) and (min-width: 1600px) {
    button.slick-arrow.slick-next {
        width: 25px;
        height: 124px;
        background: #D9D9D9;
        border-radius: 10px;
        margin-right: 1px;
    }

    .rightlayoutinner {

        gap: 5px !important;
    }
    .registerbtn {
        margin-top: 0px;
    }
    .stages h1 {
        font-size: 2rem;
    }

    .showfilterclass>p {
        font-size: 13px;
    }

    button.showMore {
        padding: 10px 24px !important;
        font-size: 12px !important;
    }

    .aboutpage h3 {
        font-size: 30px;
    }

    .aboutpage p {
        font-size: 12px;
    }

    button.slick-arrow.slick-prev {
        width: 25px;
        height: 124px;
        background: #D9D9D9;
        border-radius: 10px;
        margin-right: 1px;
    }

    .customers12>span>p {
        font-size: 14px;
    }

    .customers12>span {
        width: 100%;
        margin: 0px 41px -3px 14px;
        /* margin: auto; */
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-top: 12px;
    }
    .customers1>tr>td:nth-child(3) {
        padding-left: 16px;
        width: 6rem;
        text-align: center;
    }

    .customers1>tr>td:nth-child(2) {
        padding-left: 0px;
        width: 14rem;
        text-align: center;
    }

    .customers12>span>:nth-child(3) {
        font-size: 14px;
        padding-left: 7px;
    }

    .customers12>span>:nth-child(4) {
        font-size: 14px;
        padding-left: 7px;
        width: 11rem;
    }

    .customers2 th {
        font-size: 11px;
    }

    .customers2 th {

        width: 11.5%;

    }

    .middlebanner section {
        left: 12.3% !important;
    }

    .bannerText2 {
        font-size: 2rem !important;
    }

}

@media (max-width: 1620px) and (min-width: 1490px) {
    button.slick-arrow.slick-next {
        width: 25px;
        height: 124px;
        background: #D9D9D9;
        border-radius: 10px;
        /* margin-right: 10px; */
    }

    span.itemraces_right p {
        padding: 4px;
    }

    .middlebanner section {
        left: 12.5% !important;
    }

    .menuNav p {
        font-size: 14px;
    }

    button.slick-arrow.slick-prev {
        width: 25px;
        height: 124px;
        background: #D9D9D9;
        border-radius: 10px;
        margin-right: 12px;
    }

    .first-txt {
        padding: 55px;
        top: 25%;
        font-size: 38.4118px;
        line-height: 48px;
    }

    .slideimg1 {
        width: 100%;
        height: 52vh;
    }

    .sponsor {
        height: 52vh;
        flex: 1;
        margin-top: 19px;
    }

    .racestatuscolor {
        margin: 0 13px;
    }

    .sponsor img {
        width: 100%;
        height: 100%;
    }

    .sponsorimg {
        object-fit: cover;
        position: relative;
        height: 52vh;
        color: #fff;
    }

    .itemtime {
        font-size: 19.595px;
        margin-bottom: 26px;

    }

    .customers1>tr>td {
        width: 10%;
    }




    .customers1>tr>td:nth-child(2) {
        padding-left: 27px;
        width: 11rem;
        text-align: center;
    }

    .customers1>tr>td {
        width: 17%;
    }

    .customers12>span>p {
        font-size: 13px;
        margin: 5px auto;
    }

    .customers12>span :nth-child(3) {
        font-size: 13px;
        margin: 5px auto;
        width: 7.2rem;
    }

    .uaecareer>p {
        font-size: 9px;

    }

    .customers td,
    .customers th {
        padding: 17px 2px;
        font-size: 12px;
        width: 124px !important;
    }

    .customers2 th {
        text-align: left;
        width: 6.7%;
        font-size: 8px;
        line-height: 10px;
    }

    .footertext1 {
        font-size: 13.613px;

    }

    .slider {
        height: 460px;
        flex: 3 1;
    }

    button#uncontrolled-tab-example-tab-home {
        width: 100%;
    }

    button#uncontrolled-tab-example-tab-ante {
        width: 100%;
    }
}

@media (max-width: 1490px) and (min-width: 1320px) {

    .customers1 th {
        border: none;
        padding: 12px 11px !important;
    }

    span.itemraces_right p {
        padding: 4px !important;
    }

    .middlebanner section {
        left: 12.9% !important;
        width: 36.3%;

    }

    .aboutpage p {
        font-size: 16px !important;
    }

    .HomeRightSection {
        min-height: 500px;
    }

    .newpost {
        min-height: 500px;
    }

    button.slick-arrow.slick-next {
        width: 25px;
        height: 124px;
        margin-right: 10px;
    }

    button.slick-arrow.slick-prev {
        width: 25px;
        height: 124px;
        margin-right: 10px;
    }

    .first-txt {
        padding: 55px;
        top: 25%;
        font-size: 38.4118px;
        line-height: 48px;
    }

    .slideimg1 {
        width: 100%;
        height: 412px;
    }

    .sponsor {
        height: 420px;
        margin-top: 19px;
    }

    .sponsor img {
        width: 100%;
        height: 100%;
    }

    .sponsorimg {
        height: 420px;

    }

    .slider {
        height: 420px;
        flex: 3 1;
    }

    .adstitlepos {
        font-weight: 400;
        position: absolute;
        padding: 55px;
        top: 5%;
        left: 15%;
        height: 120px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 30.4118px;
        line-height: 30px;
        color: #C4C4C4;
    }

    .itemraces_center>h5 {
        font-size: 15px;
        line-height: 24px;
    }

    /* button#uncontrolled-tab-example-tab-home {
        width: 93px;
        font-size: 17px;

    } */

    /* button#uncontrolled-tab-example-tab-contact {
        width: 60px;
    } */

    /* button#uncontrolled-tab-example-tab-ante {
        width: 116px;
        font-size: 17px;
    } */

    .singleracecard {
        width: 210px !important;
        height: 124px;
    }

    .resultCard {
        height: min(24rem, 40rem);
    }

    .PredictionFlex {
        width: 100%;
        align-items: baseline;
        margin-inline: auto;
    }

    .MatchDataPredict h3 {
        font-size: 20.5949px;
        margin: 20px 0 0 18px;
    }

    .presictData h6 {
        font-size: 15px;
        line-height: 15px;
        margin: 20px 0 0 20px;
    }

    .racetime {
        font-size: 17.4333px;
        margin-left: 4px;
        color: #FF9900;
        width: 66px;
        height: 30px;
    }

    .customers12>span {
        margin-top: 0px;
        font-size: 14px;
    }

    .clubname {
        padding: 1.5rem 0rem 0rem 1rem;
    }

    .owner {
        padding-left: 1rem;
    }

    .AddingtonStyle img {
        height: 90.9px;
    }

    .Addingtonname {
        font-size: 15px;
    }

    .Addingtontimer {
        font-size: 16.4118px;
    }

    .racedisc {
        height: fit-content;
    }

    .innerCardBlogs {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        overflow: hidden;
        height: 435px;
    }

    .CompetitionHeader h2 {

        font-size: clamp(0.1rem, 1.5vw, 2rem);
        padding: 8px 0;
    }

    .CompetitionHeader img {
        height: 40px;
    }

    .Copyrights {

        font-size: 11px;
    }

    .slick-list {
        margin-left: 22px !important;
    }

    .react-calendar,
    .react-calendar *,
    .react-calendar ::before,
    .react-calendar ::after {
        font-size: 10px !important;
    }

    .Currentpostdiv {
        width: 93%;
    }

    .uaecareer>p {
        font-size: 8px;

    }

    .customers2 th {
        padding-top: 18px;
        padding-bottom: 15px;
        text-align: left;
        color: #fff;
        width: 9.7%;
        font-size: 9px;
        line-height: 15px;
    }
}

@media (max-width: 1320px) and (min-width: 1024px) {

    .posttabs p {
        padding: 2px !important;
        transition: all 200ms ease-in-out;
        font-size: 13px;
    }

    span.itemraces_right p {
        padding: 4px !important;
    }

    .aboutData {
        width: 96%;
        margin-right: 64px;
        padding-top: 100px;
    }

    .imgDiv img {
        width: 415px;
        height: 509px;
        margin-top: 34px;
    }

    .middlebanner section {

        width: 41%;

        left: 13.2%;
    }

    .middlebanner section p {

        font-size: 15px;

    }

    .aboutData2 p {

        font-size: 15px;

    }



    .aboutData p {

        font-size: 15px;

    }

    .HomeRightSection {
        min-height: 500px;
    }

    .newpost {
        min-height: 500px;
    }

    .slideimg1 {
        width: 100%;
        height: 420px;
    }

    .adstitlepos {
        font-weight: 400;
        position: absolute;
        padding: 55px;
        top: 5%;
        left: 15%;
        height: 120px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 25.4118px;
        line-height: 30px;
        color: #C4C4C4;
    }

    .racestatuscolor {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 14px;
        width: 38%;
        padding-top: 9px;
    }

    button.slick-arrow.slick-prev {

        margin-left: 1px;
    }

    .sponsor {
        height: 400px;
        margin-top: 19px;
        margin-right: 10px;

    }

    .sponsor img {
        width: 100%;
        height: 100%;
    }

    .sponsorimg {
        height: 400px;

    }

    .slider {
        height: 400px;
        flex: 3 1;
    }

    button.slick-arrow.slick-next {
        width: 25px;
        height: 124px;
        margin-right: 10px;
    }

    button.slick-arrow.slick-prev {
        width: 25px;
        height: 124px;

    }

    .menuNav p {
        font-size: 12px;
    }

    .newsflex p {
        font-size: 10px;
    }

    .newsflex {
        padding: 30px 20px;
    }

 

    .rightlayoutinner {
        margin-right: -63px;
    }

    button.slick-arrow.slick-prev.slick-disabled {

        margin-left: 1px;
    }

    button.slick-arrow.slick-next {
        width: 25px;
        height: 124px;
        background: #D9D9D9;
        border-radius: 10px;

    }

    button.slick-arrow.slick-prev {
        width: 25px;
        height: 124px;
        background: #D9D9D9;
        border-radius: 10px;
        margin-right: 12px;
    }

    .singleracecard {
        max-width: 226px !important;
        margin-left: 6px;
    }

    .rightlayoutinner {

        margin: 0 7px;

    }

    .first-txt {
        font-size: 25px;
    }

    button#uncontrolled-tab-example-tab-home {

        max-width: 100px;
        font-size: 14px !important;
    }

    button#uncontrolled-tab-example-tab-ante {

        font-size: 14px !important;        max-width:100px;

    }

    button#uncontrolled-tab-example-tab-contact {


        max-width: 64px;
    }

    .Currentpostheader h2 {

        font-size: 12px;


    }


    .AccordionHeader>p:nth-child(1) {

        font-size: 12.5949px;


    }

    .Currentpostheader img {


        width: 40px;

    }

    .bannerText1 {

        font-size: 2.5rem;

    }

    .bannerText2 {

        font-size: 2.5rem;

    }

    .Competition_Matches p {
        font-size: 10px;
    }

    .CompetitionHeader h2 {

        font-size: clamp(1rem, 1vw, 1rem);
        padding: 10px;
    }

    .CompetitionHeader img {
        margin-right: 14px;
    }

    .PredictionCard {
        margin-top: 1.5rem;
    }

    .GraphCard {
        margin-top: 1.5rem;
    }

    .footertext1 {
        padding: 1rem -2rem;
        font-size: 13px;
        color: #000000;
    }

    .mtfooter {
        font-size: 14px;
    }

    .mtfooter1 {
        margin-top: 25px;
        max-width: 111%;
    }

    .innerCardBlogs {

        justify-content: space-evenly;
        flex-wrap: wrap;
        overflow: hidden;
        height: 435px;
        max-width: 100%;
    }

    .MatchDataPredict h3 {

        font-size: 18.5949px;

    }

    .PredictionFlex :nth-child(1) {

        font-size: 18.5949px;


    }

    .chartmatch {

        font-size: 18.5949px;

        margin: 15px 0 0 -1px;
        text-align: center;
    }

    .playerPredictTable {

        margin-top: 0px;
    }

    .AccordionHeader {

        justify-content: space-between;

        height: 40px;
    }

    .Predictiontable {

        padding-top: 15px;
        font-size: 14px;
    }

    .MatchDataPredict h3 {

        font-size: 18.5949px;

    }

    .PredictionFlex :nth-child(1) {


        font-size: 14.5949px;
        margin: 20px 0 0 20px;
    }

    .PredictionFlex :nth-child(2) {

        font-size: 12.5949px;
        line-height: 19px;

        margin: 24px 0 0 16px;
    }

    .chartmatch {

        font-size: 18.5949px;

        margin: 15px 0 0 -1px;
        text-align: center;
    }

    .playerPredictTable {

        margin-top: 0px;
    }



    .Predictiontable {
        font-size: 11px;
    }

    .AccordionHeader>p:nth-child(2) {

        font-size: 10.5949px;

    }

    .customers12 {

        font-weight: 900;
        font-size: 24px;
    }

    .customers12>span {
        margin-top: 17px;
        font-size: 9px;
    }

    .RaceCardDetail {
        width: 72% !important;    }

    .itemraces_left>.sponsor {
        width: 62px;
    }

    .itemraces_right .distance {

        width: 77px;

    }

    .colorheader {
        padding-right: 16px;
    }

    .itemraces_center>h5 {

        font-size: 14px;
        line-height: 23px;

    }

    .customers th {
        width: 12%;
        font-weight: 600;
        font-size: 9px;
        line-height: 25px;
    }

    .Trophydata>span:nth-child(1) {

        font-size: 13.3439px;
        line-height: 25px;

    }

    .cardraces2>p {
        line-height: 100px;

    }

    .result_match_raceNo {

        font-size: 15.5949px;


    }

    .result_match_right>p {

        font-size: 11px;

    }

    .customers1>tr>td:nth-child(2) {
        padding-left: 10px;
        width: 9rem;
        text-align: start;
        font-size: 9px;
    }

    .HorseParent>p {
        display: flex;
        padding-left: 1px;

    }

    .chartPrediction {
        margin-top: -6.7rem;
    }

    .aab {

        width: -1%;
    }


    .racestatuscolor>li {




        text-align: center;
        padding: 1px 5px 1px 4px;
        font-size: 17px;


    }

    .pmclass>p {

        font-size: 12px;

    }

    .racecardrow {

        justify-content: space-around;

    }

    .Summary {

        gap: 0;
    }

    .SummaryDistance {
        width: 245px;
        height: 281px;

    }

    .bannerCard>img {
        float: right;
        margin: 5px 2rem;
        width: 50%;
    }
    .posttabs {
        padding: 10px;
    }
    .posttabs p:hover {
        font-size: 12px !important;
    }
    .posttabs p {
        
        font-size: 12px !important;
    }
    .atozParent th {
        font-size: 12px;
        width: 12%;
        padding: 8px 12px;
    }
    .atozParent td img {
        width: 40px;
        height: 25px;
        object-fit: scale-down;
    }

    .atozParent td {
        font-size: 12px;
    }
    .atozParent h1 {
        font-size: 24px;
    }

    .stages th {
    font-size: 12px !important;
}

div#selectNow2 {
    padding: 2px !important;
    font-size: 12px !important;
}
div#selectNow {
    margin: 5px 19px !important;
    font-size: 14px !important;
}

p.resetrool {
    font-size: 14px !important;
}

.statsTable {
    margin-left: 20px !important;
}
.statsTable th {
    font-size: 15px !important;

}
.raceCarParent .customers td {
    padding: 4px;
    font-size: 12px;
}
.trainerStages td {
    font-size: 12px;
}

.live_result_data {
    padding-bottom: 5px !important;
}
.result_match_left {
    margin-top: 0px !important;
}
.resultData h6 {
    margin: 9px 5px;
    font-size: 12px;
    padding: 5px 6px;
}

.resultData p {
    margin: 5px 5px;
    font-size: 12px;
    padding: 5px 6px;
}
.result_match_totalRunner {
    font-size: 14.5949px;
    padding: 0px;
}
.result_match_raceNo {
    font-size: 13.5949px;
}


.sponsorimg {
    height: 400px !important;
}


.weatherIcon {
    z-index: 999;
}
.itemraces>p {
    font-size: 14px;
    margin: 5px 10px 10px 15px;
}

.cardracesAccordion {
    display: flex;
    gap: 5px;
}


.nonrunner {
font-size: 45px;
margin: -7px;
}
.trainerbreader_section {
    margin-bottom: 10px;
}
.newabc .sponsor {
    object-fit: scale-down;
    width: 90px !important;
}
.horsenamerace{
    font-weight: "600";
    font-size: "16.6px";
    line-height: "14px";
  }

 

}

@media (max-width: 1024px) and (min-width: 768px) {




    .aboutData {
        margin-right: 100px;
        padding-top: 100px;
        width: 107%;
    }

    .aboutData p {
        font-size: 17px;
        text-align: center;
        margin-bottom: 36px;
    }

    .aboutData2 {
        width: 100%;
        padding-top: 34px;
        margin-left: 0;
        text-align: center;
    }

    .aboutData h3 {

        text-align: center;
    }

    .aboutData2 p {
        font-size: 17px;
    }

    .middlebanner section {
        left: 13.1%;

    }

    .largemenu {
        display: none;
    }

    .aboutpagesection {
        display: block;

    }

    .singleracecard {
        width: 210px !important;
    }

    .chartPrediction {
        width: 96%;
        margin-inline: auto;
    }

  
    .header {
        height: 79px;
        width: 100% !important;
    }

    .cta {
        display: none;
    }

    .mobilemenudata {
        display: flex !important;
        width: 90%;
        margin-inline: auto;
        justify-content: space-between;
    }

    .logoclass {
        margin-top: 10px;
    }

    .fafabar {
        margin-top: 18px;
        color: #fff;
        position: relative;
        width: 38px;
        height: 38px;
    }

    .rightlayout {
        display: none;
    }

    .exnews {
        display: none;
    }

    .Homelayout {
        margin-top: 10px;
    }

    .Addington {
        display: none;
    }

    .RaceCardSection {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
    }

    button.slick-arrow.slick-next {
        margin-right: 10px;
    }

    button.slick-arrow.slick-next.slick-disabled {
        margin-right: 10px;
    }

    .slidercards {
        width: 85% !important;
    }

    .sponsor img {
        width: 100%;
        object-fit: cover;
        height: 100%;
    }

    .AdsCard {
        position: inherit;
    }

    .Currentpostheader {
        width: 80%;
    }

    .adscardmapping img {
        height: 300px !important;
        object-fit: contain;
    }

    .CompetitionData {
        width: 85%;
        margin: auto;
        height: -moz-fit-content;
        height: fit-content;
        overflow: auto;
        max-width: 100% !important;
        margin-bottom: 25px;
    }

    .first-txt {
        padding: 110px;
        width: 140px;
        margin: auto;
        top: 20%;
        font-size: 58.4118px;
        line-height: 80px;
    }

    .CompetitionHeader {
        width: 80%;
    }

    .middlesectioninner {
        display: none;
    }

    .Competition {
        margin: auto;
        background: #EFEFEF;
        border-radius: 20px;
    }

    .aab {
        display: none;
    }

    .presictData {
        width: 85%;
        margin: auto;
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 50% !important;
    }

    .newabc {
        width: 98%;
    }

    .bannerHome {
        display: none;
    }

    .resultCard {
        height: min(25rem, 40rem);
        width: 94%;
        margin-inline: auto;
    }

    .mobiledata {
        margin-top: 30px;
        display: block;
    }

    .mobiledropdown {
        position: absolute;
        background: var(--primary-color);
        z-index: 999;
        width: 100%;
        margin-top: 20px;
        text-align: center;
        color: #fff;
    }

    .customers12>span {
        margin-top: 12px;
    }

    .customers1>tr>td {
        width: 15%;
        font-size: 11px;
    }

    .result_match_left {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
    }

    .live_result_data {
        display: block;
    }

    .result_match_right {
        display: flex;
        gap: 20px;
        width: 90%;
        margin: auto;
    }

    .PredictionFlex :nth-child(1) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .PredictionFlex :nth-child(2) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .PredictionFlex :nth-child(3) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .playerPredictTable {
        height: min(10.8rem, 38rem);
    }

    .singleCardBlogs img {
        width: 178.76px;
        height: 175.07px;
        border-radius: 14.7783px;
    }

    .blognews h1 {
        font-size: 29.4118px;
        margin-top: 0.2rem;
        padding-top: 2rem;
    }

    .singleCardBlogs {
        margin-top: 0.5rem;
    }

    .singleCardBlogs h3 {
        font-size: 13.8px;
    }

    .singleCardBlogs h2 {
        font-size: 13.8px;
    }

    .copyrightinner {
        flex: 2 1;
        display: none;
    }

    .react-calendar {
        width: 90% !important;
    }
}

@media (max-width: 768px) and (min-width: 640px) {


    .imgDiv img {

        width: 102%;

    }


    .aboutData {
        margin-right: 100px;
        padding-top: 100px;
        width: 107%;
    }

    .aboutData p {
        font-size: 17px;
        text-align: center;
        margin-bottom: 36px;
    }

    .aboutData2 {
        width: 100%;
        padding-top: 34px;
        margin-left: 0;
        text-align: center;
    }

    .aboutData h3 {

        text-align: center;
    }

    .aboutData2 p {
        font-size: 17px;
    }

    .middlebanner section {
        left: 15.1%;
        width: 70.7%;
    }

    .middlebanner section p {
        font-size: 17px;
    }

    .largemenu {
        display: none;
    }

    .aboutpagesection {
        display: block;

    }

    .registrationform>form {
        width: 90%;
        margin: auto;
    }


    .largemenu {
        display: none;
    }

    .singleracecard {
        width: 210px !important;
    }

    .chartPrediction {
        width: 96%;
        margin-inline: auto;
    }

    /* .Homelayout {
        display: none;
    } */
    .header {
        height: 79px;
        width: 100% !important;
    }

    .cta {
        display: none;
    }

    .mobilemenudata {
        display: flex !important;
        width: 90%;
        margin-inline: auto;
        justify-content: space-between;
    }

    .logoclass {
        margin-top: 10px;
    }

    .fafabar {
        margin-top: 18px;
        color: #fff;
        position: relative;
        width: 38px;
        height: 38px;
    }

    .rightlayout {
        display: none;
    }

    .exnews {
        display: none;
    }

    .Homelayout {
        margin-top: 10px;
    }

    .Addington {
        display: none;
    }

    .RaceCardSection {
        margin-top: 19px;
        display: flex;
        justify-content: space-between;
    }

    button.slick-arrow.slick-next {
        margin-right: 10px;
    }

    button.slick-arrow.slick-next.slick-disabled {
        margin-right: 10px;
    }

    .slidercards {
        width: 75% !important;
    }

    .sponsor img {
        width: 100%;
        object-fit: cover;
        height: 100%;
    }

    .first-txt {
        padding: 110px;
        width: 140px;
        margin: auto;
        top: 20%;
        left: 9rem;
        font-size: 58.4118px;
        line-height: 80px;
    }

    .middlesectioninner {
        display: none;
    }

    .aab {
        display: none;
    }

    .newabc {
        width: 98%;
    }

    .bannerHome {
        display: none;
    }

    .resultCard {
        height: min(25rem, 40rem);
        width: 94%;
        margin-inline: auto;
    }

    .mobiledata {
        margin-top: 30px;
        display: block;
    }

    .mobiledropdown {
        position: absolute;
        background: #D9D9D9;
        z-index: 999;
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }

    .customers12>span {
        margin-top: 12px;
    }

    .customers1>tr>td {
        width: 15%;
        font-size: 11px;
    }

    .result_match_left {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
    }

    .live_result_data {
        display: block;
    }

    .result_match_right {
        display: flex;
        gap: 20px;
        width: 90%;
        margin: auto;
    }

    .PredictionFlex :nth-child(1) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .PredictionFlex :nth-child(2) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .PredictionFlex :nth-child(3) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .playerPredictTable {
        height: min(10.8rem, 38rem);
    }

    .singleCardBlogs img {
        width: 178.76px;
        height: 175.07px;
        border-radius: 14.7783px;
    }

    .blognews h1 {
        font-size: 29.4118px;
        margin-top: 0.2rem;
        padding-top: 2rem;
    }

    .singleCardBlogs {
        margin-top: 0.5rem;
    }

    .singleCardBlogs h3 {
        font-size: 13.8px;
    }

    .singleCardBlogs h2 {
        font-size: 13.8px;
    }

    .copyrightinner {
        flex: 2 1;
        display: none;
        /* margin-top: 7px; */
    }

    .react-calendar {
        width: 90% !important;
    }
}

@media (max-width: 640px) and (min-width: 471px) {

    .racestatuscolor {
        margin: -21px auto 16px auto;
    }

    .itemraces_left {
        display: flex;
    }

    .imgDiv img {

        width: 102%;

    }


    .aboutData {
        margin-right: 100px;
        padding-top: 100px;
        width: 107%;
    }

    .aboutData p {
        font-size: 15px;
        text-align: center;
        margin-bottom: 36px;
    }

    .aboutData2 {
        width: 100%;
        padding-top: 34px;
        margin-left: 0;
        text-align: center;
    }

    .aboutData h3 {

        text-align: center;
    }

    .aboutData2 p {
        font-size: 15px;
    }

    .middlebanner section {
        left: 15.1%;
        width: 70.7%;
    }

    .middlebanner section p {
        font-size: 15px;
    }

    .largemenu {
        display: none;
    }

    .aboutpagesection {
        display: block;
        margin-top: -89px;

    }













    .largemenu {
        display: none;
    }

    .registrationform>form {
        width: 90%;
        margin: auto;
    }

    .HomeLeftSection {
        margin-top: 30px;
    }

    .chartPrediction {
        width: 96%;
        margin-inline: auto;
    }

    .chartclass {
        width: 210px;
        height: 210px;
        margin: auto;
    }

    .chartmatch {
        font-size: 19.5949px;
    }

    .PredictionCard {
        width: 305px;
        height: 310px;
    }

    .GraphCard {
        width: 305px;
        height: 310px;
    }

    .presictData h6 {
        font-size: 15px;
        margin: 20px 0 0 20px;
    }

    .MatchDataPredict h3 {
        font-size: 17.5949px;
        line-height: 1px;
        margin: 10px 0 0 18px;
    }

    /* .Homelayout {
        display: none;
    } */
    .header {
        height: 79px;
        width: 100% !important;
    }

    .cta {
        display: none;
    }

    .mobilemenudata {
        display: flex !important;
        width: 90%;
        margin-inline: auto;
        justify-content: space-between;
    }

    .logoclass {
        margin-top: 10px;
    }

    .fafabar {
        margin-top: 18px;
        color: #fff;
        position: relative;
        width: 38px;
        height: 38px;
    }

    .rightlayout {
        display: none;
    }

    .exnews {
        display: none;
    }

    .Homelayout {
        margin-top: 10px;
    }

    .Addington {
        display: none;
    }

    .customers1 {
        margin-top: 15px;
    }

    .slideimg1 {
        height: 390px;
    }

    .first-txt {
        top: 20%;
        left: 2rem;
    }

    .sponsor {
        height: 319px;
    }

    .sponsorimg {
        height: 319px;
    }

    .middlesectioninner {
        display: none;
    }

    .aab {
        display: none;
    }

    .newabc {
        width: 98%;
    }

    .bannerHome {
        display: none;
    }

    .resultCard {
        height: min(24rem, 40rem);
        width: 97%;
        margin-inline: auto;
    }

    .customers12>span>p {
        width: 20%;
        font-size: 11px;

    }

    .customers12>span>p:nth-child(5) {
        width: 40% !important;
    }

    .customers12 {
        background: #fff;
        height: 60px;
        border-radius: 21px 20px 20px 20px;
        /* width: fit-content; */
        display: flex;
        /* width: 101%; */
        justify-content: space-between;
        /* margin-top: -12px; */
        align-items: start;
        padding-top: 10px;
    }

    .mobiledata {
        display: block;
    }

    .mobiledata1 {
        display: none;
    }

    .mobiledropdown {
        position: absolute;
        background: #D9D9D9;
        z-index: 999;
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }

    .BlogCard {
        height: 400px;
        overflow: hidden;
    }

    .customers12>span {
        margin-top: 12px;
    }

    .customers1>tr>td {
        width: 22%;
        font-size: 11px;
        text-align: center;

    }

    .customers1>tr>td:nth-child(2) {
        padding-left: 10px;
        width: 5rem;
        text-align: start;
    }

    .result_match_left {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
    }

    .live_result_data {
        display: block;
    }

    .result_match_right {
        display: flex;
        width: 60%;
        flex-wrap: wrap;
        margin-left: 1.7rem;
    }

    .PredictionFlex :nth-child(1) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .PredictionFlex :nth-child(2) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .PredictionFlex :nth-child(3) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .playerPredictTable {
        height: min(10.8rem, 38rem);
    }

    .singleCardBlogs img {
        width: 178.76px;
        height: 175.07px;
        border-radius: 14.7783px;
    }

    .blognews h1 {
        font-size: 29.4118px;
        margin-top: 0.2rem;
        padding-top: 2rem;
    }

    .singleCardBlogs {
        margin-top: 0.5rem;
    }

    .singleCardBlogs h3 {
        font-size: 13.8px;
    }

    .singleCardBlogs h2 {
        font-size: 13.8px;
    }

    .copyrightinner {
        flex: 2 1;
        display: none;
        /* margin-top: 7px; */
    }

    .react-calendar {
        width: 90% !important;
    }

    button#uncontrolled-tab-example-tab-home {
        font-size: 14px;
        border-radius: 20px 20px 0 0;
        height: 58px;
        width: 115px;
    }

    button#uncontrolled-tab-example-tab-ante {
        font-size: 14px;
        border-radius: 20px 20px 0 0;
        height: 58px;
        width: 115px;
    }

    button#uncontrolled-tab-example-tab-contact {
        height: 58px;
        font-size: 20px;
        width: 80px;
    }

    .chartPrediction {
        display: none;
    }

    .result_match_right>p:nth-child(3) {
        margin-top: -22px;
        margin-bottom: 22px;

    }

    .result_match_right>p:nth-child(4) {
        margin-top: -22px;
        margin-bottom: 22px;
    }

    .resultCardHeader {
        height: 240px;
    }

    .customers1 td,
    .customers1 th {
        border: 1px solid #ddd;
        padding: 0px;
        /* text-align: center; */
    }

    .adscardmapping {
        height: 230px;
    }

    .AdsCard {
        background: #C4C4C4;
        width: 100%;
        margin-inline: auto;
        height: 260px;
        position: unset;
    }

    .mobilecompetition {
        height: fit-content;
        max-height: 700px;
        background: #EFEFEF;
        border-radius: 20px;
        overflow: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .CompetitionHeader h2 {
        font-size: 21px;
    }

    .ScrollContainer {
        width: 700px;
        display: flex;
        overflow-x: auto;
        margin-bottom: 1.5rem;
        gap: 10px;
    }

    .scroll-container1 {
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }

    p.footertext1 {
        display: none;
    }

    .customers1>tr>td {
        width: 13%;
        font-size: 11px;
        text-align: center;
    }

    .customers1 td,
    .customers1 th {
        border: none;
    }

    .RaceCardSection {
        margin-top: 19px;
        display: flex;
        justify-content: space-between;
    }

    .customers1 {
        margin-top: 15px;
    }

    .slidercards {
        width: 88% !important;
    }

    .singleracecard {
        width: 220px !important;
    }

    .slideimg1 {
        height: 400px;
    }

    .slick-slider {
        margin-left: 11px;
        margin-right: -24px;
    }

    .racestatuscolor>li {
        padding: 15px 20px;
    }

    .RaceCardDetail {
        width: 99%;
        margin: 0 auto;
    }

    .ButtonTracker>button {
        margin: 0px 5px !important;
        width: 120px;
        font-size: 13px;
    }

    .ButtonTracker {
        display: flex;
        justify-content: center;
        margin: 15px !important;
    }

    .trackerhead>h6 {
        font-size: 13px;
    }
}

@media (max-width: 471px) and (min-width: 340px) {
    .RaceCardDetail {
        width: 99%;
        margin: 0 auto;
    }

    .racestatuscolor {
        margin: -21px auto 16px auto;
    }

    .racestatuscolor>li {
        padding: 15px 20px;
    }

    .searchbox {
        display: flex;
        width: 240px;
    }

    .registrationform {
        width: 90% !important;
        margin-inline: auto;
        margin-top: 18px;
    }

    .buttonSubscribe {
        padding: 5px;
    }

    .buttonLogin {
        padding: 7px !important;
    }

    .GenerateReports p {
        margin-top: 60px;
    }

    .form {
        margin-left: 15px !important;
        margin-right: 15px !important;
        margin-bottom: 55px !important;
    
    }

    .arabic1 {
        display: none !important;
    }

    .mainrow {
        margin-left: 5px !important;
    }

    .DashboardCard {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        margin: 16px 0px !important;
        justify-content: center;
    }

    .OngoingRaces {
        width: 165.35px !important;
        height: 139.19px !important;

    }

    .userHeader {
        width: 100% !important;
        margin: 4px 0px !important;
        height: 60px !important;
    }

    .userHeader>h3 {
        margin-top: 20px !important;
        font-size: 15.5949px;
        padding: 20px 30px !important;
    }

    .div_maintb {
        margin-left: 0px !important;
        width: 100%;
    }

    .rightlayoutinner {
        gap: 5px !important;
    }

    .GenerateReports {
        width: 165.35px !important;
        height: 139.19px !important;
    }

    .GenerateReports h3 {
        display: none;
    }

    .div_maintb th:nth-child(1),
    .div_maintb td:nth-child(1) {
        padding-right: 45px !important;
    }

    .winnerRow {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .userHeader2 {
        width: 100% !important;
        margin: 10px 0px !important;
    }

    .compDetail {
        margin-right: 0px !important;
        margin-left: 0px !important;
        width: 100% !important;

    }

    .compDetail h5 {
        left: 0px !important;
    }

    .ButtonTracker {
        margin-left: 0px !important;
    }

    .SubmitButton {
        width: 100px !important;
        margin: 10px 10px !important;
        font-size: 12px;
    }

    .userHeader2>h6 {
        padding: 19px 20px !important;
    }

    .updateButton {
        width: 100px !important;
        margin: 10px 10px !important;
        font-size: 12px;
    }

    .drawchart {
        width: 375px !important;
        height: 430px;
        margin: auto;
    }

    div#reactgooglegraph-1 {
        width: 360px !important;
    }

    .racepageheadflex {
        display: flex;
    }



    /* .racestatusclass {
        display: none;
    } */

    .racepageitem {
        width: auto;
    }

    .racestatusclassMobile {

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5.97744px 8.96617px;
        gap: 10.76px;
        width: 58px;
        height: 77px;
        left: 791px;
        top: 514px;
        color: #fff;
        border-radius: 12.98px;
    }

    .racepageitem h5 {
        margin-left: 9px;
    }

    .RaceDetailsName h6 {
        margin: 10px 18px;
    }

    .RaceDesc p {
        padding-right: 75px;
    }

    .raceStatusitem {
        margin: 2px 0px 4px 6px;
    }

    .raceStatus {
        margin: 0 -5px;
        justify-content: flex-start;
    }

    .imgDiv img {

        width: 100%;
        height: 400px;

    }

    .nav-tabs .nav-link {
        color: #fff;
        font-size: 10px;
    }


    .itemraces_left {
        display: flex;
    }

    .aboutData {
        margin-right: 100px;
        padding-top: 0px;
        width: 100%;
        padding: 20px 20px;
    }

    .aboutData p {
        font-size: 13px;
        text-align: center;
        margin-bottom: 36px;
    }

    .aboutData2 {
        width: 100%;
        padding-top: 34px;
        margin-left: 0;
        text-align: center;
    }

    .aboutData h3 {

        text-align: center;
    }

    .aboutData2 p {
        font-size: 13px;
    }

    .middlebanner section {
        /* left: 15.1%; */
        margin: auto !important;
        width: 90.7% !important;
        height: 590px;
    }

    .bannerAbout {
        width: 88% !important;
        margin: 0px auto;
        margin-top: 0px;
    }



    .aboutCard>img {
        float: right;
        /* margin: 2rem; */
    }

    .middlebanner section p {
        font-size: 13px;
    }

    .bannerText2 {

        font-size: 2rem;

    }

    .bannerText1 {
        font-size: 2rem;
    }

    .resracenameflex p {
        margin: 26px 16px 9px 18px;

    }

    .buttonRegister {
        margin: 15px !important;
    }

    .registrationform>form {
        width: 100% !important;
        margin: auto;
    }

    .owner {
        font-size: 15px;
    }

    .resracenameflex>img {
        margin: 26px 20px 0 0;
    }

    .resracestatuscolor {
        gap: 15px;
        width: 73%;
        padding-top: 3px;
        margin-left: 26px;
    }

    .RaceDetailHeader {
        width: 98%;
        margin: 0 5px;
    }

    .middlebanner section h3 {
        font-size: 20px;
    }

    .resracestatuscolor li {
        width: 30px;
    }

    .itemraces_left>.sponsor {

        max-width: 84px;
        margin-top: 17px;
    }

    .weatherimg {

        margin-left: 63px;
        margin-top: 17px;

    }

    .itemraces_center>h5 {
        margin-left: 16px;
        margin-top: -11px;

    }

    .itemraces_right .distance {

        margin: 4px 8px 0 0px;
        font-size: 10px;
    }

    .largemenu {
        display: none;
    }

    .xyz {
        display: none;
    }

    .HomeLeftSection {
        margin-top: 30px;
    }

    .chartPrediction {
        width: 96%;
        margin-inline: auto;
    }

    .chartclass {
        width: 210px;
        height: 210px;
        margin: auto;
    }

    .rescolorheader {

        display: block;
        height: 150px;

    }

    .chartmatch {
        font-size: 19.5949px;
    }

    .PredictionCard {
        width: 305px;
        height: 310px;
    }

    .itemraces_center p {
        font-size: 12px;
    }

    .weatherIcon {
        margin: 27px 16px;
    }

    .GraphCard {
        width: 305px;
        height: 310px;
    }

    .presictData h6 {
        font-size: 15px;
        margin: 20px 0 0 20px;
    }

    .MatchDataPredict h3 {
        font-size: 17.5949px;
        line-height: 1px;
        margin: 10px 0 0 18px;
    }

    /* .Homelayout {
        display: none;
    } */
    .header {
        height: 79px;
        width: 100% !important;
    }

    .cta {
        display: none;
    }

    .mobilemenudata {
        display: flex !important;
        width: 100%;
        margin-inline: auto;
        justify-content: space-between;
    }

    .logoclass {
        margin-top: 15px;
        width: 82%;
    }

    .fafabar {
        margin-top: 18px;
        color: #fff;
        position: relative;
        width: 38px;
        height: 38px;
    }

    /* .rightlayout {
        display: none;
    } */

    /* .exnews {
        display: none;
    } */

    .Homelayout {
        margin-top: 10px;
        overflow: hidden;
    }

    /* .Addington {
        display: none;
    } */

    .RaceCardSection {
        margin-top: 19px;
        display: flex;
        justify-content: space-between;
    }

    /* .slidercards {
        width: 94% !important;
    }

    .singleracecard {
        width: 225px !important;
    } */

    .slick-slide.slick-active.slick-current {
        width: 260px !important;
    }

    button.slick-arrow.slick-next {
        margin-right: -24px;
    }

    .slideimg1 {
        height: 340px;
    }

    .first-txt {
        position: absolute;
        height: 120px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 25.4118px;
        line-height: 60px;
        text-align: center;
        color: rgb(75, 73, 73);
    }

    .sponsor {
        height: 319px;
        margin-top: -10px;
    }

    .sponsorimg {
        height: 319px;
    }

    .middlesectioninner {
        display: none;
    }

    .aab {
        display: none;
    }

    .newabc {
        width: 98%;
    }

    .bannerHome {
        display: none;
    }

    .resultCard {
        height: min(24rem, 40rem);
        width: 97%;
        margin-inline: auto;
    }

    .customers12>span>p {
        width: 20%;
        font-size: 11px;

    }

    .customers12>span>p:nth-child(5) {
        width: 40% !important;
    }

    .customers12 {
        background: #fff;
        height: 60px;
        border-radius: 21px 20px 20px 20px;
        /* width: fit-content; */
        display: flex;
        /* width: 101%; */
        justify-content: space-between;
        /* margin-top: -12px; */
        align-items: start;
        padding-top: 10px;
    }

    .mobiledata {
        display: block;
    }

    .mobiledropdown {
        position: absolute;
        /* background: #D9D9D9; */
        z-index: 999;
        top: 76px;
        width: 100%;
           background: var(--primary-color) !important;         margin-top: 20px;
        text-align: center;
        height: fit-content;
        color: white;
        border-radius: 0px 0px 50px 0px;

    }

    ul.dropdown-menu.show {
        border: none;
        background: var(--primary-color) !important; 
        width: 103%;
        transform: none !important;
        text-align: center;
        border-radius: 0px 0px 130px 0px;
        margin-left: -10px !important;
    }

    .btn-check:active+.btn,
    .btn-check:checked+.btn,
    .btn.active,
    .btn.show,
    .btn:active {
        border-color: none !important;
        box-shadow: none !important;
    }

    .btn:hover {
        border-color: none !important;
        box-shadow: none !important;

    }

    .BlogCard {
        height: 400px;
        overflow: hidden;
        border-radius: 0px;
        width: 100%;
        margin-left: 4px;
        margin-top: 30px;
    }

    .slick-slider {
        margin-left: 30px;
    }

    .customers12>span {
        margin-top: 12px;
    }

    .customers1>tr>td {
        width: 22%;
        font-size: 11px;
        text-align: center;

    }

    .customers1>tr>td:nth-child(2) {
        padding-left: 10px;
        width: 5rem;
        text-align: start;
    }

    .result_match_left {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
    }

    .live_result_data {
        display: block;
    }

    .result_match_right {
        display: flex;
        width: 60%;
        flex-wrap: wrap;
        margin-left: 1.7rem;
    }

    .PredictionFlex :nth-child(1) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .PredictionFlex :nth-child(2) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .PredictionFlex :nth-child(3) {
        font-size: 15.5949px;
        line-height: 17px;
        margin: 20px 0 0 10px;
    }

    .playerPredictTable {
        height: min(10.8rem, 38rem);
    }

    .singleCardBlogs img {
        width: 178.76px;
        height: 175.07px;
        border-radius: 14.7783px;
    }

    .blognews h1 {
        font-size: 29.4118px;
        margin-top: 0.2rem;
        padding-top: 2rem;
    }

    .singleCardBlogs {
        margin-top: 0.5rem;
        margin-left: 7px;
    }

    .singleCardBlogs h3 {
        font-size: 11px;
    }

    .singleCardBlogs h2 {

        font-size: 13px;

    }

    .copyrightinner {
        flex: 2 1;
        display: none;
        /* margin-top: 7px; */
    }

    .react-calendar {
        width: 90% !important;
    }

    button#uncontrolled-tab-example-tab-home {
        font-size: 14px;
        border-radius: 20px 20px 0 0;
        height: 58px;
        width: 115px;
    }

    button#uncontrolled-tab-example-tab-ante {
        font-size: 14px;
        border-radius: 20px 20px 0 0;
        height: 58px;
        width: 115px;
    }

    button#uncontrolled-tab-example-tab-contact {
        height: 58px;
        font-size: 20px;
        width: 80px;
    }

    /* .chartPrediction {
        display: none;
    } */

    .result_match_right>p:nth-child(3) {
        margin-top: -22px;
        margin-bottom: 22px;

    }

    .result_match_right>p:nth-child(4) {
        margin-top: -22px;
        margin-bottom: 22px;
    }

    .resultCardHeader {
        height: 240px;
    }

    .customers1 td,
    .customers1 th {
        border: 1px solid #ddd;
        padding: 0px;
        /* text-align: center; */
    }

    .adscardmapping {
        height: 260px;
    }

    .AdsCard {
        background: #C4C4C4;
        width: 100%;
        margin-inline: auto;
        height: 260px;
        position: unset;
    }

    .aboutpagesection {
        display: block;
        width: 100%;
    }

    .mobilecompetition {
        height: fit-content;
        max-height: 700px;
        background: #EFEFEF;
        border-radius: 20px;
        overflow: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .CompetitionHeader h2 {
        font-size: 21px;
    }

    .ScrollContainer {
        width: 700px;
        display: flex;
        overflow-x: auto;
        margin-bottom: 1.5rem;
        gap: 10px;
    }

    .scroll-container1 {
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }

    p.footertext1 {
        display: none;
    }

    .customers1>tr>td {
        width: 13%;
        font-size: 11px;
        text-align: center;
    }

    .customers1 td,
    .customers1 th {
        border: none;
    }

    .mobiledata1 {
        display: none;
    }

    .newpost {
        height: -moz-fit-content;
        height: fit-content;
        min-height: 430px;
        max-height: 550px;
    }

    .itemraces_right .Favourite {
        margin-top: 14px;
        margin-left: 78px;
    }

    .adstitlepos {
        top: 15%;
        left: 15%;
    }

    .raced p {
        font-size: 10px;
        line-height: 0px;

    }

    .inner_itemraces {
        display: flex;
    }

    .racedisc {
        height: fit-content;
        max-height: 550px;
    }

    .prizecardheaders {
        display: block;

    }

    .Competitiontrophy {

        width: 743px;
        margin-top: 10px;
        overflow: auto;
    }

    .racedisc {

        height: fit-content;

        max-height: 550px;
    }

    .prizecard {
        height: fit-content;
        max-height: auto;
    }

    .customers>td,
    .customers>th {
        border: inherit;
        padding: 21px 14px;
        font-size: 8px;


    }

    .cardracesjockeycards {
        margin-left: -57px;

    }

    .pmclass>p {
        font-weight: 300;
        font-size: 12px;
        color: black;
    }

    .uaecareer {
        display: flex;
        gap: 25px;
        margin-top: 17px;
        width: 700px;
        margin-bottom: -15px;
    }

    .trainerbreader_section {

        margin-top: 8px;
    }

    .pmclass {
        margin: 14px 0 0 2px;

    }

    .costum td,
    .costum th {

        border: inherit;
        padding: 20px 13px;
        font-size: 10px;

    }

   

    .predictor {

        width: 700px;
        overflow: scroll;
    }

    .RaceDetailHeader {
        max-height: 700px;
        margin-top: 30px;
        overflow-x: hidden;
    }

    .customers2 th {
        font-size: 10px;
        padding-left: 8px;
    }

    .CompetitionName h2 {

        font-size: 14.5949px;

    }

    .CompetitionName h3 {

        font-size: 13.5949px;

    }

    .CompetitionPoint {

        gap: 9.39px;
        padding-left: 3px;
        padding-right: 10px;
    }

    .CompetitionCard {

        height: 35px;

    }


    .CompetitionPoint>div:nth-child(3) {

        height: 45px;

    }

    .CompetitionPoint>div:nth-child(7) {

        height: 45px;

    }

    .CompetitionHorse>p {

        font-size: 14.6px;

    }

    .CompetitionBtn>button {
        font-size: 13px;
        width: 137px;

    }

    ul.mb-3.RaceNavItem.nav.nav-tabs {

        overflow-x: auto;
        margin-left: 0 !important;
    }

    .modal-content {
        width: 100vw;
        margin: 0px 0px;
    }

    .RaceCardDetail {
        width: 99%;
    }

}