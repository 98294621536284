:root {
    --primary-color: var(--primary-color);
    --secondary-color: var(--secondary-color);
  }
  

.Homelayout {
    /* margin-top: 19px; */
    width: 100%;
}



.hometop {
    display: flex;
    gap: 19px;
}

.leftlayout {
    flex: 3;
}

.rightlayout {
    flex: 1.2;
}

.rightlayoutinner {
    display: flex;
    justify-content: space-between;
    gap: 19px;
}

.exnews {
    height: 77px;
    left: 0px;
    flex: 3;
    background: #C4C4C4;
    /* border-radius: 0px 20px 0px 0px; */
}

.newsflex {
    display: flex;
    color: #fff;
    gap: 1rem;
    padding: 30px 50px;
}

.newsflex h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.newsflex p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.searchbox {
    display: flex;
    /* width: 350px; */
}

.searchbox>input {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    margin-left: -8px;
    /* border: 3px solid rgb(15 196 225); */
    /* border-radius: 20px; */
}

.icon11 {
    margin-left: -35px;
    font-size: 21px;
    color: rgb(170, 168, 168);
}

.tracker {
    margin-top: 19px;
}

.language {
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.language>select {
    width: 45px;
    height: 32px;
    background: #D9D9D9;
    border-radius: 9px;
}

.notification {
    margin-top: 19px;
}

.slider {
    flex: 3;
}

.slideimg1 {
    width: 100%;
    height: 65vh;
}

.sponsor {
    height: 515px;
    flex: 1;
    margin-top: 19px;
}

.sponsor img {
    width: 100%;
    height: 100%;
}

.sponsorimg {
    color: #fff;
    height: 515px;
    object-fit: cover;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: -999;

}

.first-txt {
    position: absolute;
    /* padding: 55px; */
    /* top: 25%; */
    /* left: 18%; */
    height: 120px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 49.4118px;
    line-height: 60px;
    text-align: center;
    color: rgb(75, 73, 73);
}

.middlesection {
    display: flex;
    width: 100%;
    margin-top: 1.1rem;
    margin-inline: auto;
    /* grid-template-columns: 25% auto 25%; */
    column-gap: 1.1rem;
    padding-right: 20px;
}

.xyz {
    display: flex;
    flex: 2;
    column-gap: 1.1rem;
}

.HomeCenterSection {
    height: auto;
    flex: 2;
    width: 69%;
}


/* ----------------- Banner CSS------------------ */

.bannerText {
    padding: 6rem 2rem;
    position: absolute;
}

.registerbtn {
    width: 162px;
    height: 39px;
    background: #FFFFFF;
    border-radius: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #19469D;
    border: none;
    font-family: 'Inter';
    transition: all ease-in-out 1s;
}

.bannerText1 {
    color: var(--primary-color);
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
}

.bannerText2 {
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 51px;
}

.chartclass {
    width: 244px;
    height: 240px;
    margin: auto;
}

.chartheader {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    margin: 10px 0 0 22px;

}

.chartmatch {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22.5949px;
    line-height: 27px;
    margin: 15px 0 0 22px;
}

.react-calendar {
    width: 90% !important;
    margin: auto;
}

.react-calendar {
    /* width: 445px !important; */
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    margin-top: 30px;
}

.loader {
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;
    margin-left: 63%;
    margin-top: 20%;
}

.loader1 {
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;
    margin-left: 62.5%;
    margin-top: 20%;
}

.loader:before,
.loader:after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
}

.loader:before {
    animation: ball1 1s infinite;
    background: #cb2025;
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
}

.loader:after {
    animation: ball2 1s infinite;
    background: #00a096;
    box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg) scale(0.8);
        -moz-transform: rotate(0deg) scale(0.8);
    }

    50% {
        -webkit-transform: rotate(360deg) scale(1.2);
        -moz-transform: rotate(360deg) scale(1.2);
    }

    100% {
        -webkit-transform: rotate(720deg) scale(0.8);
        -moz-transform: rotate(720deg) scale(0.8);
    }
}

@keyframes ball1 {
    0% {
        box-shadow: 30px 0 0 #f8b334;
    }

    50% {
        box-shadow: 0 0 0 #f8b334;
        margin-bottom: 0;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
    }

    100% {
        box-shadow: 30px 0 0 #f8b334;
        margin-bottom: 10px;
    }
}

@keyframes ball2 {
    0% {
        box-shadow: 30px 0 0 #97bf0d;
    }

    50% {
        box-shadow: 0 0 0 #97bf0d;
        margin-top: -20px;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
    }

    100% {
        box-shadow: 30px 0 0 #97bf0d;
        margin-top: 0;
    }
}

button#uncontrolled-tab-example-tab-ante {
    background: #fff; 
    border-radius: 0px;
    color: #000;
    height: 70px;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;
    padding: 22px 45px;
    margin-top: -2px;
    /* border: 2px solid var(--secondary-color) !important; */

}

button#uncontrolled-tab-example-tab-contact {
    background: #fff ; 
    border-radius: 0px;
    color: #000;
    height: 70px;
    font-weight: 600;
    font-size: 24px;
    line-height: 15px;
    margin-top: -2px;
    padding: 18px 48px;
    /* border: 2px solid var(--secondary-color) !important; */

}
button.nav-link.active {
    border-bottom: 1px solid #efefef !important;
}
button#uncontrolled-tab-example-tab-home {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;
    color: #000;
    background: #fff;
    border-radius: 0px;
    height: 70px;
    padding: 20px 48px;
    margin-top: -2px;  
    /* border: 2px solid var(--secondary-color) !important; */
}
ul.mb-4.tabdata1.nav.nav-tabs button {
    margin-right: 2px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: none;
    color: var(--primary-color) !important;
    font-weight: 800;
    background: var(--fourth-color) !important;

}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: none;
    color: var(--primary-color) !important;
    font-weight: 800;
    background: var(--fourth-color) !important;

}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active svg {
    fill: white !important;
  }


/* button#uncontrolled-tab-example-tab-contact .nav-link:active {
    background: var(--default-primary-color);
} */

.accordion-button .collapsed {
      background: var(--primary-color) !important; 
}

.newsD {
    flex: 9;
}

.adstitlepos {
    font-weight: 400;
    position: absolute;
    /* padding: 15px; */
    /* top: 5%; */
    /* left: 5%; */
    /* height: 120px; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 49.4118px;
    line-height: 40px;
    color: #C4C4C4;
}

.adscardmapping {
    display: flex;
    align-items: center;
}

.RaceLoader {
    width: 67%;
}

.NAclass {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}

.aboutpagesection.resultdata {
    width: 50%;
    margin: auto;
}

.RaceAndHorseModelDataCSSFlex11 {
    display: flex;
    flex-direction: column;
    gap: 2px;
    /* height: 89rem; */
    overflow: auto;
}


span.rbc-btn-group:nth-child(3) {
    display: none !important;
}

.rbc-month-view {
    color: #000;
    background: white;
    border-radius: 20px;
}

.rbc-day-bg+.rbc-day-bg {
    /* border-left: 1px solid #ddd; */
    color: white !important;
    border-radius: 20px;
}

.rbc-off-range-bg {
    background: #fff !important;
}

.rbc-month-view {
    width: 110% !important;
    margin-left: -18px !important;
}

.rbc-off-range-bg {
    background: #fff !important;
    border-radius: 20px !important;
}

.rbc-month-header {
    display: none;
}

.rbc-btn-group>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: #fff;
}

.rbc-day-bg+.rbc-day-bg {
    border: none;
}

.rbc-toolbar .rbc-toolbar-label {
    color: #fff;
}

.rbc-day-bg+.rbc-day-bg {
    border: none;
    cursor: not-allowed;
}

span.raceroursecolor {
    height: 4px !important;
    width: 25%;
    /* background: #7831b6; */
    /* width: 40px; */
    position: absolute;
    left: 25%;
    top: 90%;
    /* margin-top: 72px; */
        /* margin-top: -7px; */
}
.com_nnerdata{
    padding: 12px !important;
}
.graphParent {
    display: flex;
    margin-top: 35px;
    gap: 10px;
}

.searchchild1 {
    display: none;
}

button.registerbtn:hover {

    color: #fff;
      background: var(--primary-color) !important; 


}

span.racecardcolor1 {
    height: 4px !important;
    width: 6%;
    /* background: #7831b6; */
    /* width: 40px; */
    position: absolute;
    left: 3%;
    margin-top: 31px;
}

table.drawtable {
    display: flex;
}



tbody.drawtablebody {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

tbody.drawtablebody tr {
    border: 1px solid rgb(208 208 208);
    height: 25px;
    width: 100%;
    font-size: 13px;
    text-align: center;
}

p.pdrawtable {
    width: 55px;
    border: 1px solid rgb(208 208 208) !important;
    height: 25px;
    font-size: 13px;
    text-align: center;

}

img.noimage1 {
    width: 100%;
    object-fit: cover;
    height: 230px;
}

.horsebodyitem table {
    width: 100%;
}

.horsebodyitem1 tr {

    width: 100%;
    background: #ffff;
}

.react-calendar {
    background: #ededed !important;
}

.racecardcss1{
    display: flex;
    justify-content: start;
    font-size: 14px;
}

.searchbox>input::-webkit-input-placeholder {
padding: 0px 15px;
  }

  *[dir="rtl"] table.drawtable {
    direction: revert;
    text-align: revert;
}

  *[dir="rtl"] .customClass .lb {
    border-radius: 0px !important;
}

*[dir="rtl"] .nonrunner {
    margin: 0px -10px;
}


*[dir="rtl"] span.racecardcolor1 {
    width: 6%;
    left: 3%;
    margin-top: 36px;
    right: 6%;
}


*[dir="rtl"] .predictDetail>p {
    margin: 5px 10px;
}

*[dir="rtl"] .predictDetail>h6 {
    margin: 5px 10px;
}




.notification {
    position: relative;
    display: inline-block;
  }
  
  
  
  .notification .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 7px 8px;
    border-radius: 50%;
    background: red;
    color: white;
}

  button#dropdown-basic1 {
    background: #fff;
    border: none;
    margin-top: -22px;
}

.notificationmenu.dropdown-menu.show {
    background: transparent !important;
    width: 300px;
    margin-left: -235px;
    margin-top: 10px;
}

.button#dropdown-basic1:active{
    box-shadow:none !important;
}

.button#dropdown-basic1:focus{
    box-shadow:none !important;
}

.notificationimg1{
    height: 35px;
    width: 35px;
}
.notificationpanel{
    display: flex;
}

p.notifytime {
    font-size: 10px;
    text-transform: lowercase;
    margin: 12px 8px;
    color: red;

}
.notifytext p {
    font-size: 10px;
}
.notifytext h4 {
    font-size: 14px;
}

.notificationpanel.list-group-item {
    margin: 0;
    padding: 3px;
    line-height: 10px;
    justify-content: space-between;
}
.list-group {
    max-height: 360px;
    overflow: auto;
    color: #000;
    text-align: center;
    background: var(--secondary-color);
    padding: 15px;
    font-weight: 700;
}
.list-group{
    max-height: 360px ;
    overflow: auto;
}
.racepageitem p {
    font-size: 12px;
}

span.totalrunners {
    padding: 0 20px;
    font-size: 14px;
    color: #919191;
}
p.racerourseDate {
    font-size: 18px !important;
    font-weight: 300 !important;
}

span.Gallop {
    padding: 0 30px !important;
    font-size: 18px !important;
    font-weight: 300 !important;
}

svg.chosecalender {
    height: 35px;
    color: #fff;
    width: 55px;
}

.notificationpanel.list-group-item:hover {
    background: rgb(219, 219, 217);
    cursor: pointer;
}

.allnotifilcationdata.list-group {
    max-height: 810px !important;
    overflow: auto;
    color: #000;
    text-align: center;
    background: var(--secondary-color);
    padding: 15px;
    font-weight: 700;
}


.racecardscreencalender .react-calendar {
    width: 50% !important;
    width: 20% !important;
    margin: auto !important;
    position: absolute;
    /* z-index: -999; */
    top: 42%;
    left: 44%;
    padding: 0px;
    margin: 0px;
}


.match {
    /* margin-top: -10px; */
    background: var(--fourth-color) !important;
    margin: 10px -10px 0px 10px;
}