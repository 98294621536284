.statspage {
  width: 982px;
  margin: auto;
  height: 600px;
}

.statsheader {
  width: 982px;
  height: 193px;
  background: linear-gradient(180deg, #078be6 0%, #19469d 100%), #19469d;
  border-radius: 18px;
  margin-top: 10px;
}

.statsheaderbtn .btn-primary {
  background: #fff;
  color: #000;
  width: 95px;
  height: 55px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  border-radius: 30px;
}

.Statisticsbtn {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.statsheader>p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 22.5949px;
  line-height: 27px;
  color: #fff;
  margin: 15px;
  padding-top: 10px;
}

.statsbody {
  background: #d9d9d9;
  margin-top: 20px;
}

.statsbodyitem {
  margin: 7px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 18px;
}

.statsbodyitem>ul>li {
  list-style: none;
}

/* button.eventdata1 {
  background: #19469d;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
} */


h2.eventrace {
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
}

p.eventracedata {
  color: #fff;
  text-align: center;
}



.sponsor.querysponsor {
  width: 22%;
  margin-top: 30px;
}


.queryheader {
  display: flex;
  width: 98% !important;
}
.querypage {
  width: 100%;
  /* margin: auto; */
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}


/* .react-calendar__tile--active {
  background: var(--primary-color) !important; 
  color: white;
} */


.react-calendar__tile--active {
  background: white !important;
  color: #423e3e;
}

.react-calendar__tile--now {
  background: #f0f0e8;
  border-radius: 10px;
}





.querypage {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 10px;
  margin-bottom: 10%;
}

.queryheader {
  display: flex;
  height: fit-content;
  margin-top: 30px;
  gap: 20px;
}
/* .sponsorimg.querysponsorimg {
  height: 83vh;
} */
.leftquery {
  flex: 1;
  background: var(--primary-color) !important; 
  border-radius: 0px;
}

.rightquery {
  flex: 3;
  background: #faf7f7;
  border-radius: 0px;
}

.leftquery>select {
  word-wrap: normal;
  padding: 12px;
  margin: 4px 25px;
  width: 80%;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  color: #3f3f3f;
}

.leftquery>select:nth-child(1) {
  margin-top: 35px !important;
}

.leftquery>select:last-child {
  margin-bottom: 35px !important;
}

.GenerateReport {
  background: red;
  border-radius: 0px;
  border: none;
  padding: 12px;
  margin: 10px 25px;
  width: 85%;
  margin-bottom: 10px !important;
  color: #fff;
}

.querytable {
  width: 100%;
}

.querytablehead {
  background: var(--secondary-color) !important; 
  height: 50px;
  color: #fff;
  text-align: center;
}

.querytablehead>th:nth-child(1) {
  border-radius: 10px 10px 0px 0px !important;
}

tbody.querytablebody>tr {
  height: 45px;
  background: #fff;
  margin: 5px !important;
  border: 3px solid #faf7f7 !important;
  /* width: 94%; */
  font-size: 16px;
  line-height: 45px;
  text-align: center;
}

.Statisticsbtn>select {
  padding: 14px;
  border: none;
  border-radius: 12px;
  color: #696868;
  font-size: 14px;
}

.trainerStages .accordion-item:last-of-type .accordion-button.collapsed {

  border-radius: 0px !important;
  padding: 19px;
  text-align: center;

}

.trainerStages .accordion-button:not(.collapsed) {
  border-radius: 0px !important;
  background: #fff !important;
  border: 1px solid #fff !important;
  padding: 16px !important;
}

.trainerStages td {
  width: 175px !important;  text-align: center;
  text-transform: capitalize !important;
  font-size: 15px;
}

.trainerStages .accordion-button {
  border-radius: 0px !important;
  background: #fff !important;
  border: 1px solid #fff !important;
  padding: 16px !important;
}

table.statsTable.innermodalstats {
  width: 95%;
  margin-left: 25px !important;
}
.statsTable {
  caption-side: bottom;
  border-collapse: collapse;
  margin-left: 63px !important;
  width: 94%;
  margin-top: 12px;
}
table.statsTable.innermodalstats th {
  font-size: 12px;
}
table.statsTable.innermodalstats th {
  font-size: 12px;
  text-align: center;
  width: 257px !IMPORTANT;
  padding-left: 3%;
}
.statsmodalhorse1{
  display: flex;
  /* justify-content: space-between; */
}
tr.statsmodalhorse1 td {
  padding-right: 0;
  width: 92px;
  text-align: center;
}
.statsTable th {
  width: 257px !important;
  text-transform: uppercase !important;
  font-size: 19px;
  color: #000 !important;
}
table.tableHeaderCard {
  width: 100%;
}
.accordion-header {
  margin-bottom: 0;
  z-index: -9999 !important;
}

.container.mystages.trainerStages {
  margin-top: 25px;
}
.stages123 th{
  color: #000 !important;
}

div#selectNow2 {
  width: 180px;
}

.trainerstatsbtn1.accordion-body {
  background: #fff !important;
  margin-top: -15px;  
  padding: 0;

}
.trainerstatsbtn1.accordion-item{
  background: #fff !important;
}

.raceCarParent1.atozParent.stages {
  width: 100% !important;
}

div#selectNow {
  /* width: 113px; */
  word-wrap: normal;
  padding: 8px !;
  margin: 6px 25px;
  width: 85%;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  color: #3f3f3f;
}

.QueryTool{
  display: flex;
  width: 80%;
  margin-inline: auto;
  margin-top: 20px;
  gap: 10px;
}
.Queryleftdata{
  width: 297px;
height: 935px;
background: linear-gradient(180deg, #078BE6 0%, #19469D 100%), #19469D;
border-radius: 18px;
}
.Queryrightdata{
  width: 1056px;
  height: 307px;
  background: #D9D9D9;
  border-radius: 18px;
}
.queryheaderbtn.btn-primary {
  background: #fff;
  color: #000;
  width: 95px;
  height: 55px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px; 
}

/* .searchdatalist {
 display: flex;
 flex-direction: column;
} */
.searchchild {
  border: 1px solid #cbc3c3;
  border-radius: 0px 0px 20px 20px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  z-index: 9999999 !important;
  background: #fff;
  width: 250%;
  border-top: 0;
  padding-top: 20px;
  top: 77px;
  right: 1.5% !IMPORTANT;
  height: 300px;
  overflow-x: hidden;
}
.searchdatalist {
  text-align: center;
}
p.searchname {
  line-height: 12px;
  cursor: pointer;
  font-size: 12px;
  cursor: pointer;
}
.searchparent{
  position: relative;
}
.searchchild1{
display: none;
}
.searchdatalist > p{
  font-size: 14px;
  cursor: pointer;
  padding: 1px;
}
.searchdatalist1{
  font-size: 14px;
}

h2.nodataavaiable {
  position: absolute;
  left: 50%;
  top: 30%;
  color: #19469D;
  text-decoration: underline;
}


div#selectNow2 {
  word-wrap: normal;
  padding: 8px;
  margin: 4px 0px;
  width: 80%;
  border: none;
  border-radius: 10px;
  font-size: 13px;
  color: #3f3f3f;
}

/* .querytoolpagination {
  margin-top: 10px;
  position: absolute;
  left: 50%;
} */

p.resetrool {
  text-align: center;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  margin: auto;
}

/* table.querytable {
  height: 83vh!important;
} */






.trainerstatsbtn1.accordion-item {
  border: 1px solid #ccc !important;
}

.trainerstatsbtn1.data_border.accordion-item {
  border: 3px solid #fff !important;
}