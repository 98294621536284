
/* a:hover{
    color: white;
    text-decoration: none;
    
} */

.blue{
    background: aqua !important;
    color: #359242;
}
.footer1{
    /* height: 100%; */
    width: 100%;
    background-image: url('../../assets/footer.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Copyrights {
    width: 100%;
    background: var(--fourth-color) !important; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    height: 60px;
    min-height: 60px;
}
.Policies{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
}
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 90%;
}
.footertext1{
    padding: 1.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14.6133px;
    line-height: 25px;
    color: #000000;
}

.formclass1{
    display: flex;
}
.formclass1 > input{
    
    height: 58px;
    background: #D7D9DE;
    border-radius: 0px;
    border: none;
    width: 100%;
}

.formclass2 > button{
     display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 30px;
gap: 10px;
color: #fff;
width: 100%;
height: 45px;
left: 972px;
top: 3038px;
background: var(--primary-color) !important; 
border-radius: 0px;
border: none;
margin-top: 0px;
}
.LinkStyle > li > p{
    color: #000;
} 
.text-muted > li > p{
    color: #000;
}
input::-webkit-input-placeholder { /* WebKit browsers */
    padding-left: 20px;
    /* color: #000; */
  }

  .list-unstyled .text-muted > li >img{
    color: #6c757d !important;
    background: #6c757d!important;
  }

  .mtfooter{
    margin-top: 45px;
  }
  .mtfooter1 {
    margin-top: 25px;
    width: 40%;
    padding-left: 5%;
}
.copyrightinner{  
    flex: 2;
    margin-top: 10px;
    font-size: 15px;
  }
  h5.text-black.mb-3.mtfooter {
    font-weight: 700;
    color: var(--fourth-color) !important;
}
.Policies a{
    color: white;
}
ul.list-unstyled.text-muted img {
    margin-right: 5px;
    opacity: 0.5;
}
ul.list-unstyled.text-muted.text-bold li {
    padding-bottom: 12px;
}
ul.list-unstyled.text-muted li {
    padding-bottom: 12px;
}

p.followusc {
    font-weight: 700;
}

.Policies a p {
    font-size: 15px;
}

.container-fluid.myabout {
    display: none !important;
}
.aboutpageheader{
    display: none !important;

}