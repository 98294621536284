:root {
    --primary-color: var(--primary-color);
    --secondary-color: var(--secondary-color);
  }
  
.HomeRightSection {
    height: fit-content;
    max-height: 700px;
    background: var(--primary-color) !important; 
    border-radius: 20px;
    overflow: auto;
    min-height: 700px;
}

.HomeRightLeaderBoader {
    /* height: 700px; */
    max-height: 720px;
    background: var(--primary-color) !important; 
    border-radius: 20px;
    overflow: auto;
    margin-top: 2rem;
    height: fit-content;
}

.react-reveal.RaceCardDetail {
    flex: 2;
}
#castselect {
     width: 300px;
     margin: 4px;
}
.Competition {
    margin: auto;
}
h4.noFoundHeader {
    color: #fff;
}
.CompetitionHeader {
    width: 88%;
    margin: auto;
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
}

.CompetitionHeader h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1rem, 10vw, 2rem);
    text-transform: uppercase;
}

.CompetitionHeader img {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1rem, 10vw, 2rem);
    line-height: 24px;
    height: 45px;
}

.AccordionHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.AccordionHeader>p:nth-child(1) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.AccordionHeader>p:nth-child(2) {
    font-style: normal;
    font-weight: 300;
    font-size: 15.5949px;
    line-height: 19px;
    color: #000000;
    padding-top: 10px;
}

.AccordionHeader11 {
    border-radius: 10px;
}

.AccordionHeader11 button {
    border-radius: 20px !important;
}

.accordion-button::after {
    display: none !important;
}

.CompetitionData {
    width: 100%;
    margin: auto;
    height: -moz-fit-content;
    height: fit-content;
    overflow: auto;
    max-width: 630px;
    /* margin-bottom: 25px; */
}

.LeaderData {
    width: 100%;
    margin: auto;
    height: -moz-fit-content;
    height: fit-content;
    overflow: auto;
    height: 550px;
    margin-bottom: 25px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 30px;

}

.accordion-button {
    border-radius: 30px;
}

.Competitionitem {
    border-radius: 21px;
    margin: 8px;
}

.Competition_Matches {
    display: flex;
    justify-content: space-between;
    color: #000;
}

.accordion-body {
    background: #19469D;

}

.accordion-item {
    background: #EFEFEF;
    border: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.CastCompetitionCategory {
    display: flex;
}

.CastCompetitionCategory>input {
    margin: 5px;
    height: 15px;
    width: 25px;
}

/* button:disabled {
    background: #989693 !important;
} */

.racecardlottie {
    width: 100% !important;
}

.Competitiontrophy {
    display: flex;
    justify-content: space-between;
    overflow: scroll !important;
    width: 98%;
    margin: auto;
}

span.cardraces1box h3 {
    margin: 10px;
}


.orangeclass {
    width: 150px !important;
    font-size: 21px !important;
    margin: 0px 10px !important;
}

.nocompetitionimg1 {
    display: flex;
    justify-content: center;
}